import { useQuery } from "react-query";
import WeighAndDispenseUpcomingLast from ".";
import { getWeighAndDispenseContainerApi } from "api/weigh-dispense-container-api";
import { useParams } from "react-router-dom";

const AncWeighAndDispenseUpcomingLastContainer = () => {
  const { site } = useParams()
  const {
    isLoading: upcomingLastIsLoading,
    isError: upcomingLastIsError,
    data: upcomingLastData,
  } = useQuery(
    ["getWeighAndDispenseLastContainer-Api",site],
    getWeighAndDispenseContainerApi.bind(this, {
      type: "completed",
      status: "string",
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
    })
  );

  return (
    <WeighAndDispenseUpcomingLast
      isLoading={upcomingLastIsLoading}
      isError={upcomingLastIsError}
      upcomingLastData={upcomingLastData?.data?.data ?? []}
    />
  );
};
export default AncWeighAndDispenseUpcomingLastContainer;