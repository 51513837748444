import AncHeaderText from "reusable/anc-table/anc-header-text";
import TableBody from "reusable/anc-table/table-body";
import TableDescriptionComponent from "reusable/anc-table/table-description-component";
import TableHead from "reusable/anc-table/table-head";
import TableHeaderComponent from "reusable/anc-table/table-header-Component";
import TableHeaderRowComponent from "reusable/anc-table/table-header-row-component";
import AncTabTableComponent from "reusable/anc-table/table-wrapper";
import TableRowComponent from "reusable/anc-table/table-row-component";
import ErrorHandler from "utils/error-handler";
import { forwardRef, useState } from "react";
import { solPrepHeaderData } from "constants/solution-prep-constants/solution-prep-header";
import { SolutionPrepTableDataObjProps } from "assets/types/anc-sol-prep-table-types";
import "./index.scss"
import TableDescriptionRender from "reusable/anc-table/table-description-render/table-description";
import SolutionPrepModal from "../solution-prep-modal";
import EmptyTableDataComponent from "reusable/anc-empty-table-data-component";

interface SolutionPrepProgressType {
  isLoading: boolean;
  isError: boolean;
  inProgressData: SolutionPrepTableDataObjProps[];
  id:string;
  ref:any;
}

const SolutionPrepProgress: React.FC<SolutionPrepProgressType> = 
forwardRef(({
  isLoading,
  isError,
  inProgressData,
  id,
  ref
}) => {
  const [inProgressHeaderData, setinProgressHeaderData] =
    useState([...solPrepHeaderData]);
  let sortedData = inProgressData;
  const [viewMoreModalState, setViewMoreModalState] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<SolutionPrepTableDataObjProps>({
    actual_start: '',
    lot: '',
    material: 1,
    material_description: '',
    process_lot: '',
    remaining_time: '',
    progress_value: 1,
    schedule: '',
    schedule_color_code: '',
    schedule_in_secs: '',
    solution_description: '',
    solution_lot: 1,
    target_start: ''
  })
  return (
    <>
      <div className="anc-table-error-handler anc-align-items-center" id={id} ref={ref}>
        <ErrorHandler
          isLoading={isLoading}
          isError={isError}
          loaderColor="#ffff"
        />
      </div>

      {!isLoading && !isError &&
        <>
          {
            inProgressData.length !== 0
              ? (<AncTabTableComponent>
                <TableHead>
                  <TableHeaderRowComponent>
                    {inProgressHeaderData.map((item, index) => {
                      return (
                        <TableHeaderComponent key={index} align={item.align}>
                          <AncHeaderText
                            sortActive={item.sortActive}
                            textValue={item.header}
                            sortKey={item.sortKey}
                            alignText={item.align}
                            columnNameVisible={item.columnNameVisible}
                            sortFn={() => {
                              const updatedItems = [...inProgressHeaderData];
                              const indexfind = updatedItems.findIndex((item) => item.sortActive)
                              updatedItems[indexfind].sortActive = false
                              updatedItems[index].sortKey =
                                !updatedItems[index].sortKey;
                              updatedItems[index].sortActive = true
                              setinProgressHeaderData(updatedItems);
                              sortedData = inProgressData.sort(
                                item.sortfn.bind(item) as (
                                  a: SolutionPrepTableDataObjProps,
                                  b: SolutionPrepTableDataObjProps
                                ) => number
                              );
                            }}
                          />
                        </TableHeaderComponent>
                      );
                    })}
                  </TableHeaderRowComponent>
                </TableHead>

                <TableBody>
                  {sortedData?.map((row: SolutionPrepTableDataObjProps, i: number) => (
                    <TableRowComponent key={i}>
                      {inProgressHeaderData.map((item, index) => {
                        return (
                          <>
                            <TableDescriptionComponent key={index} align={item.align}>
                              <TableDescriptionRender
                                columnComponentType={item.columnComponentType}
                                headerDataKey={item.datakey}
                                row={row}
                                buttonCallback={(state) => {
                                  setViewMoreModalState(state)
                                  setSelectedRowData(row)
                                }}
                              />
                            </TableDescriptionComponent>

                          </>
                        );
                      })}
                    </TableRowComponent>
                  ))}
                </TableBody>
                <div className="anc-view-more-modal-container">
                  <SolutionPrepModal
                    title={selectedRowData?.Product_BatchNo}
                    opened={viewMoreModalState}
                    close={() => {
                      setViewMoreModalState(false);
                    }}
                    modalData={selectedRowData}
                  />
                </div>
              </AncTabTableComponent>
              )
              : <EmptyTableDataComponent />
          }
        </>
      }
    </>
  );
});
SolutionPrepProgress.displayName = "In Progress";

export default SolutionPrepProgress;
