import "./index.scss";
import "../../../assets/bootstrap.scss";
import {
  LotHeaderType,
  LotRangeHeaderText,
  MaxHeaderType,
  MinHeaderType,
  NameHeaderType,
} from "constants/thaw-inoc-constants/thaw-inoc-process-view";

interface AncLotsCardHeaderDataContentProps {
  dataValue: string;
  type: string;
  headerIndex: number;
}

const AncLotsCardHeaderDataContent: React.FC<
  AncLotsCardHeaderDataContentProps
> = (props: AncLotsCardHeaderDataContentProps) => {
  return (
    <>
      {props.type === NameHeaderType && (
        <div className="ops-fs-5 anc-header-card-name ops-fw-bold anc-text-uppercase">
          {props.dataValue ?? "N/A"}
        </div>
      )}
      {props.type === LotHeaderType && (
        <div className="ops-fs-5 anc-header-card-name ops-fw-bold anc-text-uppercase">
          {null}
        </div>
      )}
      {props.type === MinHeaderType && props.headerIndex === 0 && (
        <div className="d-flex ops-fs-6 anc-header-card-time ops-fw-bold anc-text-uppercase">
          <div className="anc-header-card-label">
            {LotRangeHeaderText ?? "N/A"}&nbsp;
          </div>
          <div className="anc-header-card-range">{props.type ?? "N/A"}</div>
        </div>
      )}
      {props.type === MaxHeaderType && props.headerIndex === 0 && (
        <div className="d-flex ops-fs-6  anc-header-card-time ops-fw-bold anc-text-uppercase">
          <div className="anc-header-card-label">
            {LotRangeHeaderText ?? "N/A"}&nbsp;
          </div>
          <div className="anc-header-card-range">{props.type ?? "N/A"}</div>
        </div>
      )}
    </>
  );
};
export default AncLotsCardHeaderDataContent;
