import CircleIcon from "assets/icons/circle-icon";
import React from "react";
import "./index.scss";
import AlertIcon from "assets/icons/alert-icon";

interface HorizontalLabelsInterface {
  item: any;
}
const HorizontalLabels: React.FC<HorizontalLabelsInterface> = (
  props: HorizontalLabelsInterface
) => {
  return (
    <div className="anc-d-flex anc-horizontal-labels">
      <div className="anc-label-text-container align-items-center">
        <div className="anc-d-flex anc-label-container align-items-center">
          <div className="anc-pr-1 align-items-center anc-d-flex">
            {props.item.labelImageType==="circle" ? (
              <CircleIcon
                fill={props.item.labelColor}
                stroke={props.item.labelColor}
              />
            ):<AlertIcon fill={"red"} id={"label-alert-icon"}/>}
          </div>
          <div className="anc-d-flex anc-running-label anc-text-uppercase ops-fs-6 ops-fw-bold align-items-center">
            {props.item.label}
          </div>
        </div>
      </div>

    </div>
  );
};
export default HorizontalLabels;
