import "./index.scss";

interface HeaderValueChildContentProps {
  headerValue: string;
  children: React.ReactNode;
}
const HeaderValueContainer = (props: HeaderValueChildContentProps) => {
  const { headerValue, children } = props;
  return (
    <>
      <div className="ops-fs-6 anc-value-header anc-text-uppercase ops-fw-bold">
        {headerValue}
      </div>
      <div
        className={ 
          "anc-value-data"
        }
      >
        {children}
      </div>
    </>
  );
};

export default HeaderValueContainer;
