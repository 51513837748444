interface Props {
  fill: string;
  classname?: string;
}

const VectorUpIcon = ({ fill, classname }: Props) => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5.41007L6.71001 1.17007C6.61705 1.07634 6.50645 1.00194 6.38459 0.951175C6.26273 0.900406 6.13202 0.874268 6.00001 0.874268C5.868 0.874268 5.73729 0.900406 5.61543 0.951175C5.49357 1.00194 5.38297 1.07634 5.29001 1.17007L1.05001 5.41007C0.956281 5.50303 0.881887 5.61363 0.831118 5.73549C0.780349 5.85735 0.754211 5.98805 0.754211 6.12007C0.754211 6.25208 0.780349 6.38278 0.831118 6.50464C0.881887 6.6265 0.956281 6.7371 1.05001 6.83007C1.23737 7.01632 1.49082 7.12086 1.75501 7.12086C2.0192 7.12086 2.27265 7.01632 2.46001 6.83007L6.00001 3.29007L9.54001 6.83007C9.72627 7.01481 9.97767 7.11896 10.24 7.12007C10.3716 7.12083 10.5021 7.0956 10.6239 7.04584C10.7458 6.99607 10.8566 6.92275 10.95 6.83007C11.0471 6.74045 11.1254 6.63248 11.1805 6.51241C11.2357 6.39233 11.2664 6.26253 11.2711 6.13049C11.2757 5.99846 11.2542 5.86681 11.2076 5.74315C11.1611 5.6195 11.0905 5.50629 11 5.41007Z"
        fill={fill}
        className={classname}
      />
    </svg>
  );
};

export default VectorUpIcon;