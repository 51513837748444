import { useQuery } from "react-query";
import WeighAndDispenseUpcomingNext from ".";
import { getWeighAndDispenseContainerApi } from "api/weigh-dispense-container-api";
import { useParams } from "react-router-dom";

const AncWeighAndDispenseUpcomingNextContainer = () => {
  const { site } = useParams();
  const {
    isLoading: upcomingNextIsLoading,
    isError: upcomingNextIsError,
    data: upcomingNextData,
  } = useQuery(
    ["getWeighAndDispenseNextContainer-Api",site],
    getWeighAndDispenseContainerApi.bind(this, {
      type: "upcoming",
      status: "string",
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
    })
  );

  return (
    <WeighAndDispenseUpcomingNext
      isLoading={upcomingNextIsLoading}
      isError={upcomingNextIsError}
      upcomingNextData={upcomingNextData?.data?.data ?? []}
    />
  );
};
export default AncWeighAndDispenseUpcomingNextContainer;
