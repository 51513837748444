interface Props {
    fill: string
    classname?: string
  }
  
  const AlarmBellIcon = ({ fill, classname }: Props) => {
    return (
        <svg 
            width="13" 
            height="12" 
            viewBox="0 0 13 12" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M9.63609 6.59V5C9.63533 4.29156 9.37163 3.60623 8.89167 3.06539C8.41171 2.52454 7.74647 2.16307 7.01376 2.045V1.5C7.01376 1.36739 6.95851 1.24021 6.86015 1.14645C6.76179 1.05268 6.62839 1 6.4893 1C6.3502 1 6.2168 1.05268 6.11845 1.14645C6.02009 1.24021 5.96483 1.36739 5.96483 1.5V2.045C5.23212 2.16307 4.56688 2.52454 4.08693 3.06539C3.60697 3.60623 3.34326 4.29156 3.34251 5V6.59C3.03646 6.69316 2.77137 6.88404 2.58358 7.13647C2.39579 7.3889 2.29449 7.69053 2.29358 8V9C2.29358 9.13261 2.34884 9.25979 2.44719 9.35355C2.54555 9.44732 2.67895 9.5 2.81804 9.5H4.46486C4.58564 9.92369 4.8493 10.2977 5.21516 10.5644C5.58103 10.831 6.02876 10.9755 6.4893 10.9755C6.94983 10.9755 7.39757 10.831 7.76343 10.5644C8.1293 10.2977 8.39296 9.92369 8.51373 9.5H10.1606C10.2996 9.5 10.433 9.44732 10.5314 9.35355C10.6298 9.25979 10.685 9.13261 10.685 9V8C10.6841 7.69053 10.5828 7.3889 10.395 7.13647C10.2072 6.88404 9.94214 6.69316 9.63609 6.59ZM4.39144 5C4.39144 4.46957 4.61246 3.96086 5.00589 3.58579C5.39931 3.21071 5.93291 3 6.4893 3C7.04568 3 7.57928 3.21071 7.97271 3.58579C8.36613 3.96086 8.58716 4.46957 8.58716 5V6.5H4.39144V5ZM6.4893 10C6.30625 9.99895 6.12667 9.95224 5.96842 9.86453C5.81016 9.77681 5.67874 9.65114 5.58722 9.5H7.39138C7.29985 9.65114 7.16843 9.77681 7.01018 9.86453C6.85193 9.95224 6.67235 9.99895 6.4893 10ZM9.63609 8.5H3.34251V8C3.34251 7.86739 3.39776 7.74021 3.49612 7.64645C3.59448 7.55268 3.72788 7.5 3.86697 7.5H9.11162C9.25072 7.5 9.38412 7.55268 9.48248 7.64645C9.58083 7.74021 9.63609 7.86739 9.63609 8V8.5Z" 
                fill={fill}
                className={classname}
                fillOpacity={1}
            />
        </svg>
        
    )
  }
  
  export default AlarmBellIcon