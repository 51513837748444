import "./index.scss";
import AncLotsCardHeaderDataContent from "./anc-lots-card-header-data-content";
import AncLotsCardBodyDataContent from "./anc-lots-card-body-data-content";
import TableHead from "reusable/anc-table/table-head";
import TableLotBody from "reusable/anc-table/table-lot-body";
import { processViewCardHeaderData } from "constants/thaw-inoc-constants/thaw-inoc-process-view";
import { MaterialDetailListInterface } from "assets/types/thaw-inoculation-process-view.types";

interface AncLotsCardContainerProps {
  lotsArrayLength: number;
  lotsIndex: number;
  materialName: string;
  materialList: MaterialDetailListInterface;
}

const AncLotsCardContainer: React.FC<AncLotsCardContainerProps> = (
  props: AncLotsCardContainerProps
) => {
  const { lotsArrayLength, lotsIndex, materialName, materialList } = props;

  return (
    <div
      className={
        (lotsIndex + 1 !== lotsArrayLength ? "anc-lots-border " : " ") +
        "anc-lots-card-container"
      }
    >
      <table className="anc-lots-table">
        <TableHead>
          <tr>
            {processViewCardHeaderData.map((item, index) => {
              return (
                <th key={index}>
                  <AncLotsCardHeaderDataContent
                    dataValue={materialName}
                    type={item.type}
                    headerIndex={lotsIndex}
                  />
                </th>
              );
            })}
          </tr>
        </TableHead>
        <TableLotBody>
          {materialList?.lots?.slice(0,2).map((row, index) => {
            return (
              <tr key={index}>
                {processViewCardHeaderData.map((item, index) => {
                  return (
                    <td key={index} className="ops-fs-6 ops-fw-semibold">
                      <AncLotsCardBodyDataContent
                        type={item.type}
                        colorCode={row.color_code ?? "N/A"}
                        cultureTimeMax={row.culture_time_max ?? "N/A"}
                        cultureTimeMin={row.culture_time_min ?? "N/A"}
                        runningStatus={row.running_status ?? "N/A"}
                        lotsName={row.name ?? "N/A"}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </TableLotBody>
      </table>
    </div>
  );
};
export default AncLotsCardContainer;
