/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios, {AxiosResponse} from "axios";
import { ErrorInfo } from "react";
import { getOktaToken } from "utils/common-methods";

interface configureDataProp {
  url: string;
  method: string;
  header: object;
  data: unknown
}

export const fetchApi: (configureData: configureDataProp) => Promise<AxiosResponse<any, any>> = async (
  configureData
) => {
  const token: string = getOktaToken();
  try {
    return await axios({
      ...configureData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  } catch (e: any) {
    throw new Error(e.message);
  }
};
