import LotsTilesContainer from "components/lots-tiles-container";
import DownStreamContainer from "./downsteam/container";
import BioReactorContainer from "./bio-reactor/container.anc";
import HarvestContainer from "./harvest/container.anc";

import "./index.scss";

const E2EPage = () => {
  return (
    <>
      <div 
      // className="ops-my-20"
      >
        <LotsTilesContainer />
        <div className="anc-d-flex anc-justify-content-between anc-bioreactor-harvest-row">
          <BioReactorContainer />
          <HarvestContainer />
        </div>
        <DownStreamContainer />
      </div>
    </>
  );
};
export default E2EPage;
