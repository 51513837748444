import React, { useState } from "react";
import "./index.scss";
import { Button, MetricCard } from "@gitlab-rtsensing/component-library";
import AncTitleImageCardHeader from "reusable/anc-title-image-card-header";
import AncLotsCardContainer from "reusable/anc-lots-card-container";
import {
 
  IncubatorStagesInterface,
  MaterialDetailListInterface,
} from "assets/types/thaw-inoculation-process-view.types";
import ThawInoculationModal from "../thaw-inoculation-modal";
import { ThawInoculationDataType } from "assets/types/thaw-modal-type";
import { AlarmType } from "types/bioreactor-trains-type";
interface ThawIncubatorCardInterface {
  incubatorStage: IncubatorStagesInterface;
  incubatorName: string;
  incubatorActiveAlarms: number;
  alarmsData: AlarmType[];
}

const ThawIncubatorCard: React.FC<ThawIncubatorCardInterface> = (
  props: ThawIncubatorCardInterface
) => {
  const { incubatorStage, incubatorName, alarmsData, incubatorActiveAlarms } =
    props;

  const [viewMoreModalState, setViewMoreModalState] = useState<boolean>(false);

  const lotsArray = incubatorStage?.material_detail_list.flatMap((item) =>
    item.lots.map((lot) => ({ ...lot, isopen: false }))
  );

  return (
    <div className="anc-thaw-inoculation-card-container">
      <MetricCard className={`anc-pd-0`}>
        <>
          <MetricCard.Header>
            <AncTitleImageCardHeader
              materialTitle={incubatorStage.name ?? "N/A"}
            />
          </MetricCard.Header>
          <MetricCard.Content className=" d-flex anc-thaw-inoc-card-content">
            <div>
              {incubatorStage?.material_detail_list?.map(
                (list: MaterialDetailListInterface, index: number) => {
                  return (
                    <AncLotsCardContainer
                      key={index}
                      lotsArrayLength={
                        incubatorStage?.material_detail_list?.length
                      }
                      lotsIndex={index}
                      materialList={list}
                      materialName={list.name}
                    />
                  );
                }
              )}
              <div
                onClick={() => setViewMoreModalState(true)}
                className="anc-thaw-button-wrapper"
              >
                <Button
                  className="anc-w-100"
                  label="VIEW MORE"
                  type="secondary"
                  iconPosition="right"
                  disabled={false}
                />
              </div>

              <div className="anc-view-more-modal-container">
                <ThawInoculationModal
                  opened={viewMoreModalState}
                  close={() => {
                    setViewMoreModalState(false);
                  }}
                  title={incubatorName}
                  alarms={incubatorActiveAlarms}
                  materialLots={lotsArray as ThawInoculationDataType[]}
                  incubatorName={incubatorStage.name}
                  alarmsDataArray={alarmsData}
                  isTable={false}
                  tableLot={{
                    color_code: "",
                    culture_time_max: "",
                    culture_time_min: "",
                    desc: "",
                    name: "",
                    stage: "",
                    next_equipment: "",
                    recipes: "",
                    running_status: "",
                    step_process_progress: "",
                    unit_op_progress: "",
                  }}
                />
              </div>
            </div>
          </MetricCard.Content>
        </>
      </MetricCard>
    </div>
  );
};
export default ThawIncubatorCard;
