import "./index.scss";

interface TableHeaderRowComponentProps {
  children: React.ReactNode;
}
const TableHeaderRowComponent: React.FC<TableHeaderRowComponentProps> = ({
  children,
}) => {
  return <tr className="anc-tab-table-Head-row">{children}</tr>;
};
export default TableHeaderRowComponent;
