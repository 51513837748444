import React from "react";
import "./index.scss";

interface AncMetricStatusProps {
    machineStatus?:string
}
const AncMetricStatus:React.FC<AncMetricStatusProps> = ({machineStatus}) => {
  return (
    (
        <div className="anc-machine-status ops-fw-bold ops-fs-5 ops-lh-3">
          {machineStatus}
        </div>
      )
  );
};
export default AncMetricStatus;
