import "./index.scss";

interface TableRowComponentProps {
  children: any;
  isIdle?: boolean;
}
const TableRowComponent: React.FC<TableRowComponentProps> = ({ children, isIdle }) => {
  return <tr className={!isIdle ? "anc-tab-table-body-row" : "anc-tab-table-idle-row"}>{children}</tr>;
};
export default TableRowComponent;
