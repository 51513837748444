import React, { useMemo } from 'react';
import classNames from 'classnames';
import cls from './tab-header.module.scss';

interface Props {
  index: number;
  label: string | JSX.Element;
  isActive: boolean;
  onClick: (index: number) => void;
}

export const TabHeader = (props: Props) => {
  const { onClick, label, index, isActive } = props;
  const rcn = 'ops-tab-header-item'; // rootClassName
  const activeClass = useMemo(() => (isActive ? 'is-active' : ''), [isActive]);
  const classes = classNames([cls[rcn], cls[activeClass]]);

  return (
    <li className={classes}>
      <button className={cls[`${rcn}-button`]} onClick={() => onClick(index)}>
        {label}
      </button>
    </li>
  );
};

export default TabHeader;
