import AncHeaderText from "reusable/anc-table/anc-header-text";
import TableBody from "reusable/anc-table/table-body";
import TableDescriptionComponent from "reusable/anc-table/table-description-component";
import TableHead from "reusable/anc-table/table-head";
import TableHeaderComponent from "reusable/anc-table/table-header-Component";
import TableHeaderRowComponent from "reusable/anc-table/table-header-row-component";
import AncTabTableComponent from "reusable/anc-table/table-wrapper";
import TableRowComponent from "reusable/anc-table/table-row-component";
import ErrorHandler from "utils/error-handler";
import { useState } from "react";
import { bioreactorTrainsHeaderData } from "constants/bioreactor-trains-constants/bioreactor-trains-header";
import {
  BioreactorTrainsTableDataObjProps,
  BioreactorTrainsTableModalDataObjProps,
} from "assets/types/bioreactor-trains-table-types";
import "./index.scss";
import TableDescriptionRender from "reusable/anc-table/table-description-render/table-description";
import BioReactorModal from "../bio-reactor-modal";
import EmptyTableDataComponent from "reusable/anc-empty-table-data-component";
interface BioreactorTrainType {
  isLoading: boolean;
  isError: boolean;
  bioreactorTrainsData: BioreactorTrainsTableDataObjProps[];
}

const BioReactorTableView: React.FC<BioreactorTrainType> = ({
  isLoading,
  isError,
  bioreactorTrainsData,
}) => {
  const [headerData, setHeaderData] = useState(bioreactorTrainsHeaderData);

  let sortedData = bioreactorTrainsData;
  const [viewMoreModal, setViewMoreModal] = useState<boolean>(false);
  const [viewMoreModalData, setViewMoreModalData] =
    useState<BioreactorTrainsTableModalDataObjProps>({
      alarm_data: [],
      cleaning_status_cip: "",
      desc: "",
      dirty_hold_timer: "",
      equipment: "",
      equipment_id: "",
      lot_id: "",
      max_culture_time: "",
      min_culture_time: "",
      name: "",
      next_equipment: "",
      process_step: "",
      recipes: "",
      schedule_adherences: "",
      schedule_adherences_color_code: "",
      state: "",
      status: "",
      status_color_code: "",
      step_process_progress: "",
      unit_op_progress: "",
      material: "",
    });

  return (
    <>
      <div className="anc-table-error-handler anc-align-items-center">
        <ErrorHandler
          isLoading={isLoading}
          isError={isError}
          loaderColor="#ffff"
        />
      </div>

      {!isLoading && !isError && bioreactorTrainsData?.length > 0 ? (
        <AncTabTableComponent>
          <TableHead>
            <TableHeaderRowComponent>
              {headerData.map((item, index) => {
                return (
                  <TableHeaderComponent key={index} align={item.align}>
                    <AncHeaderText
                      sortActive={item.sortActive}
                      textValue={item.header}
                      sortKey={item.sortKey}
                      alignText={item.align}
                      columnNameVisible={item.columnNameVisible}
                      sortFn={() => {
                        const updatedItems = [...headerData];
                        const indexFind = updatedItems.findIndex(
                          (item) => item.sortActive
                        );
                        updatedItems[index].sortKey =
                          !updatedItems[index].sortKey;
                        updatedItems[indexFind].sortActive = false;
                        updatedItems[index].sortActive = true;
                        setHeaderData(updatedItems);

                        sortedData = bioreactorTrainsData.sort(
                          item.sortfn.bind(item) as (
                            a: BioreactorTrainsTableDataObjProps,
                            b: BioreactorTrainsTableDataObjProps
                          ) => number
                        );
                      }}
                    />
                  </TableHeaderComponent>
                );
              })}
            </TableHeaderRowComponent>
          </TableHead>

          <TableBody>
            {sortedData?.map(
              (row: BioreactorTrainsTableDataObjProps, i: number) => (
                <TableRowComponent key={i} isIdle={row.status === "Idle"}>
                  {headerData.map((item, index) => {
                    return (
                      <TableDescriptionComponent
                        key={index}
                        align={item.align}
                        statusColor={row.status_color_code}
                      >
                        <TableDescriptionRender
                          columnComponentType={item.columnComponentType}
                          headerDataKey={item.datakey}
                          row={row}
                          buttonCallback={() => {
                            setViewMoreModalData({
                              ...viewMoreModalData,
                              status: row.status,
                              equipment_id: row.equipment_id,
                              material: row.material,
                              lot_id: row.lot_id,
                              step_process_progress: row.step_process_progress,
                              process_step: row.process_step,
                              schedule_adherences: row.schedule_adherences,
                              schedule_adherences_color_code: row.schedule_adherences_color_code
                            });
                            setViewMoreModal(true);
                          }}
                        />
                      </TableDescriptionComponent>
                    );
                  })}
                </TableRowComponent>
              )
            )}
          </TableBody>
          {viewMoreModalData ? (
            <div className="anc-view-more-modal-container">
              <BioReactorModal
                title={"Wave Bioreactor"}
                opened={viewMoreModal}
                close={() => {
                  setViewMoreModal(false);
                }}
                alarms={[]}
                status={viewMoreModalData.status}
                equipmentid={viewMoreModalData.equipment_id}
                material={viewMoreModalData.material}
                lot={viewMoreModalData.lot_id}
                stepprogress={viewMoreModalData.step_process_progress}
                processStep={viewMoreModalData.process_step}
                schedule={viewMoreModalData.schedule_adherences}
                scheduleColorCode={
                  viewMoreModalData.schedule_adherences_color_code
                }
              />
            </div>
          ) : null}
        </AncTabTableComponent>
      ) : <EmptyTableDataComponent></EmptyTableDataComponent>}
    </>
  );
};
export default BioReactorTableView;
