import { useQuery } from "react-query"
import BioReactor from "."
import { getBioReactorApi } from "api/bioreactor-api"
import { useParams } from "react-router-dom";

const BioReactorContainer = () => {
    const { site } = useParams();

    const {isLoading, isError, data: BioReactorData} = useQuery(['getBioReactor-Api', site],getBioReactorApi.bind(this,{
        mfg_stage: "string",
        site: site as string,
        building: "string",
        line_no: "string",
        curr_stage: "string"
    }))
    return (
        <BioReactor 
            isLoading={isLoading} 
            isError={isError} 
            data={BioReactorData?.data?.data} 
        />
    )
}
export default BioReactorContainer;
