import React from "react";
import "./index.scss";

interface AncMachineScheduleStatusProps {
    machineScheduleStatus:string
    runningColor? : string
}
const AncMachineScheduleStatus:React.FC<AncMachineScheduleStatusProps> = ({machineScheduleStatus , runningColor}) => {
  return (
    <div
    className={`anc-machine-schedule-status anc-running-status-${runningColor?.toLowerCase()} ops-fw-bold ops-fs-6 ops-lh-4 anc-text-uppercase anc-pl-2`}
  >
    {machineScheduleStatus.toUpperCase() === "ON SCHEDULE"
      ? "ON SCHED."
      : machineScheduleStatus}
  </div>
  );
};
export default AncMachineScheduleStatus;
