import React from "react";
import "./index.scss";
import PathwayIcon from "assets/icons/pathway-icon";

interface AncMetricHeaderProps {
  machineTitle: string;
  type?: string;
  iconActive? : boolean
}
const AncMetricHeader: React.FC<AncMetricHeaderProps> = ({
  machineTitle,
  type,
  iconActive
}) => {
  return (
    <div
      className={`anc-d-flex ops-fw-bold ops-fs-6 ops-lh-4 anc-text-uppercase ${
        type !== "bioreactor" ? " anc-card-title" : " anc-card-bioreactor-title"
      }`}
    >
      {machineTitle}
      {type === "bioreactor" && (
        <div className="anc-card-tag-icon" style={iconActive ? { backgroundColor: "#0063c3" } : {}}>
          <PathwayIcon  iconActive={iconActive}></PathwayIcon>
        </div>
      )}
    </div>
  );
};
export default AncMetricHeader;
