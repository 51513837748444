import React, {  useEffect, useState } from "react";
import StatusAndHeader from "./status-header";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import E2EContainer from "../e2e-tab/container.anc";
import SolutionPrepContainer from "components/solution-prep-tab/container.anc";
import Tabs from "reusable/dct-tab";
import { ComingSoon } from "@gitlab-rtsensing/component-library"
import BioReactorTrainsTabContainer from "components/bioreactor-trains-tab/container.anc";
import ThawInoculationProcessViewContainer from "components/thaw-inoculation-tab/container.anc";
import WeightAndDispenseContainer from "components/weigh-dispense-tab/container.anc";

const initialTabData = [
  {
    route: "/e2e",
    title: "E2E Process View",
    line_status: "No",
    content: <E2EContainer />,
  },
  {
    route: "/weigh_and_dispense",
    title: "Weigh and Dispense",
    line_status: "No",
    content: <WeightAndDispenseContainer/>,
  },
  {
    route: "/solution",
    title: "Solution Prep",
    line_status: "No",
    content: <><SolutionPrepContainer/></>,
  },
  {
    route: "/inoculation",
    title: "Thaw/Inoculation",
    line_status: "No",
    content: <><ThawInoculationProcessViewContainer/></>,
  },{
    route: "/bioreactor_trains",
    title: "Bioreactor Trains",
    line_status: "No",
    content: <BioReactorTrainsTabContainer/>,
  },{
    route: "/harvest",
    title: "Harvest",
    line_status: "No",
    content: <ComingSoon label="Coming Soon"/>,
  },
  {
    route: "/downstream",
    title: "Downstream", 
    line_status: "No",
    content: <ComingSoon label="Coming Soon"/>,
  },
  
];

const AncTabContainer: React.FC = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const { tab_route: tabRoute } = useParams();
  const { site } = useParams()
  const tabData = initialTabData.map((item: TabDataType, index: number) => {
    
    return {
      content: item.content,
      header: {
        onClick: (index: number) => {
          navigate(`/${site}${item.route}`);
        },
        label: (
          <>
            <StatusAndHeader
              title={item.title}
              status={"RED"}
              link={"in the tab"}
            />
          </>
        ),
      },
    };
  });

  useEffect(() => {
    if (!tabRoute) {
      navigate(`/${site}${initialTabData[0].route}`);
    } else {
      setTabIndex((_) => {
        const getIndex = initialTabData.findIndex(
          (item: TabDataType, index: number) => item.route.slice(1) === tabRoute
        );
        return getIndex > -1 ? getIndex : 0;
      });
    }
  }, [tabRoute]);

  return (
    <>
      <div id="dct-anc-tab">
      <Tabs
          defaultTab={tabIndex}
          className="anc-tab-content"
          key={1}
          tabs={tabData}
        />
      </div>
    </>
  );
};

export default AncTabContainer;
