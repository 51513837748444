import "./index.scss";
import "../../assets/bootstrap.scss"

interface AncTitleLegendContainerProps {
  children: React.ReactNode;
}
const AncTitleLegendContainer: React.FC<AncTitleLegendContainerProps> = ({
  children,
}) => {
  return <div className="anc-label-tab-wrapper anc-d-flex">{children}</div>;
};
export default AncTitleLegendContainer;
