import { payloadSolType, payloadType } from "types/api.types";
import { fetchApi } from "./fetch-api";

export const getSolutionPrepContainerApi = async (data:payloadSolType) => {

    const response = await fetchApi({
      header: {},
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/solution_prep_details`,
      data
    })
   
    return response;
     
}