import "./index.scss";

interface AncMetricTitleProps {
  title?: string;
  className ? : string
  type? : string
  
}
const AncMetricTitle: React.FC<AncMetricTitleProps> = ({
  title,
  className,
  type
}) => {
  return (
    <div className={`anc-metric-title ${className} ${type ==="bioreactor" ? "ops-fw-bold" : "ops-fw-medium"}    ops-fs-5 ops-lh-3`}>{title}</div>
  );
};
export default AncMetricTitle;
