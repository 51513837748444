import "./index.scss";
import HeaderValueTextContent from "../header-value-text-content";
import CleanProgressBar from "reusable/clean-progress-bar";
import TimerHeaderValueContent from "../timer-header-value-content";
import {
  Button,
  ExpandArrowsIcon,
  ExternalLinkIcon,
} from "@gitlab-rtsensing/component-library";
import { getMachineImage } from "utils/common-methods";

interface ViewMoreContentProps {
  actualStart: string;
  lot: string;
  material: number;
  materialDescription: string;
  progressValue: number | string;
  processLot: string;
  remainingTime: string;
  schedule: string;
  scheduleColorCode: string;
  solutionDescription: string;
  solutionLot: number;
  targetStart: string;
}

const ViewMoreContent = (props: ViewMoreContentProps) => {
  const {
    actualStart,
    lot,
    material,
    materialDescription,
    progressValue,
    processLot,
    remainingTime,
    schedule,
    scheduleColorCode,
    solutionDescription,
    solutionLot,
    targetStart,
  } = props;
  return (
    <div className="anc-sol-prep-view-more-content">
      <div className="anc-d-flex anc-image-lot-container">
        <div>{getMachineImage("view_more_modal_image")}</div>
        <div className="anc-d-flex anc-pl-5 anc-material-lot-wrapper">
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"material #"}
                dataValue={material ?? "N/A"}
                dataColor={""}
              />
            </div>
            <div className="anc-d-flex anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"lot #"}
                dataValue={lot ?? "N/A"}
                dataColor={""}
              />
            </div>
          </div>
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"Schedule Adherence"}
                dataValue={schedule ?? "N/A"}
                dataColor={scheduleColorCode ?? "N/A"}
              />
            </div>
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <div className="ops-fs-6 anc-progress-bar-header ops-fw-bold">
                PROGRESS
              </div>
              <div className="anc-w-100 anc-d-flex anc-progress-bar">
                {progressValue !== "Completed" &&
                progressValue !== "Not Started" ? (
                  <CleanProgressBar
                    percentageArray={[
                      { percentage: progressValue, flag: "BLUE" },
                    ]}
                  />
                ) : progressValue === "Completed" ? (
                  <div className="ops-fs-5 anc-complete">{"Completed"}</div>
                ) : (
                  <div className="ops-fs-5 anc-complete">{"Not Started"}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="anc-d-flex anc-process-bar-container">
        <div>
          <HeaderValueTextContent
            headerValue={"Material description"}
            dataValue={materialDescription ?? "N/A"}
            dataColor={""}
          />
        </div>
      </div>
      <div className="anc-d-flex anc-timer-container">
        <div>
          <TimerHeaderValueContent
            headerValue={"target start"}
            dataValue={targetStart ?? "N/A"}
          />
        </div>
        <div>
          <TimerHeaderValueContent
            headerValue={"actual start"}
            dataValue={actualStart ?? "N/A"}
          />
        </div>
        <div>
          <TimerHeaderValueContent
            headerValue={"remaining time"}
            dataValue={remainingTime ?? "N/A"}
          />
        </div>
      </div>
      <div className="anc-d-flex anc-sol-prep-button-container">
        <div className="anc-pi-vision-button-wrapper">
          <Button
            className="anc-pi-vision-button"
            label="GO TO PI VISION"
            // target="_blank"
            type="secondary"
            iconPosition="right"
            icon={<ExternalLinkIcon width={12} height={12} fill="#0063c3" />}
            disabled={false}
          />
        </div>
        <div className="anc-simca-online-button-wrapper">
          <Button
            className="anc-simca-online-button"
            label="GO TO SIMCA ONLINE"
            // target="_blank"
            type="secondary"
            iconPosition="right"
            icon={<ExternalLinkIcon width={12} height={12} fill="#0063c3" />}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewMoreContent;
