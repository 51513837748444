import "./index.scss";

interface TitleProps {
  title: string;
  height?: string;
  weight?: string;
}

const Title = (props: TitleProps) => {
  const { title, height, weight } = props;

  return (
    <div
      className={
        (height ? `${height}` : `ops-fs-3`) +" "+
        (weight ? `${weight}` : `ops-fw-bold`) +" "+
        `ops-font-arial`
      }
    >
      {title}
    </div>
  );
};

export default Title;
