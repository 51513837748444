import React from "react";
import "./index.scss";
import BellIcon from "assets/icons/bell-icon";
import CautionIcon from "assets/icons/caution-icon";

interface AncMachineDownTextProps {}
const AncMachineDownText: React.FC<AncMachineDownTextProps> = () => {
  return (
    <div
      className={`anc-machine-down-text anc-d-flex  ops-fw-medium ops-lh-3 ops-fs-5`}
    >
      <CautionIcon></CautionIcon>
      <span>Down</span>
    </div>
  );
};
export default AncMachineDownText;
