import "./index.scss";

interface AncTitleHeaderProps {
  title: string;
  height?: string;
  weight?: string;
}
const AncTitleHeaderText: React.FC<AncTitleHeaderProps> = (
  props: AncTitleHeaderProps
) => {
  return <div className="anc-title-header-text"> {props.title ?? ""}</div>;
};
export default AncTitleHeaderText;
