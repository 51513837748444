
import AlarmBellIcon from "assets/icons/alarm-bell-icon";
import "./index.scss"
import { getStatusColor } from "utils/common-methods";

interface AncAlarmWithToolTipType {
    alarm: number;
    tooltip?: React.ReactNode;
    tooltipId: string;
    colorCode: string;
}
const AncAlarmWithToolTip: React.FC<AncAlarmWithToolTipType> = ({alarm, tooltip, tooltipId, colorCode}) => {
    const fill = getStatusColor(colorCode);
    let className = "anc-alarm-container";
    if (colorCode === "red") { 
        className += " anc-alarm-container-red";
    } else if (colorCode === 'green') {
        className += " anc-alarm-container-green";
    } else if (colorCode === 'grey') {
        className += " anc-alarm-container-grey";
    } 

    return <div id={tooltipId} className={className}> 
                <div className="anc-alarm-block"><AlarmBellIcon fill={fill} /></div>
                <div className="anc-alarm-block">{alarm}</div>
                {tooltip && tooltip}
            </div>

};
export default AncAlarmWithToolTip;