import React from "react";
import "./index.scss";
interface AncMetricHeaderProps {
    header : string;
    serialNo : number;
    ancHeader?: string;
}
const AncMetricHeader:React.FC<AncMetricHeaderProps> = ({header,serialNo,ancHeader}) => {
  return (
    
    <div className="anc-d-flex anc-mb-2 anc-metric-header-container">
        <div className="anc-metric-serial-no ops-fs-6 ops-lh-4 ops-fw-bold anc-pl-1 anc-mr-2 anc-mb-1">{serialNo}</div>
        <div className="anc-metric-header ops-fs-4 ops-lh-2 ops-fw-bold anc-pb-1">{header}</div>
        {ancHeader&& <div className="anc-metric-lots ops-px-3 ops-fs-5 anc-pb-1">{ancHeader}&nbsp;Lots</div>}
    </div>
        
  );
};
export default AncMetricHeader;
