import React from "react";
import "./index.scss";
import AncMetricTag from "reusable/anc-metric-tag";
interface WeighExpenseCardProps {
  cardData: cardDataProps;
  tooltipIndex: string;
  showRunningStatus: boolean;
}
interface cardDataProps {
  running_status: string;
  current_status: string;
  color_code: string;
  product_batch_no: string;
}

const LotsCardsContent: React.FC<WeighExpenseCardProps> = (
  props: WeighExpenseCardProps
) => {
  const { cardData, tooltipIndex, showRunningStatus } = props;

  return (
    <div className="anc-weight-expense-container anc-d-flex anc-mb-1">
      {showRunningStatus && 
        <div
          className={
            (cardData.color_code.toLocaleLowerCase() === "red"
              ? "anc-schedule-red"
              : "anc-schedule") +
            " ops-fs-6 ops-fw-bold anc-text-uppercase anc-mr-1 "
          }
          title={cardData.running_status === "ON SCHEDULE"
          ? "ON SCHED."
          : cardData.running_status}
        >
          <>
          {cardData.running_status === "ON SCHEDULE"
            ? "ON SCHED."
            : cardData.running_status}
          </>
        </div> 
      }
      <div
        id={`anc-first-float-data-anchor${tooltipIndex}`}
        className="anc-mr-1"
        title={cardData?.product_batch_no}
      >
        <AncMetricTag machineTitle={cardData?.product_batch_no} />
      </div>

      <div className="ops-fs-5 anc-desc" 
        title={cardData?.current_status}>
        {cardData.current_status}
      </div>
    </div>
  );
};
export default LotsCardsContent;
