import React from "react";
import "./index.scss";

interface AncMachinePhaseProps {
  machinePhase: string;
  className?: string;
}
const AncMachinePhase: React.FC<AncMachinePhaseProps> = ({ machinePhase , className }) => {
  return (
    <div className={`anc-card-machine-phase ${className} ops-fw-medium ops-fs-5 ops-lh-3`}>
      {machinePhase}
    </div>
  );
};
export default AncMachinePhase;
