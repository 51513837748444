import "./index.scss";

interface TimerHeaderValueContentProps {
  headerValue: string;
  dataValue?: string;
  multipleDataValues?: string[];
}
const TimerHeaderValueContent = (props: TimerHeaderValueContentProps) => {
  const { headerValue, dataValue,multipleDataValues } = props;
  return (
    <>
      <div className="ops-fs-6 anc-timer-header anc-text-uppercase ops-fw-bold">
        {headerValue}
      </div>
      {dataValue ? (
        <div className="ops-fs-5 anc-timer-data">{dataValue}</div>
      ) : null}
      {multipleDataValues
        ? multipleDataValues?.map((value, i) => (
            <div key={i} className="ops-fs-5 anc-timer-data anc-pb-2">
              {value}
            </div>
          ))
        : null}
    </>
  );
};

export default TimerHeaderValueContent;
