import AncStatusLogo from "reusable/anc-status-logo";
import './index.scss';
import AlertIcon from "assets/icons/alert-icon";

interface legendTooltipProps {
    title: string;
    labelColor: string;
    labelImageType?: string | undefined;
    text: string;
}

const LegendTooltip = (props: legendTooltipProps) => {
    return (
        <div className="anc-legend-tooltip">
            <div className="anc-legend-tooltip-container">
                <div className="anc-legend-tooltip-heading d-flex flex-row align-items-center">
                    {props.labelImageType==='alert' 
                    ? <AlertIcon fill="#d62728" classname="anc-pt-1"/> 
                    : <AncStatusLogo color={props.labelColor.toLowerCase()} size={8} />}
                    <div>{props.title}</div>
                </div>
                <div className="anc-legend-tooltip-content">{props.text}</div>
            </div>

        </div>
    );
}

export default LegendTooltip;