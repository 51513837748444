import { useEffect } from "react";
import PageLayout from "reusable/layout";
import SegmentHeader from "reusable/segment-header";
import AncTabContainer from "components/anc-tab-container";
import { useParams } from "react-router-dom";
import { getNavList } from "utils/common-methods";
import RefreshE2E from "../components/anc-tab-refresh/tab-refresh-container.anc";
import UpcomingLotsSectionContainer from "components/upcoming-lots-container/container.anc";
import "./index.scss";
interface ShopFloorType {
  setNavList: (navList: any) => void;
}

const ShopFloorPage = (props: ShopFloorType) => {
  const { setNavList } = props;
  const { tab_route: tabRoute, site } = useParams();
  
  useEffect(() => {
    setNavList(
    getNavList(tabRoute ?? "", site as string));
  }, [tabRoute]);

  return (
    <>
      <PageLayout>
        <>
        <div className="sticky-header-shop-floor">
          <div className="ops-mb-1 anc-align-items-center anc-d-flex anc-justify-content-between ">
            <SegmentHeader id="anc-shop-floor" title={`${site?.toUpperCase()} Shop Floor`} />
            {site !== 'ari' ? <></>: <RefreshE2E/>}
          </div>
          <div>
            {site !== 'ari' ? <></> : <UpcomingLotsSectionContainer />}
          </div>
        </div>
        {
          site !== 'ari' ?<div style={{margin: '320px', padding: '36px'}}><div style={{margin: 'auto', textAlign: 'center'}} className="ops-fs-3">Coming Soon</div> </div> : <AncTabContainer />
        }
        </>
      </PageLayout>
    </>
  );
};
export default ShopFloorPage;
