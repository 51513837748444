/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  MetricCard,
  MetricCardHeader,
  Status,
  Tooltip,
} from "@gitlab-rtsensing/component-library";
import { type Alert } from "@gitlab-rtsensing/component-library/lib/components/types";
import React from "react";
import "./index.scss";
interface CardProps {
  children: React.ReactNode;
  header: string;
  headerStatusColor?: Alert;
  isToolTip?: boolean;
  isStatus?: boolean;
  tooltipContent?:string;
  className?:string;
}

const CardComponent: React.FC<CardProps> = (props) => {

  return (
    <MetricCard className={`anc-metric-card anc-pd-0 ${props.className}`}>
      <>
        {props.header && <MetricCardHeader className="ops-fs-4 ops-fw-bold">
          <div className="d-flex">
            {props.isStatus && (
              <div className="anc-pr-2">
                <Status alert="Green"className="anc-disable-status-hover" />
              </div>
            )}
            {/* <NeutralCircle/> */}
            {<div className="ops-fs-4 ops-fw-bold">{props.header.split(/(?=\sfor\s)/)?.[0]}<span className="ops-fw-medium">{props.header.split(/(?=\sfor\s)/)?.[1]}</span></div>}
            {props.isToolTip && (
              <div className="ops-fs-4 anc-d-flex align-items-center anc-pt-1 ops-fw-bold">
                <Tooltip className="anc-ml-1 component-library-tooltip">
                  {props.tooltipContent} 
                </Tooltip>
              </div>
            )}
          </div>
        
        </MetricCardHeader>}

        <MetricCard.Content className="d-flex anc-metric-card-content ">
          <>{props.children}</>
        </MetricCard.Content>
      </>
    </MetricCard>
  );
};
export default CardComponent;
