import React from "react";
import "./index.scss";

interface AncMachineIdProps {
  machineId: string;
}
const AncMachineId: React.FC<AncMachineIdProps> = ({ machineId }) => {
  return (
    <div className="anc-card-machine-id ops-fw-bold ops-fs-5 ops-lh-3">
      {`ID: ${machineId}`}
    </div>
  );
};
export default AncMachineId;
