import { SolutionPrepTableDataObjProps } from "assets/types/anc-sol-prep-table-types";
import moment from "moment";

export const solPrepHeaderData = [
  {
    datakey: "material",
    align: "right",
    key: 1,
    sortKey: false,
    sortfn: function (
      a: SolutionPrepTableDataObjProps,
      b: SolutionPrepTableDataObjProps
    ): number {
      if (this.sortKey) {
        return a.material > b.material ? 1 : -1;
      } else {
        return b.material > a.material ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Material #",
    sortActive: false,
  },
  {
    datakey: "lot",
    align: "right",
    key: 2,
    sortKey: false,
    sortfn: function (
      a: SolutionPrepTableDataObjProps,
      b: SolutionPrepTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.lot > b.lot ? 1 : -1;
      } else {
        return b.lot > a.lot ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Lot #",
    sortActive: false
  },
  {
    datakey: "target_start",
    align: "left",
    key: 5,
    sortKey: false,
    sortfn: function (
      a: SolutionPrepTableDataObjProps,
      b: SolutionPrepTableDataObjProps
    ) {
      const momentA = moment(a.target_start, "hh:mmA"); // Parse time string
      const momentB = moment(b.target_start, "hh:mmA"); // Parse time string

      if (this.sortKey) {
        return momentA.isBefore(momentB) ? 1 : -1;
      } else {
        return momentB.isBefore(momentA) ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Target Start",
    sortActive: false
  },
  {
    datakey: "actual_start",
    align: "left",
    key: 6,
    sortKey: false,
    sortfn: function (
      a: SolutionPrepTableDataObjProps,
      b: SolutionPrepTableDataObjProps
    ) {
      const momentA = moment(a.actual_start, "hh:mmA");
      const momentB = moment(b.actual_start, "hh:mmA");

      if (this.sortKey) {
        return momentA.isBefore(momentB) ? 1 : -1;
      } else {
        return momentB.isBefore(momentA) ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Actual Start",
    sortActive: false
  },
  {
    datakey: "remaining_time",
    align: "left",
    key: 7,
    sortKey: false,
    sortfn: function (
      a: SolutionPrepTableDataObjProps,
      b: SolutionPrepTableDataObjProps
    ) {
      const momentA = moment(a.remaining_time, "hh:mmA"); 
      const momentB = moment(b.remaining_time, "hh:mmA"); 

      if (this.sortKey) {
        return momentA.isBefore(momentB) ? 1 : -1;
      } else {
        return momentB.isBefore(momentA) ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Remaining Time",
    sortActive: false
  },
  {
    datakey: "schedule",
    align: "left",
    key: 8,
    sortKey: false,
    sortfn: function (
      a: SolutionPrepTableDataObjProps,
      b: SolutionPrepTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.schedule_in_secs > b.schedule_in_secs ? 1 : -1;
      } else {
        return b.schedule_in_secs > a.schedule_in_secs ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Schedule",
    sortActive: true
  },
  {
    datakey: "",
    sortfn: () => {},
    align: "center",
    key: 9,
    sortKey: false,
    columnNameVisible: false,
    columnComponentType: "button",
    header: "View More",
    sortActive: false
  },
];
