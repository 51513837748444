import { useQuery } from "react-query"
import SolutionPrep from "."
import { getSolutionPrepApi } from "api/solutionprep-api"
import { useParams } from "react-router-dom";

const SolutionPrepContainer = () => {
    const { site } = useParams();

    const {isLoading, isError, data: solutionPrepData} = useQuery(['getSolutionPrep-Api',site],getSolutionPrepApi.bind(this,{
        mfg_stage: "string",
        site: site as string,
        building: "string",
        line_no: "string",
        curr_stage: "string"
    }))
    return (
        <SolutionPrep 
            isLoading={isLoading} 
            isError={isError} 
            data={solutionPrepData?.data?.data} 
        />
    )
}
export default SolutionPrepContainer;
