
import "./index.scss";
import AncMachineAlarmComponent from "reusable/anc-machine-alarm-component";
import { useState } from "react";
import { thawInoculationHeaderInterface } from "assets/types/thaw-inoc-header-types";
import ErrorHandler from "utils/error-handler";
import { headerAlarmsData } from "constants/thaw-inoc-constants/thaw-inoc-time-alarm";
import APIErrorMessage from "utils/api-error";
interface ThawInoculationTimeAlarmContainerInterface {
  thawHeaderData: thawInoculationHeaderInterface;
  thawInocHeaderIsLoading: boolean;
  thawInocHeaderIsError: boolean;
}

const ThawInoculationTimeAlarmContainer = (
  props: ThawInoculationTimeAlarmContainerInterface
) => {
  const [headerdata, setHeaderData] = useState(headerAlarmsData);
  return (
    <>
      <div className="anc-thaw-header-error-handler">
        <ErrorHandler
          isLoading={props.thawInocHeaderIsLoading}
          isError={props.thawInocHeaderIsError}
          loaderColor="#ffff"
        />
      </div>
      {!props.thawInocHeaderIsLoading && !props.thawInocHeaderIsError && props.thawHeaderData ? (
        <div className="anc-thaw-time-alarm-container anc-d-flex">
          {headerdata.map((item, index) => {
            return (
              <AncMachineAlarmComponent
                key={index}
                headerLabel={item.header}
                headerKey={item.headerKey}
                headerData={props.thawHeaderData}
              />
            );
          })}
        </div>
      ):<APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
    </>
  );
};
export default ThawInoculationTimeAlarmContainer;
