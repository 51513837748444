// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs_ops-tabs__FuPXo{content:""}.tabs_ops-tabs-container-header__rLnT4{position:relative;width:100%}.tabs_ops-tabs-container-header--tile__Epn\\+f::after,.tabs_ops-tabs-container-header--section__PIzaz::after{position:absolute;left:0;right:0;bottom:0;height:3px;content:"";border-radius:1.5px}.tabs_ops-tabs-container-header--section__PIzaz::after{background-color:var(--ops-gray-300)}.tabs_tabs-filter-button-container__gqYs0{list-style:none;margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/reusable/dct-tab/tabs.module.scss"],"names":[],"mappings":"AACA,sBACI,UAAA,CAGF,uCACE,iBAAA,CACA,UAAA,CAEA,4GAEE,iBAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,UAAA,CACA,mBAAA,CAGF,uDACE,oCAAA,CAIJ,0CACE,eAAA,CACA,gBAAA","sourcesContent":["\n.ops-tabs {\n    content: '';\n  }\n  \n  .ops-tabs-container-header {\n    position: relative;\n    width: 100%;\n  \n    &--tile::after,\n    &--section::after {\n      position: absolute;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      height: 3px;\n      content: '';\n      border-radius: 1.5px;\n    }\n  \n    &--section::after {\n      background-color: var(--ops-gray-300);\n    }\n  }\n  \n  .tabs-filter-button-container {\n    list-style: none;\n    margin-left: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ops-tabs": `tabs_ops-tabs__FuPXo`,
	"ops-tabs-container-header": `tabs_ops-tabs-container-header__rLnT4`,
	"ops-tabs-container-header--tile": `tabs_ops-tabs-container-header--tile__Epn+f`,
	"ops-tabs-container-header--section": `tabs_ops-tabs-container-header--section__PIzaz`,
	"tabs-filter-button-container": `tabs_tabs-filter-button-container__gqYs0`
};
export default ___CSS_LOADER_EXPORT___;
