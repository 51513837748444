import "./anc-site.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import ShopFloorPage from "page/shop-floor-page";
import { useState } from "react";
import { AppContext } from "context/app-context";
import DesktopSideNav from "components/side-nav";
import AuthComponent from "components/auth-component";
import RequiredAuthComponent from "components/auth-component/required-auth";

const initialState = {
  navRoot: null,
  plant: "",
  mfg_stg: "",
  building: "",
  line: "",
  curr_stage: null,
  refetchInterval: 30000,

  //  More you can add here if any other state findings..
};

const AncSite: React.FC = () => {
  const { site } = useParams()
  const [appState, setAppState] = useState<any>(initialState);
  const [navList, setNavList] = useState({
    label: `${site?.toUpperCase()} SHOP FLOOR`,
    authorized: true,
    jumpToSections: [],
    externalSections: [],
  });

  useState(() => {
    setAppState({
      ...appState,
      refetchInterval: parseInt(
        process.env.REACT_APP_REFETCH_INTERVAL ?? "30000"
      ),
    });
  });

  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ appState, setAppState, initialState }}>
        <QueryClientProvider client={queryClient}>
          <AuthComponent>
          <div className="site-wrapper">
            <Routes>
              <Route path="/:site" element={<RequiredAuthComponent/>}>
                <Route path="/:site" element={<ShopFloorPage setNavList={setNavList} />} />
              </Route>
              <Route path="/:site/:tab_route" element={<RequiredAuthComponent />}>
                <Route path="/:site/:tab_route" element={<ShopFloorPage setNavList={setNavList} />} />
              </Route>
            </Routes>
          </div>

          <DesktopSideNav hideNav={false} activeRouteData={navList} />
          </AuthComponent>
        </QueryClientProvider>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default AncSite;
