import { payloadType } from "types/api.types";
import { fetchApi } from "./fetch-api";
import BioreactorTrainsData from "assets/mock-data/bioreactor-trains";

export const getBioReactorTableViewContainerApi = async (data:payloadType) => {

    const response = await fetchApi({
        header: {},
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/bioreactor_table_view`,
        data
      })
     
      return response;
     
}