import React from "react";
import "./index.scss";
import { ClockIcon } from "@gitlab-rtsensing/component-library";

interface TagsProps {
  data: string | undefined
  backgroundColor ?: string
}

const RefreshTag: React.FC<TagsProps> = (props : TagsProps) => {
    
  return (
    <div className={` anc-mt-0 d-flex align-items-center ${props.backgroundColor?"anc-grey-tag-details":"anc-tag-details"}`}>   
      <div
        className="d-flex anc-tag-data anc-p-1 ops-fs-6 align-items-center anc-text-uppercase ops-fw-bold"
        onClick={() => {}}
      >
        <div>
          {
            <ClockIcon
              fill="#797979"
              height={18}
              className="anc-tag-icon"
            />
          }
        </div>

        {"data refreshed "}
        <div data-testid="refreshTag" className="anc-tag-time ops-fs-6 align-items-center anc-text-uppercase ops-fw-bold">
          &nbsp;{props.data ?? "N/A" }
        </div>
      </div>
    </div>
  );
};

export default RefreshTag;
