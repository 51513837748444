import React from "react";
import AncMetricCard from "reusable/anc-metric-card";
import AncMetricHeader from "reusable/anc-metric-header";
import AncMetricTitle from "reusable/anc-metric-title";
import ErrorHandler from "utils/error-handler";
import TileWrapper from "reusable/anc-tile-wrapper";
import {
  DownStreamDataInterface,
  DownStreamValueInterface,
} from "types/down-stream.types";
import "./index.scss";
import DownstreamImages from "assets/mock-data/downstream-images";
import APIErrorMessage from "utils/api-error";

interface DownstreamWrapperType {
  isLoading: boolean;
  isError: boolean;
  downStreamData: DownStreamDataInterface ;
}

const DownstreamWrapper: React.FC<DownstreamWrapperType> = ({
  isError,
  isLoading,
  downStreamData,
}) => {
  return (
    <div id="down-stream-wrapper" className="downstream-wrapper anc-mt-2 ">
      <ErrorHandler
        isError={isError}
        isLoading={isLoading}
        loaderColor="#ffff"
      />
     
      <AncMetricHeader header="Down Stream" serialNo={6} />

      <TileWrapper>

        <div className="downstream-cards-wrapper">
          {!isLoading && !isError && downStreamData?.values?.length > 0 ? (
          downStreamData?.values
            ?.sort((a, b) => a.order_id - b.order_id)
            .map((item: DownStreamValueInterface, index: number) => {
              return (
                <div key={parseInt(
                  (Math.random() * 500).toString()
                ).toString()} className="anc-downstream">
                  <AncMetricTitle title={item.equipment} className="anc-ml-2" />
                  <AncMetricCard
                    machineStatus={item.status}
                    machineScheduleStatus={item.running_status}
                    machinePhase={item.machine_status}
                    machineTitle={item?.lot?.name}
                    image={DownstreamImages[index]}
                    statusColor={item?.color_code}
                    runningColor={item?.running_status_code}
                    tooltipIndex={parseInt(
                      (Math.random() * 500).toString()
                    ).toString()}
                    activealarms={item?.lot?.active_alarms}
                    materialdesc={item?.lot?.desc}
                    batchno={item?.lot?.name}
                    machinestatus={item?.machine_status}
                    receipeduration={item?.lot?.receipe_duration}
                    recipe={item?.lot?.recipe}
                    running={item?.lot?.running}
                    unitstatus={item?.status}
                  />
                </div>
              );
            })) : <APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
        </div>
      </TileWrapper>
     
    </div>
  );
};
export default DownstreamWrapper;
