const DownstreamImages = [
  "neutralization",
  "pro_a_column",
  "hold_tank",
  "low_ph_viral",
  "cex_column",
  "aex_column",
  "viral_filtration",
  "uf_df",
  "polysorbate_addition",
  "bds_fill",
];

export default DownstreamImages;
