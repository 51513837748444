import { Status } from "@gitlab-rtsensing/component-library";
import CircleIcon from "assets/icons/circle-icon";
import { getStatusColor } from "utils/common-methods";

interface AncStatusLogoProps {
  color: string;
  size : number;
  alertClassName ? : string
  className ? : string
}
const AncStatusLogo: React.FC<AncStatusLogoProps> = ({ color , size , className , alertClassName}: AncStatusLogoProps) => {
  const otherColor = color !== "green" && color !== "red";
  return (
    <>
      {color === "green" && (
        <Status
          height={size}
          alert="Green"
          className={alertClassName}
        />
      )}
      {color === "red" && (
        <Status height={size} alert="Red" className={alertClassName} />
      )}
      {otherColor && (
        <div className={className}>
          <CircleIcon fill={color} height={size} width={size} />
        </div>
      )}
    </>
  );
};

export default AncStatusLogo;