import { useQuery } from "react-query";
import { getThawInocProcessViewApi } from "api/thaw-inoc-process-view-api";
import ThawInoculationTab from ".";
import { getThawInocTableViewApi } from "api/thaw-inoc-table-view-api";
import { getThawInocTabHeaderApi } from "api/thaw-inoc-tab-header-api";
import { useParams } from "react-router-dom";

const ThawInoculationProcessViewContainer = () => {
   const { site } = useParams();
  const {
    isLoading: thawProcessViewIsLoading,
    isError: thawProcessViewIsError,
    data: thawProcessViewData,
  } = useQuery(
    ["getThawInocProcessView-Api", site],
    getThawInocProcessViewApi.bind(this, {
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
    })
  );

  const {
    isLoading: thawInocTableViewIsLoading,
    isError: thawInocTableViewError,
    data: thawInocTableViewData,
  } = useQuery(
    ["getThawInocTableView-Api",site],
    getThawInocTableViewApi.bind(this, {
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
    })
  );

  const {
    isLoading: thawInocHeaderIsLoading,
    isError: thawInocHeaderIsError,
    data: thawInocHeaderData,
  } = useQuery(
    ["getThawInocTabHeader-Api", site],
    getThawInocTabHeaderApi.bind(this, {
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
    })
  );
  
  return (
    <ThawInoculationTab
      thawProcessViewIsLoading={thawProcessViewIsLoading}
      thawProcessViewIsError={thawProcessViewIsError}
      thawProcessViewData={thawProcessViewData?.data?.data}
      thawTableViewIsLoading={thawInocTableViewIsLoading}
      thawTableViewIsError={thawInocTableViewError}
      thawTableViewData={thawInocTableViewData?.data?.data}
      thawInocHeaderIsLoading={thawInocHeaderIsLoading}
      thawInocHeaderIsError={thawInocHeaderIsError}
      thawInocHeaderData={thawInocHeaderData?.data?.data}
    />
  );
};

export default ThawInoculationProcessViewContainer;
