import "./index.scss";

interface HeaderValueTextContentProps {
  headerValue: string;
  dataValue: string | number;
  dataColor: string;
}
const HeaderValueTextContent = (props: HeaderValueTextContentProps) => {
  const { headerValue, dataValue, dataColor } = props;
  return (
    <>
      <div className="ops-fs-6 anc-value-header anc-text-uppercase ops-fw-bold">
        {headerValue}
      </div>
      <div
        className={
          (dataColor === "RED" ? "anc-value-data-red " : "anc-value-data ") +
          "ops-fs-5 anc-value-data"
        }
      >
        {dataValue}
      </div>
    </>
  );
};

export default HeaderValueTextContent;
