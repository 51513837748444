import "./index.scss";

interface TableHeadProps {
  children: React.ReactNode;
}
const TableHead: React.FC<TableHeadProps> = ({
  children,
}) => {
  return <thead className="anc-tab-table-Head">{children}</thead>;
};
export default TableHead;
