import { getThawInocTabHeaderApi } from "api/thaw-inoc-tab-header-api";
import BioReactorTrainsTab from ".";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const BioReactorTrainsTabContainer = () => {
  const { site } = useParams();

  const {
    isLoading: bioReactorHeaderIsLoading,
    isError: bioReactorHeaderIsError,
    data: bioReactorHeaderData,
  } = useQuery(
    "getbioReactorTabHeader-Api",
    getThawInocTabHeaderApi.bind(this, {
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
    })
  );
  return (
    <BioReactorTrainsTab
      bioReactorHeaderIsLoading={bioReactorHeaderIsLoading}
      bioReactorHeaderIsError={bioReactorHeaderIsError}
      bioReactorHeaderData={bioReactorHeaderData?.data?.data}
    />
  );
};
export default BioReactorTrainsTabContainer;
