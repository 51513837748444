import { useQuery } from "react-query";
import BioReactorTableView from ".";
import BioreactorTrainsData from "assets/mock-data/bioreactor-trains";
import { getBioReactorTableViewContainerApi } from "api/bioreactor-container-api";
import  { useParams } from 'react-router-dom'
const BioReactorTableViewContainer = () => {
    const  { site } = useParams()
    const {
        isLoading: bioreactorTrainIsLoading,
        isError: bioreactorTrainIsError,
        data: bioreactorTrainData,
    } = useQuery(
        ["getBioreactorTrainsTableViewContainer-Api", site],
        getBioReactorTableViewContainerApi.bind(this, {
            mfg_stage: "string",
            site: site as string,
            building: "string",
            line_no: "string",
            curr_stage: "string"

        })
    );
    return (
        <BioReactorTableView
            isLoading={bioreactorTrainIsLoading}
            isError={bioreactorTrainIsError}
            bioreactorTrainsData={bioreactorTrainData?.data?.data?? []}
        />
    );
};
export default BioReactorTableViewContainer;