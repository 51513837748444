import "./index.scss";

const EmptyTableDataComponent = () => {
    return (
        <div className="anc-d-flex anc-justify-content-center anc-align-items-center anc-empty-data-container ops-fs-4">
            No Data Available
        </div>
    )
}

export default EmptyTableDataComponent