import HorizontalLabels from "reusable/horizontal-labels";
import "../../assets/bootstrap.scss";
import "./index.scss";

interface AncTitleLabelHeaderInterface {
  height?: string;
  weight?: string;
  children:React.ReactNode;
}

const AncTitleLabelHeader: React.FC<AncTitleLabelHeaderInterface> = (
  props: AncTitleLabelHeaderInterface
) => {
  const {  height, weight } = props;

  return (
    <div
      className={
        (height ? `${height}` : `ops-fs-3 `) +
        "anc-table-title " +
        "ops-font-arial" +
        " anc-d-flex "
      }
    >
      {props.children}
    </div>
  );
};

export default AncTitleLabelHeader;
