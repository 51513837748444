import "./index.scss";

interface SeparatorBarProps {
  width?: string;
  height?: string;
  backgroundColor?: string;
  className?: string;
}
const SeparatorBar = ({
  width = "100%",
  height = "2px",
  backgroundColor = "#E3E3E3",
  className = "",
}: SeparatorBarProps) => {
  return (
    <div
      className={`anc-metric-separator ${className}`}
      style={{ width, height, backgroundColor }}
    />
  );
};
export default SeparatorBar;
