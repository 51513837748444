import "./index.scss";
import AncSubHeader from "reusable/anc-subtitle";
import ThawInoculationIncubator from "./thaw-inoculation-table-view";
import { ThawInoculationTableDataProps, ThawInoculationTableDataPropsObj } from "assets/types/thaw-inoc-table-types";
import EmptyTableDataComponent from "reusable/anc-empty-table-data-component";

export interface ThawInoculationTableViewContainerInterface {
  thawTableViewIsLoading: boolean;
  thawTableViewIsError: boolean;
  thawTableViewData: ThawInoculationTableDataPropsObj;
}

const ThawInoculationTableViewContainer = (
  props: ThawInoculationTableViewContainerInterface
) => {
  const { thawTableViewIsLoading, thawTableViewIsError, thawTableViewData } =
    props;
  return (
    <>
          {!thawTableViewIsLoading && !thawTableViewIsError && (thawTableViewData?.table_details && thawTableViewData?.table_details?.length > 0) ?(

      <div className="ops-fs-2 anc-thaw-inoc-container">
        
        {thawTableViewData?.table_details?.map(
          (item: ThawInoculationTableDataProps, index: number) => {
            return (
              <>
                <AncSubHeader
                  key={index}
                  label={`Lots - ${item?.incubator_name??''}`}
                  height={"ops-pt-0 "}
                  dot_color="#008533"
                  indexValue={index}
                />
                <ThawInoculationIncubator
                  isLoading={thawTableViewIsLoading}
                  isError={thawTableViewIsError}
                  incubatorData={item.table}
                  incubatorName={item.incubator_name}
                  alarmData={item.alarm_data}
                />
              </>
            );
          }
        )}

      </div>
       ):<EmptyTableDataComponent/>}
    </>
  );
};
export default ThawInoculationTableViewContainer;
