import { useQuery } from "react-query";
import { getProductionTabApi } from "api/production-tab-api";
import ProductionTab from ".";
import { useParams } from "react-router-dom";

const ProductionTabContainer = () => {
  const { site } = useParams();
  const {
    isLoading,
    isError,
    data: productionTabData,
  } = useQuery(
    ["getProductionTab-Api", site],
    getProductionTabApi.bind(this, {
      mfg_stage: "string",    
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
    })
  );

  return (
    <ProductionTab
      isLoading={isLoading}
      isError={isError}
      data={productionTabData?.data?.data}
    />
  );
};

export default ProductionTabContainer;
