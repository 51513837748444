import AncCircularBadge from "reusable/anc-circular-badge";
import "./index.scss";
import AncMetricCard from "reusable/anc-metric-card";
import AncMetricHeader from "reusable/anc-metric-header";
import AncMetricTitle from "reusable/anc-metric-title";
import TileWrapper from "reusable/anc-tile-wrapper";
import DividerBar from "reusable/divider-bar";
import ConnectorBar from "reusable/anc-connector";
import TitleLeft from "assets/mock-data/bioreactor-left-part-title";
import ErrorHandler from "utils/error-handler";
import BioreactorImages from "assets/mock-data/bioreactor-image";
import APIErrorMessage from "utils/api-error";

interface BioReactorProps {
  isLoading: boolean;
  isError: boolean;
  data: BioReactorDataType;
}
const BioReactor: React.FC<BioReactorProps> = ({
  isError,
  isLoading,
  data,
}) => {
  return (
    <div className="ops-mt-2 anc-w-100">
      <AncMetricHeader header={"Bioreactor Train"} serialNo={4} />
      <ErrorHandler
        isLoading={isLoading}
        isError={isError}
        loaderColor="#ffff"
      />

      <TileWrapper>
        <div className="anc-bio-reactor ops-px-4 ops-py-2   anc-d-flex ">
        {!isLoading && !isError && data?.values?.length > 0 ? (
          <>
          <div className="anc-bio-reactor-left anc-mr-3">
            <div className="anc-d-flex ">
              {TitleLeft.map((element: { title: string }) => {
                return (
                  <div
                    key={`${element.title}${parseInt(
                      (Math.random() * 500).toString()
                    ).toString()}`}
                    className=" anc-bioreactor-title-bar anc-pl-2 "
                  >
                    <AncMetricTitle
                      title={element.title}
                      className="  anc-pt-0 anc-pb-1"
                    ></AncMetricTitle>
                  </div>
                );
              })}
            </div>
            <div className="anc-d-flex">
              {data?.values
                ?.filter(
                  (value: BioReactorLeftDataType) =>
                    value.equipment !== "Production Bioreactor"
                )
                ?.map((element: BioReactorLeftDataType, index: number) => {
                  return (
                    <div
                      key={parseInt(
                        (Math.random() * 500).toString()
                      ).toString()}
                      className={`${index === 0 && "anc-bioreactor-first-row"}`}
                    >
                      <div className="  anc-train-row">
                        {element?.values
                          ?.sort(function (
                            a: MetricDataType,
                            b: MetricDataType
                          ) {
                            return a?.order_id - b?.order_id;
                          })
                          .map((el: MetricDataType, elindex: number) => {
                            return (
                              <div
                                key={parseInt(
                                  (Math.random() * 500).toString()
                                ).toString()}
                                className="anc-d-flex anc-train-component anc-mb-4"
                              >
                                <AncMetricCard
                                  className="anc-mr-2"
                                  machineStatus={el.unit_status}
                                  machineScheduleStatus={el.running_status}
                                  machinePhase={el.machine_status}
                                  machineTitle={el.batch_no}
                                  image={BioreactorImages[elindex]}
                                  statusColor={el.status_color_code}
                                  runningColor={el.running_status_code}
                                  tooltipIndex={parseInt(
                                    (Math.random() * 500).toString()
                                  ).toString()}
                                  activealarms={el.active_alarms ?? ""}
                                  materialdesc={el.material_desc}
                                  batchno={el.batch_no}
                                  machinestatus={el.machine_status}
                                  receipeduration={el.receipe_duration}
                                  recipe={el.receipe}
                                  running={el.running}
                                  unitstatus={el.running_status}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <DividerBar length={402} />
          <div className="anc-bio-reactor-right anc-pl-4">
            <AncMetricTitle
              title="Production Bioreactor"
              className="anc-pl-4 anc-pt-0 anc-pb-1"
            ></AncMetricTitle>
            <div className="anc-production-bioreactor">
              {data?.values
                ?.filter(
                  (value: BioReactorLeftDataType) =>
                    value.equipment === "Production Bioreactor"
                )[0]
                .values?.sort(function (a: MetricDataType, b: MetricDataType) {
                  return a?.order_id - b?.order_id;
                })
                .map((element: MetricDataType) => {
                  return (
                    <div
                      className="anc-d-flex anc-mb-1 "
                      key={parseInt(
                        (Math.random() * 500).toString()
                      ).toString()}
                    >
                      <AncMetricCard
                        machineStatus={element.unit_status}
                        machineScheduleStatus={element.running_status}
                        machinePhase={element.machine_status}
                        machineTitle={element.batch_no}
                        image={"production_bioreactor"}
                        statusColor={element.status_color_code}
                        runningColor={element.running_status_code}
                        activealarms={element.active_alarms ?? ""}
                        materialdesc={element.material_desc}
                        batchno={element.batch_no}
                        machinestatus={element.machine_status}
                        receipeduration={element.receipe_duration}
                        recipe={element.receipe}
                        running={element.running}
                        unitstatus={element.running_status}
                        tooltipIndex={parseInt(
                          (Math.random() * 500).toString()
                        ).toString()}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          </>)
          :<APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
        </div>
        
      </TileWrapper>
       
    </div>
  );
};
export default BioReactor;
