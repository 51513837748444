import { useQuery } from "react-query";
import BioReactorProcessView from ".";
import { getBioReactorProcessViewApi } from "api/bioreactor-tab-process-view-api";
import { getBioReactorPathApi } from "api/bioreactor-path-api";
import { useParams } from "react-router-dom";
import { useState } from "react";

const BioReactorProcessViewContainer = () => {
  const { site } = useParams();
  const [equipmentId, setEquipmentId] = useState("");

  const {
    isLoading,
    isError,
    data: BioReactorProcessViewData,
  } = useQuery(
    ["getBioReactorProcessView-Api", site],
    getBioReactorProcessViewApi.bind(this, {
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
    })
  );
  const {
    isLoading: pathLoading,
    isError: pathError,
    data: BioReactorPathData,
  } = useQuery(
    "getBioReactorPath-Api",
    getBioReactorPathApi.bind(this, {
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
      batch_no: equipmentId
    }
    )
  );
  return (
    <BioReactorProcessView
      isLoading={isLoading && pathLoading}
      isError={isError && pathError}
      data={BioReactorProcessViewData?.data?.data?.values}
      pathData={BioReactorPathData?.data?.data?.equipment_train_details}
      setEquipmentId={(equipmentId : string) => {
        setEquipmentId(equipmentId);
      }}
    />
  );
};
export default BioReactorProcessViewContainer;
