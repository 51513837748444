import React from "react";
import "./index.scss";
interface TableType {
  children: React.ReactNode
}
const AncTable: React.FC<TableType> = ({children}) => {
  return (
    <table className="anc-tab-table-container">
      {children}
    </table>
  );
};

export default AncTable;