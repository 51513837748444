import "./index.scss";
import TileWrapper from "reusable/anc-tile-wrapper";
import AncMetricTitle from "reusable/anc-metric-title";
import AncMachineDownText from "reusable/anc-machine-down-text";
import AncMachineId from "reusable/anc-machine-id";
import AncMachinePhase from "reusable/anc-machine-phase";
import AncMetricTag from "reusable/anc-metric-tag";
import { getMachineImage } from "utils/common-methods";
import AncComponentcard from "reusable/anc-component-card";
import { Button } from "@gitlab-rtsensing/component-library";
import AncMetricStatus from "reusable/anc-machine-status";
import AncMachineScheduleStatus from "reusable/anc-machine-schedule-status";
import React, { useEffect, useState } from "react";
import ErrorHandler from "utils/error-handler";
import AncMachineAlarmsCount from "reusable/anc-machine-alarms-count";
import { ArcherContainer, ArcherElement } from "react-archer";
import AncMachineAlarmsTooltip from "reusable/anc-alarm-tooltip-content";
import BioReactorModal from "../bio-reactor-modal";
import AncStartTime from "reusable/anc-card-start-time";
import APIErrorMessage from "utils/api-error";
import path from "path";
import { BioreactorTrainComponentDataType, BioreactorTrainDataType } from "types/bioreactor-trains-type";

interface BioReactorProcessViewProps {
  isLoading: boolean;
  isError: boolean;
  data: BioreactorTrainDataType[];
  pathData: any[];
  setEquipmentId: (equipmentId: string) => void;
}
const BioReactorProcessView: React.FC<BioReactorProcessViewProps> = ({
  isError,
  isLoading,
  data: BioReactorTranisTabData,
  pathData,
  setEquipmentId,
}) => {
  const isAlarms = true;
  const [currentPathData, setPathData] = useState<any[]>([]);
  const [equipmentArray, setEquipmentArray] = useState<
    BioreactorTrainDataType[]
  >([]);
  const [isPathModeActive, setIsPathModeActive] = useState<boolean>(false);
  const [currentPathEquipmentIdArray, setCurrentPathEquipmentIdArray] =
    useState<string[]>([]);
  const [viewMoreModal, setViewMoreModal] = useState<boolean>(true);
  const [viewMoreModalData, setViewMoreModalData] = useState<
    BioreactorTrainComponentDataType | undefined
  >(undefined);

  useEffect(() => {
    if (!isPathModeActive) {
      setEquipmentArray(BioReactorTranisTabData);
      setCurrentPathEquipmentIdArray([]);
    } else if (currentPathData?.length > 0) {
      const pathEquipmentIdArray = currentPathData?.map((equipment: any) => {
        return equipment.equipment_id;
      });
      setCurrentPathEquipmentIdArray(pathEquipmentIdArray);
      const equipmentArrayData = JSON.parse(
        JSON.stringify([...BioReactorTranisTabData])
      );
      currentPathData?.forEach((pathElement: any, pathIndex: number) => {
        if (pathIndex === currentPathData.length - 1) {
          return;
        }

        equipmentArrayData.forEach(
          (element: BioreactorTrainDataType, rowindex: number) => {
            const currentEquipmentIndex = element.values.findIndex(
              (equipment: BioreactorTrainComponentDataType) =>
                equipment.equipment_id === pathElement.equipment_id
            );
            if (currentEquipmentIndex !== -1) {
              equipmentArrayData[rowindex].values[
                currentEquipmentIndex
              ].relationId = currentPathData[pathIndex + 1].equipment_id;
            }
          }
        );
        setEquipmentArray(equipmentArrayData);
      });
    }
  }, [isPathModeActive]);

  useEffect(() => {
    if (BioReactorTranisTabData?.length > 0) {
      setEquipmentArray(BioReactorTranisTabData);
    }
  }, [BioReactorTranisTabData]);

  useEffect(() => {
    if (pathData?.length > 0) {
      setPathData(pathData);
    }
  }, [pathData]);

  const handlePathIconClick = (equipmentId: string) => {
    setIsPathModeActive(!isPathModeActive);
    setEquipmentId(equipmentId);
  };
  return (
    <>
      <ErrorHandler
        isLoading={isLoading}
        isError={isError}
        loaderColor="#ffff"
      />
      {!isLoading && !isError && equipmentArray?.length>0 ? (
        <ArcherContainer noCurves startMarker={false} endMarker={false}>
          <div className="anc-d-flex ">
            {equipmentArray.map(
              (element: BioreactorTrainDataType, rowindex: number) => {
                return (
                  <div
                    className={`anc-bioreactor-section ${
                      rowindex === 0 ? "anc-bioreactor-first-section" : ""
                    }`}
                    key={rowindex}
                  >
                    <TileWrapper>
                      <div key={rowindex} className="anc-wave-bioreactor">
                        <AncMetricTitle
                          title={element.equipment}
                          className="ops-my-2"
                          type="bioreactor"
                        ></AncMetricTitle>

                        {element.values.map(
                          (
                            el: BioreactorTrainComponentDataType,
                            index: number
                          ) => {
                            const tooltipId = parseInt(
                              (Math.random() * 500).toString()
                            ).toString();
                            return (
                              <ArcherElement
                                relations={[
                                  {
                                    targetId: el.relationId ?? "",
                                    targetAnchor: "left",
                                    sourceAnchor: "right",
                                    style: {
                                      strokeColor: "#0063c3",
                                      strokeWidth: 3,
                                      strokeDasharray: "5,5",
                                    },
                                  },
                                ]}
                                id={el?.equipment_id ?? null}
                                key={index}
                              >
                                <div
                                  key={index}
                                  className={`anc-bioreactor-train-element  ${
                                    isPathModeActive &&
                                    !currentPathEquipmentIdArray?.includes(
                                      el.equipment_id
                                    )
                                      ? "anc-opaque-card"
                                      : ""
                                  }`}
                                >
                                  <AncComponentcard
                                    machineStatus={el.status}
                                    statusColor={el.color_code}
                                    className={""}
                                    type={
                                      currentPathEquipmentIdArray?.includes(
                                        el.equipment_id
                                      ) &&
                                      currentPathEquipmentIdArray[0] !==
                                        el.equipment_id
                                        ? "bioreactor-path"
                                        : "bioreactor"
                                    }
                                  >
                                    <div className="anc-bioreactor-card">
                                      {currentPathEquipmentIdArray?.includes(
                                        el.equipment_id
                                      ) &&
                                      currentPathEquipmentIdArray[0] !==
                                        el.equipment_id ? (
                                        <div className="anc-d-flex anc-bioreactor-path-card">
                                          <div>
                                            <div className="anc-start-time-tag">
                                              <AncStartTime
                                                startTime={
                                                  currentPathData[
                                                    currentPathData.findIndex(
                                                      (path: any) =>
                                                        path.equipment_id ===
                                                        el.equipment_id
                                                    )
                                                  ].start_date
                                                }
                                              ></AncStartTime>
                                            </div>
                                            <AncMachineId
                                              machineId={el.equipment_id}
                                            ></AncMachineId>
                                          </div>
                                          {getMachineImage("wave_bioreactor")}
                                        </div>
                                      ) : (
                                        <>
                                          <div className="anc-d-flex anc-status-and-alarm">
                                            <div className="anc-d-flex">
                                              {el.status && (
                                                <AncMetricStatus
                                                  machineStatus={el.status}
                                                />
                                              )}

                                              {el.running_status && (
                                                <AncMachineScheduleStatus
                                                  machineScheduleStatus={
                                                    el.running_status
                                                  }
                                                  runningColor={
                                                    el.running_status_code
                                                  }
                                                />
                                              )}
                                            </div>
                                            {el.active_alarms !== undefined &&
                                              el.active_alarms >= 0 && (
                                                <div>
                                                  {el.status !== "Idle" && (
                                                    <AncMachineAlarmsCount
                                                      alarmCount={
                                                        el.active_alarms
                                                      }
                                                      tooltipId={`anc-alarm-tooltip-${tooltipId}`}
                                                      tooltip={
                                                        <AncMachineAlarmsTooltip
                                                          id={`anc-alarm-tooltip-${tooltipId}`}
                                                          alarms={
                                                            el?.alarm_data ?? []
                                                          }
                                                        />
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                          <div
                                            className={`
                                    anc-d-flex  
                                    anc-justify-content-between 
                                    anc-align-items-center 
                                    `}
                                          >
                                            <div className="anc-pt-1 ">
                                              <div className="anc-d-flex ops-mb-2">
                                                {el.equipment_id && (
                                                  <AncMachineId
                                                    machineId={el.equipment_id}
                                                  />
                                                )}
                                                {isAlarms && (
                                                  <AncMachineDownText />
                                                )}
                                              </div>

                                              {el.machine_status && (
                                                <AncMachinePhase
                                                  machinePhase={
                                                    el.machine_status
                                                  }
                                                  className="ops-mb-1"
                                                ></AncMachinePhase>
                                              )}

                                              {el.lot?.name && (
                                                <>
                                                  <div
                                                    id={`anc-float-data-anchor${index}`}
                                                    onClick={() => {
                                                      handlePathIconClick(
                                                        el.equipment_id
                                                      );
                                                    }}
                                                  >
                                                    <AncMetricTag
                                                      machineTitle={
                                                        el.lot?.name
                                                      }
                                                      type={"bioreactor"}
                                                      iconActive={
                                                        isPathModeActive &&
                                                        el.equipment_id ===
                                                          currentPathEquipmentIdArray[0]
                                                      }
                                                    />
                                                  </div>
                                                </>
                                              )}
                                            </div>

                                            {getMachineImage("centrifuge")}
                                          </div>

                                          <div
                                            className="ops-fs-5"
                                            onClick={() => {
                                              setViewMoreModalData(el);
                                              setViewMoreModal(true);
                                            }}
                                          >
                                            <Button
                                              label="View More"
                                              type="secondary"
                                              className="anc-bioreactor-view-more-button"
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </AncComponentcard>
                                </div>
                              </ArcherElement>
                            );
                          }
                        )}
                      </div>
                    </TileWrapper>
                  </div>
                );
              }
            )}
          </div>
          {viewMoreModalData ? (
            <div className="anc-view-more-modal-container">
              <BioReactorModal
                title={"Wave Bioreactor"}
                opened={viewMoreModal}
                close={() => {
                  setViewMoreModal(false);
                }}
                alarms={viewMoreModalData?.alarm_data ?? []}
                status={viewMoreModalData.status}
                equipmentid={viewMoreModalData.equipment_id}
                material={viewMoreModalData.lot?.name ?? ""}
                lot={viewMoreModalData.lot?.name ?? ""}
                stepprogress={viewMoreModalData.lot?.unit_op_progress ?? ""}
                processStep={viewMoreModalData.lot?.step_process_progress ?? ""}
                schedule={viewMoreModalData.lot?.schedule_adherences ?? ""}
                scheduleColorCode={viewMoreModalData.running_status_code ?? ""}
              />
            </div>
          ) : null}
        </ArcherContainer>
      ) :!isLoading &&
      !isError &&
      (!equipmentArray || equipmentArray?.length === 0) ? (
        <APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>
      ):<></>}
    </>
  );
};
export default BioReactorProcessView;
