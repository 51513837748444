import ThawInoculationTimeAlarmContainer from "./thaw-inoculation-time-alarm-container";
import "./index.scss";
import ThawInoculationContainer from "./thaw-inoculation-container";
import { ProcessViewDataInterface } from "assets/types/thaw-inoculation-process-view.types";
import { thawInoculationHeaderInterface } from "assets/types/thaw-inoc-header-types";
import { ThawInoculationTableDataPropsObj } from "assets/types/thaw-inoc-table-types";
interface ThawInoculationTabInterface {
  thawProcessViewIsLoading: boolean;
  thawProcessViewIsError: boolean;
  thawProcessViewData: ProcessViewDataInterface;
  thawTableViewIsLoading: boolean;
  thawTableViewIsError: boolean;
  thawTableViewData: ThawInoculationTableDataPropsObj;
  thawInocHeaderIsLoading: boolean;
  thawInocHeaderIsError: boolean;
  thawInocHeaderData: thawInoculationHeaderInterface;
}

const ThawInoculationTab = (props: ThawInoculationTabInterface) => {

  return (
    <>
      <div className="ops-my-20 thaw-inoculation-container">
        <ThawInoculationTimeAlarmContainer
          thawHeaderData={props.thawInocHeaderData}
          thawInocHeaderIsLoading={props.thawInocHeaderIsLoading}
          thawInocHeaderIsError={props.thawInocHeaderIsError}
        />
        <ThawInoculationContainer
          thawProcessViewIsLoading={props.thawProcessViewIsLoading}
          thawProcessViewIsError={props.thawProcessViewIsError}
          thawProcessViewData={props.thawProcessViewData}
          thawTableViewIsLoading={props.thawTableViewIsLoading}
          thawTableViewIsError={props.thawTableViewIsError}
          thawTableViewData={props.thawTableViewData}
        />
      </div>
    </>
  );
};
export default ThawInoculationTab;
