import { useQuery } from "react-query"
import UpcomingLotsSection from "."
import { getUpcomingLotsSectionApi } from "api/upcoming-lot-main-api"
import { useParams } from "react-router-dom";

const UpcomingLotsSectionContainer = () => {
    const { site } = useParams()
    const {isLoading, isError, data: UpcomingLotsSectionData} = useQuery(['getUpcomingLotsSection-Api', site],getUpcomingLotsSectionApi.bind(this,{
        mfg_stage: "string",
        site: site as string,
        building: "string",
        line_no: "string",
        curr_stage: "string"
    }))
    return (
        <UpcomingLotsSection 
            isloading={isLoading} 
            isError={isError} 
            data={UpcomingLotsSectionData?.data?.data} 
        />
    )
}
export default UpcomingLotsSectionContainer;
