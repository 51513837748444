import "./index.scss";
import { checkingTab } from "utils/common-methods";
import UpcomingScheduleModal from "./upcoming-schedule-modal";
import { useEffect, useState } from "react";
interface UpcomingLotsContainerType {
 data :any
 isloading: boolean;
 isError:boolean;
}

const UpcomingLotsSection: React.FC<UpcomingLotsContainerType> = ({data}) => {
  
  const [UpcomingScheduleModalState, setUpcomingScheduleModalState] = useState(false)

  useEffect(() => {
    return () => {
      setUpcomingScheduleModalState(false)
    }
  },[])
return (
  <>
         <>
          <div className="anc-d-flex anc-refresh-lot-container anc-align-items-center">
            <div className="anc-lot-container anc-d-flex anc-align-items-center anc-justify-content-between">
              {checkingTab(data,  (state: boolean) => { 
                setUpcomingScheduleModalState(state)
               })}
            </div>
          </div>
          <div className="anc-upcoming-schedule-modal-container">
                <UpcomingScheduleModal
                    title={`Upcoming Schedule - 3 Months`}
                    opened={UpcomingScheduleModalState}
                    close={() => {
                        setUpcomingScheduleModalState(false);
                    }}
                />
            </div>
        </>
  </>
);
};
export default UpcomingLotsSection;
