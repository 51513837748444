import CircleIcon from "assets/icons/circle-icon";
import "./index.scss";

interface SubTitleProps {
  label: string;
  height?: string;
  weight?: string;
  dot_color: string;
  indexValue?: number;
}

const AncSubHeader = (props: SubTitleProps) => {
  const { label, height, weight, indexValue } = props;

  return (
    <div className="d-flex flex-row align-items-baseline anc-subtitle">
      <div
        className={
          (height ? `${height}` : `ops-pt-4 `) +
          (indexValue !== 0 ? "anc-incubator-header " : "") +
          "ops-fw-semibold ops-fs-4 anc-thaw-header-label"
        }
      >
        {label ?? ""}
      </div>
      <CircleIcon fill={props.dot_color} />
    </div>
  );
};

export default AncSubHeader;
