import "./index.scss";
import "../../assets/bootstrap.scss";

interface AncTabButtonInterface {
  element: any;
  indexValue: number;
  tabLength: number;
  isActiveTab: boolean;
}

const AncTabButton = (props: AncTabButtonInterface) => {
  return (
    <div
      className={
        (props.indexValue === 0
          ? "anc-first-tab "
          : props.indexValue + 1 === props.tabLength
          ? "anc-last-tab "
          : "") +
        (props.isActiveTab ? " anc-active-tab-button " : " ") +
        " ops-fs-6 ops-fw-bold anc-button-tab anc-text-uppercase"
      }
    >
      <div
        className={
          (props.isActiveTab ? "anc-active-text " : "") + " anc-button-tab-text"
        }
      >
        {props.element.tabLabel}
      </div>
    </div>
  );
};

export default AncTabButton;
