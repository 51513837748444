import AncTabButton from "reusable/anc-tab-button";

interface handleActiveTabInterface {
  activeTab: number;
  handleActiveTab: (value: number) => void;
  tabData:any;
}

const AncSwitchTab = (props: handleActiveTabInterface) => {
  const { activeTab, tabData, handleActiveTab } = props;

  const handleAncButton = (value: any) => {
    handleActiveTab(value);
  };

  return (
    <div className="anc-switch-tab anc-d-flex">
      {props.tabData.map((element: any, index:number) => {
        return (
          <div onClick={() => handleAncButton(index)} key={index}>
            <AncTabButton
              element={element}
              indexValue={index}
              isActiveTab={activeTab === index}
              tabLength={props.tabData.length}
            />
          </div>
        );
      })}
    </div>
  );
};
export default AncSwitchTab;
