import React from "react";
import WeighDispenseContainer from "./weigh-dispense/container.anc";
import SolutionPrepContainer from "./solution-prep/contianer.anc";
import ThawInoculationContainer from "./thaw-inoculation/container.anc";
import "./index.scss";

const LotsTilesContainer: React.FC = () => {
  return (
    <div className="anc-lots-tiles-container anc-d-flex">
       <WeighDispenseContainer />
       <SolutionPrepContainer/>
       <ThawInoculationContainer/>
    </div>
  );
};
export default LotsTilesContainer;
