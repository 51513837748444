import { payloadType } from "types/api.types";
import { fetchApi } from "./fetch-api";

export const getThawInocTableViewApi = async (data: payloadType) => {
    const response = await fetchApi({
        header: {},
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/thaw_inoculation_table_view`,
        data
    })
    return response;

}