import "./index.scss";
interface TableDescriptionComponentProps {
  children: React.ReactNode;
  align:string;
  statusColor?: string | number | undefined;
}

const TableDescriptionComponent: React.FC<TableDescriptionComponentProps> = ({
  children,align,statusColor
}) => {

  return <td className={
    (align === "right"
      ? "anc-tab-table-header-data-right "
      : align === "center"
      ? "anc-tab-table-header-data-center "
      : "anc-tab-table-header-data-left ") + 
      (statusColor ? `bioreactor-${statusColor}` : "") +
      // (statusColor ? `first-child anc-${statusColor?.toLowerCase()}` : "") +
      " ops-fs-5" 
  }>{children}</td>;
};
export default TableDescriptionComponent;
