import AncWeighAndDispenseProgressContainer from "./weigh-dispense-progress/container.anc";
import AncWeighAndDispenseUpcomingNextContainer from "./weigh-dispense-upcoming/container.anc";
import AncWeighAndDispenseUpcomingLastContainer from "./weigh-dispense-last-24-hs/container.anc";
import AncTitleSegmentHeader from "reusable/anc-title-segment-header";
import "./index.scss";

const WeighAndDispensePage = () => {
  return (
    <>
      <div className="ops-fs-2 anc-weigh-and-dispense-container">
        <div className="d-flex align-items-center anc-top-progress-container ops-font-arial">
          <AncTitleSegmentHeader title={"In Progress"} />
        </div>
        <AncWeighAndDispenseProgressContainer />
        <div className="d-flex align-items-center anc-next-progress-container ops-font-arial">
          <AncTitleSegmentHeader title={"Upcoming Next 48 Hours"} />
        </div>
        <AncWeighAndDispenseUpcomingNextContainer/>
        <div className="d-flex align-items-center anc-last-progress-container ops-font-arial">
          <AncTitleSegmentHeader title={"Completed in Last 48 Hours"} />
        </div>
        <AncWeighAndDispenseUpcomingLastContainer/>
      </div>
    </>
  );
};
export default WeighAndDispensePage;