import "./index.scss";
import HeaderValueTextContent from "../header-value-text-content";
import CleanProgressBar from "reusable/clean-progress-bar";
import TimerHeaderValueContent from "../timer-header-value-content";
import { Tooltip } from "react-tooltip";
import { getMachineImage } from "utils/common-methods";
import AncSubHeader from "reusable/anc-subtitle";
import MetricCardBlock from "reusable/metric-card-block";
import AncAlarmWithToolTip from "reusable/anc-alarm-with-tooltip";
import SeparatorBar from "reusable/anc-separator-bar";
import HeaderValueContainer from "../header-value-container";
import AncLegendsGrid from "reusable/anc-legends-grid";
import HorizontalLabels from "reusable/horizontal-labels";
import DividerBar from "reusable/divider-bar";
import { BioreactorTrainsTableDataObjProps } from "assets/types/bioreactor-trains-table-types";
import { AlarmType } from "types/bioreactor-trains-type";

interface ViewMoreContentProps {
  alarms: AlarmType[];
  status: string;
  equipmentid: string;
  material: string;
  lot: string;
  stepprogress: string;
  processstep: string;
  schedule: string;
  schedulecolorcode: string;
}

const ViewMoreContent = (props: ViewMoreContentProps) => {
  const {
    alarms,
    status,
    equipmentid,
  material,
  lot,
  stepprogress,
  processstep,
  schedule,
  schedulecolorcode,
  } = props;

  const getTooltipBody = () => {
    return (
      <div>
        <div className="ops-fs-5 ops-fw-semibold ops-mb-1">Current Alarms</div>
        <div>
          <table className="alarm-table">
            <tr>
              <th className="ops-fs-6 ops-fw-semibold">ALARM</th>
              <th className="ops-fs-6 ops-fw-semibold">DURATION</th>
              <th className="ops-fs-6 ops-fw-semibold">TYPE</th>
            </tr>
            {([] as AlarmType[]).map((alarm) => {
              return (
                <tr key={alarm.name}>
                  <td className="ops-fs-6">{alarm.name}</td>
                  <td className="ops-fs-6">{alarm.duration}</td>
                  <td className="ops-fs-6">{alarm.type}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    );
  };

  const getTooltip = (id: string) => {
    return (
      <Tooltip
        noArrow={true}
        float
        className={"anc-thaw-inoculation-alarm-tooltip"}
        opacity={1}
        render={() => {
          return (
            <div className="anc-thaw-inoculation-alarm-tooltip-body">
              {getTooltipBody()}
            </div>
          );
        }}
        style={{
          backgroundColor: "white",
          padding: "0px",
          zIndex: 1000,
        }}
        anchorSelect={`#${id}`}
      ></Tooltip>
    );
  };

  return (
    <div className="anc-view-more-content">
      <AncSubHeader
        label={"Equipment Status"}
        height={"ops-pt-0 "}
        dot_color="#FFFFFF"
      />
      <div className="anc-d-flex anc-image-lot-container">
        <div className="anc-d-flex anc-material-lot-wrapper">
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"EQUIPMENT ID"}
                dataValue={lot ?? "N/A"}
                dataColor={""}
              />
            </div>
            <div className="anc-d-flex anc-header-value-content">
              <HeaderValueContainer headerValue={"STATUS"}>
                <div className="anc-d-flex">
                  <HorizontalLabels
                    item={{
                      label: "advisory",
                      labelColor: "#D07804",
                      labelImageType: "circle",
                    }}
                  />
                  <DividerBar length={15}/>
                  <div className="anc-pl-2"> 
                  <HorizontalLabels
                    item={{
                      label: "CRITICAL",
                      labelColor: "#D62728",
                      labelImageType: "circle",
                    }}
                  />
                  </div>
                </div>
              </HeaderValueContainer>
            </div>
          </div>
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"CLEANING STATUS CIP"}
                dataValue="Lorem"
                dataColor={""}
              />
            </div>
            <div className="anc-d-flex anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"DIRTY HOLD TIMER"}
                dataValue="Lorem"
                dataColor={ "N/A"}
              />
            </div>
          </div>
        </div>
        <div className="image-wrapper">
          {getMachineImage("wave_bioreactor_alt")}
        </div>
      </div>
      <SeparatorBar height="1px" className="anc-mt-2 anc-mb-3" />
      <div className="anc-d-flex anc-align-items-center anc-upcoming-lot-header ">
        <AncSubHeader
          label={"Current Lot Status"}
          height={"ops-pt-0 "}
          dot_color="#FFFFFF"
        />
        <div className="anc-lot ops-fw-bold anc-lot-grey anc-pb-2 ops-fs-5">
          {"AMG135-00532336"}
        </div>
      </div>
      <div className="anc-d-flex anc-image-lot-container">
        <div className="anc-d-flex anc-material-lot-wrapper">
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"MATERIAL DESCRIPTION"}
                dataValue={material?? 'N/A'}
                dataColor={""}
              />
            </div>
          </div>
          <div className="ops-mb-4 anc-d-flex anc-justify-content-between">
            <div className="anc-w-50">
              <div className="ops-mb-2">
                <MetricCardBlock
                  headerValue={"Unit op progress"}
                  dataValue={"Bioreactor Train"}
                  dataColor={""}
                />
              </div>
              <div className="anc-d-flex">
                <CleanProgressBar
                  percentageArray={[{ percentage: 20, flag: "BLUE" }]}
                />
              </div>
            </div>

            <div className="anc-w-50">
              <div className="ops-mb-2">
                <MetricCardBlock
                  headerValue={"Step process progress"}
                  dataValue={processstep??0
                  }
                  dataColor={""}
                />
              </div>
              <div className="anc-d-flex">
                <CleanProgressBar
                  percentageArray={[{ percentage: stepprogress??0, flag: "BLUE" }]}
                />
              </div>
            </div>
          </div>
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"MINIMUM CULTURE TIME"}
                dataValue={ "XX.X hr"}
                dataColor={ "XX.X hr"}
              />
            </div>
            <div className="anc-d-flex anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"MAXIMUM CULTURE TIME"}
                dataValue={ "XX.X hr"}
                dataColor={ "XX.X hr"}
              />
            </div>
          </div>
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"NEXT EQUIPMENT"}
                dataValue={"N-3/N-2 SUB Bioreactor (ABC12345)" ?? "N/A"}
                dataColor={""}
              />
            </div>
            <div className="anc-d-flex anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"SCHEDULE ADHERENCE"}
                dataValue={schedule ?? "N/A"}
                dataColor={schedulecolorcode?? "N/A"}
              />
            </div>
          </div>
          <div className="anc-d-flex anc-header-value-container">
            <div className="anc-d-flex anc-w-50 anc-header-value-content">
              <HeaderValueTextContent
                headerValue={"RECEPIE"}
                dataValue={"Lorem ipsum dolor"}
                dataColor={""}
              />
            </div>
          </div>
        </div>
      </div>

      <SeparatorBar height="1px" className="anc-mt-2 anc-mb-3" />
      <div className="anc-d-flex anc-upcoming-lot-header ops-fs-5">
        <AncSubHeader
          label={"ALRAMS"}
          height={"ops-pt-0 "}
          dot_color="#FFFFFF"
        />
        {alarms.length > 0 && (
          <AncAlarmWithToolTip
            alarm={alarms.length}
            colorCode="red"
            tooltipId="anc-thaw-inoculation-alarm-container"
            tooltip={getTooltip("anc-thaw-inoculation-alarm-container")}
          />
        )}
      </div>
      <div className="anc-d-flex anc-timer-container anc-pt-0">
        <div>
          <TimerHeaderValueContent
            headerValue={"Current alarms"}
            multipleDataValues={[
              "Lorem ipsum dolor",
              "Sed ut perspiciatis unde",
              "Lorem ipsum dolor",
            ]}
          />
        </div>
        <div>
          <TimerHeaderValueContent
            headerValue={"Alarm type"}
            multipleDataValues={[
              "Safety",
              "pressure",
              "Quality",
            ]}
          />
        </div>
        <div>
          <TimerHeaderValueContent
            headerValue={"Alarm duration"}
            multipleDataValues={[
              "2 hrs",
              "1.5 hrs",
              ".5 hrs",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewMoreContent;
