import "./index.scss";

interface TableBodyProps {
  children: React.ReactNode;
}
const TableLotBody: React.FC<TableBodyProps> = ({
  children,
}) => {
  return <tbody className="anc-table-lot-body">{children}</tbody>;
};
export default TableLotBody;
