interface Props {
    classname?: string;
    fill:string;
  }
  
  const VectorDownIcon = ({ fill, classname }: Props) => {
    return (
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="10" 
        height="6" 
        viewBox="0 0 10 6" 
        fill="none">
          <path 
            d="M9.16664 0.641741C9.01051 0.486532 8.7993 0.399414 8.57914 0.399414C8.35899 0.399414 8.14778 0.486532 7.99164 0.641741L4.99998 3.59174L2.04998 0.641741C1.89384 0.486532 1.68263 0.399414 1.46248 0.399414C1.24232 0.399414 1.03111 0.486532 0.874978 0.641741C0.796871 0.71921 0.734875 0.811378 0.692568 0.912927C0.650261 1.01448 0.628479 1.1234 0.628479 1.23341C0.628479 1.34342 0.650261 1.45234 0.692568 1.55389C0.734875 1.65544 0.796871 1.74761 0.874978 1.82507L4.40831 5.35841C4.48578 5.43651 4.57795 5.49851 4.6795 5.54082C4.78105 5.58312 4.88997 5.60491 4.99998 5.60491C5.10999 5.60491 5.21891 5.58312 5.32046 5.54082C5.42201 5.49851 5.51417 5.43651 5.59164 5.35841L9.16664 1.82507C9.24475 1.74761 9.30675 1.65544 9.34905 1.55389C9.39136 1.45234 9.41314 1.34342 9.41314 1.23341C9.41314 1.1234 9.39136 1.01448 9.34905 0.912927C9.30675 0.811378 9.24475 0.71921 9.16664 0.641741Z" 
            fill={fill} 
          />
      </svg>
    );
  };
  
  export default VectorDownIcon;