import "./index.scss";
import ErrorHandler from "utils/error-handler";
import { ProductionTableDataType } from "types/schedule-modal.types";
interface ProductionTabInterface {
  isLoading: boolean;
  isError: boolean;
  data: ProductionTableDataType[];
}
const ProductionTab: React.FC<ProductionTabInterface> = (
  props: ProductionTabInterface
) => {
  const { isLoading, isError, data: prodTabData } = props;
  return (
    <>
      <ErrorHandler
        isLoading={isLoading}
        isError={isError}
        loaderColor="#ffff"
      />
      {!isLoading && !isError && (
        <div className="anc-prod-tab">
          <table className="anc-prod-table-modal anc-pb-4">
            <thead className="anc-prod-table-header-container">
              <tr>
                <th className="anc-prod-table-header-1 anc-text-uppercase ops-fs-6 ops-fw-semibold">
                  Target Thaw Date
                </th>
                <th className="anc-prod-table-header-2 anc-text-uppercase ops-fs-6 ops-fw-semibold">
                  Lot Number
                </th>
                <th className="anc-prod-table-header-3 anc-text-uppercase ops-fs-6 ops-fw-semibold">
                  Material Description
                </th>
              </tr>
            </thead>
            <tbody className="anc-prod-table-body-container">
              {prodTabData?.map(
                (row: ProductionTableDataType, index: number) => (
                  <tr key={index}>
                    <td className="anc-prod-table-data-1 anc-text-uppercase ops-fs-5 ">
                      {row?.target_date ?? "N/A"}
                    </td>
                    <td className="anc-prod-table-data-2 anc-text-uppercase ops-fs-5 ">
                      {row?.lot ?? "N/A"}
                    </td>
                    <td className="anc-prod-table-data-3 anc-text-uppercase ops-fs-5 anc-pr-3">
                      {row?.desc ?? "N/A"}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ProductionTab;
