import { LotHeaderType, MaxHeaderType, MinHeaderType, NameHeaderType } from "constants/thaw-inoc-constants/thaw-inoc-process-view";
import "./index.scss";
import AncMetricTag from "reusable/anc-metric-tag";

interface AncLotsCardBodyDataContentProps {
  type: string;
  colorCode: string;
  lotsName: string;
  cultureTimeMax: string;
  cultureTimeMin: string;
  runningStatus: string;
}

const AncLotsCardBodyDataContent: React.FC<AncLotsCardBodyDataContentProps> = (
  props: AncLotsCardBodyDataContentProps
) => {
  const {
    type,
    colorCode,
    lotsName,
    cultureTimeMax,
    cultureTimeMin,
    runningStatus,
  } = props;

  return (
    <>
      {type === NameHeaderType && (
        <div
          className={
            (colorCode === "red"
              ? "anc-card-schedule-red"
              : "anc-card-schedule") +
            " anc-d-flex ops-fs-6 ops-fw-bold anc-text-uppercase"
          }
        >
          {runningStatus}
        </div>
      )}
      {type === MaxHeaderType && (
        <div className="anc-d-flex anc-min-content-wrapper">
          <div className="ops-fs-5 ops-fw-bold anc-content-desc-1">
            {cultureTimeMax.split(" ")[0]}
          </div>
          <div className="ops-fs-5 anc-content-desc-2">
            {cultureTimeMax.split(" ")[1]}
          </div>
        </div>
      )}
      {type === MinHeaderType && (
        <div className="anc-d-flex anc-min-content-wrapper">
          <div className="ops-fs-5 ops-fw-bold anc-content-desc-1">
            {cultureTimeMin.split(" ")[0]}
          </div>
          <div className="ops-fs-5 anc-content-desc-2">
            {cultureTimeMin.split(" ")[1]}
          </div>
        </div>
      )}

      {type === LotHeaderType && (
        <div className="anc-lots-metric-tag">
          {<AncMetricTag machineTitle={lotsName} />}
        </div>
      )}
    </>
  );
};
export default AncLotsCardBodyDataContent;
