import "./index.scss";
import AncTitleLabelHeader from "reusable/anc-title-label-header";
import { useState } from "react";
import AncTitleLegendContainer from "reusable/anc-title-legend-container";
import AncSwitchTab from "reusable/anc-switch-tab";
import AncTitleHeaderText from "reusable/anc-title-header-text";
import AncLegendsGrid from "reusable/anc-legends-grid";
import labelData from "assets/mock-data/thaw-inoc-tab-layout-data";
import ThawInoculationTableViewContainer from "../thaw-inoculation-table-view-container";
import {
  ThawProcessViewTitle,
  processTabArray,
} from "constants/thaw-inoc-constants/thaw-inoc-process-view";
import { ProcessViewDataInterface } from "assets/types/thaw-inoculation-process-view.types";
import ThawInoculationProcessView from "../thaw-inoculation-process-view";
import { ThawInoculationTableDataPropsObj } from "assets/types/thaw-inoc-table-types";
import ThawInoculationTooltipContent from "assets/mock-data/thaw-inoc-legend-tooltip-content";
interface ThawInoculationContainerInterface {
  thawProcessViewIsLoading: boolean;
  thawProcessViewIsError: boolean;
  thawProcessViewData: ProcessViewDataInterface;
  thawTableViewIsLoading: boolean;
  thawTableViewIsError: boolean;
  thawTableViewData: ThawInoculationTableDataPropsObj;
}

const ThawInoculationContainer = (props: ThawInoculationContainerInterface) => {
  const [activeTab, setactiveTab] = useState(0);
  const handleActiveTab = (tabIndex: number) => {
    setactiveTab(tabIndex);
  };
  return (
    <div className="anc-thaw-table-process-container">
      <AncTitleLegendContainer>
        <AncTitleLabelHeader>
          <AncTitleHeaderText title={ThawProcessViewTitle} />
          <AncLegendsGrid labelData={labelData} labelContent={ThawInoculationTooltipContent}/>
        </AncTitleLabelHeader>
        <AncSwitchTab
          handleActiveTab={handleActiveTab}
          activeTab={activeTab}
          tabData={processTabArray}
        />
      </AncTitleLegendContainer>
      {activeTab === 0 && (
        <div className="anc-d-flex anc-thaw-process-content">
          <ThawInoculationProcessView
            isLoading={props.thawProcessViewIsLoading}
            isError={props.thawProcessViewIsError}
            processViewData={props.thawProcessViewData}
          />
        </div>
      )}
      {activeTab === 1 && (
        <div className="anc-d-flex anc-thaw-table-content">
          <ThawInoculationTableViewContainer
            thawTableViewIsLoading={props.thawTableViewIsLoading}
            thawTableViewIsError={props.thawTableViewIsError}
            thawTableViewData={props.thawTableViewData}
          />
        </div>
      )}
    </div>
  );
};
export default ThawInoculationContainer;
