import React from "react";
import "./index.scss";

interface ShowButtonProps {
  showData: boolean;
  remainingLots: string;
  totalLength: number;
  handleExtendedData: (value: boolean) => void;
}

const ShowButtonContent: React.FC<ShowButtonProps> = (
  props: ShowButtonProps
) => {
  const handleData = (isShow: boolean) => {
    props.handleExtendedData(isShow);
  };
  return (
    <div className="anc-show-button-container anc-d-flex">
      <div className="anc-d-flex ops-fs-6 anc-pr-1 ops-fw-bold anc-text-uppercase anc-schedule"></div>
      <div
        onClick={() => {
          handleData(!props.showData);
        }}
        className="anc-d-flex anc-mr-1 anc-text-uppercase anc-batch ops-fs-6 ops-fw-bold"
      >
        {props.totalLength > 3 &&
          (props.showData
            ? "-" + " Show Less"
            : "+" + props.remainingLots + " LOTS")}
      </div>
      <div className="anc-d-flex ops-fs-5 anc-desc">{""}</div>
    </div>
  );
};
export default ShowButtonContent;
