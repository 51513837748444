import { MetricCard } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import AncMetricTag from "reusable/anc-metric-tag";
import { getMachineImage } from "utils/common-methods";
import { Tooltip } from "react-tooltip";

interface AncComponentcardProps {
  machineStatus?: string;
  className?: string;
  statusColor?: string;
  children: JSX.Element;
  type ? : string
}
const AncComponentcard: React.FC<AncComponentcardProps> = ({
  machineStatus,
  className,
  type,
  statusColor,
  children,
}) => {
  const isIdle = machineStatus === "Idle";

  return (
    <div className={`${className} anc-metric-card`}>
      <MetricCard
        className={`anc-component-card anc-${statusColor?.toLowerCase()} ${
          isIdle ? "anc-idle-card" : ""
        } anc-px-2 anc-pb-2 anc-pt-2 ${type==="bioreactor-path" ? "anc-path-card" : ""} `}
      >
        {children}
      </MetricCard>
    </div>
  );
};
export default AncComponentcard;
