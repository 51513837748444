import React, { ErrorInfo } from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import AncSite from "./anc-site";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: AncSite,
  errorBoundary (_err: Error, _info: ErrorInfo, _props: any) {
    return <div>Build Failed while compiling</div>;
  },
});

let styleElements: Array<Element>;

export const bootstrap = reactLifecycles.bootstrap;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mount = [
  // Add CSS styles to the page before React app is loaded
  async () => {
    if (styleElements) {
      document.head.append(...styleElements);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any) => {
    const permission = props?.permissions.filter((item: any) => {
      if (item.url === "anc" || item.url === 'ari') {
        return true;
      }
      return false;
    });
    if (permission.length === 0) {

      props.singleSpa.navigateToUrl("/unauthorized");

    } else if (window.location.pathname.startsWith('/anc')) {

      if (!permission[0].isAuthorized)  props.singleSpa.navigateToUrl("/unauthorized");
    } if (window.location.pathname.startsWith('/ari')) {

      if (!permission[1].isAuthorized)  props.singleSpa.navigateToUrl("/unauthorized");

    }
    return reactLifecycles.mount(props);
  },
  // Keep references to added style tags for the app so they can be remounted again
  async () => {
    if (!styleElements) {
      styleElements = Array.from(
        document.getElementsByClassName("amgen-dct-anc")
      );
    }
  },
];
export const unmount = [
  reactLifecycles.unmount,
  // Remove styles once the app has unmounted
  async () => {
    styleElements.forEach((element) => element.remove());
  },
];
