import { useQuery } from "react-query"
import Harvest from "."
import { getHarvestApi } from "api/harvest-api"
import { useParams } from "react-router-dom";

const HarvestContainer = () => {
const { site } = useParams();

    const {isLoading, isError, data: HarvestData} = useQuery(['getHarvest-Api', site],getHarvestApi.bind(this,{
        mfg_stage: "string",
        site: site as string,
        building: "string",
        line_no: "string",
        curr_stage: "string"
    }))
    return (
        <Harvest 
            isLoading={isLoading} 
            isError={isError} 
            data={HarvestData?.data?.data?.values || []} 
        />
    )
}
export default HarvestContainer;
