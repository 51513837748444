import "./index.scss";

interface DividerBarProps {
    length : number 
}
const DividerBar = ({length} : DividerBarProps) => {
    return (
        <div className="anc-metric-divider anc-my-2" style={{height: length}}>

        </div>
    )
}
 export default DividerBar;