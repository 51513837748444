import React, { useState } from "react";
import AncMetricHeader from "reusable/anc-metric-header";
import AncTabCardComponent from "reusable/anc-tab-component";
import LotsCardsContent from "reusable/anc-lots-card-content";
import ShowButtonContent from "reusable/anc-show-button-component";
import DctDsBag from "assets/images/dct-ds-bag";
import ErrorHandler from "utils/error-handler";
import "./index.scss";
import { getMachineImage } from "utils/common-methods";
import APIErrorMessage from "utils/api-error";
interface SolutionPrepInterface {
  isLoading: boolean;
  isError: boolean;
  data: SolutionPrepDataType;
}
const SolutionPrep: React.FC<SolutionPrepInterface> = ({
  isLoading,
  isError,
  data: solutionPrepData,
}) => {
  const [showData, setShowData] = useState(false);
  const handleExtendedData = (isShow: boolean) => {
    setShowData(!showData);
  };

  const remainingLots = (solutionPrepData?.stage_data?.length - 3).toString();
  return (
    <div className="anc-solution-container anc-w-100">
      <AncMetricHeader
        header={"Solution Prep"}
        serialNo={2}
        ancHeader={solutionPrepData?.stage_data?.length.toString()}
      />
      <ErrorHandler
        isLoading={isLoading}
        isError={isError}
        loaderColor="#ffff"
      />
      {!isLoading && !isError && (
        <AncTabCardComponent className="anc-solution" header={"Batch Schedule"}>
          {/* FIXME: remove any from below line */}
          {solutionPrepData && 
            <div className={"anc-d-flex anc-solution-card-content"}>
              <div className={"anc-solution-card-table"}>
                {!showData &&
                  solutionPrepData?.stage_data
                    ?.sort(
                      (
                        a: SolutionPrepElementDataType,
                        b: SolutionPrepElementDataType
                      ) => a.order_id - b.order_id
                    )
                    ?.filter(
                      (_item: SolutionPrepElementDataType, index: number) =>
                        index < 3
                    )
                    .map((el: SolutionPrepElementDataType, index: number) => {
                      return <LotsCardsContent showRunningStatus={true} key={index} cardData={el} tooltipIndex={(parseInt((Math.random() *500).toString())).toString() }   />;
                    })}
                {showData &&
                  solutionPrepData?.stage_data
                    ?.sort(
                      (
                        a: SolutionPrepElementDataType,
                        b: SolutionPrepElementDataType
                      ) => a.order_id - b.order_id
                    )
                    ?.map((el: SolutionPrepElementDataType, index: number) => {
                      return <LotsCardsContent showRunningStatus={true} key={index} cardData={el} tooltipIndex={(parseInt((Math.random() *500).toString())).toString() }   />;
                    })}

                <div>
                  <ShowButtonContent
                    handleExtendedData={handleExtendedData}
                    showData={showData}
                    remainingLots={remainingLots}
                    totalLength={
                      solutionPrepData?.stage_data?.sort(
                        (
                          a: SolutionPrepElementDataType,
                          b: SolutionPrepElementDataType
                        ) => a.order_id - b.order_id
                      )?.length
                    }
                  />
                </div>
              </div>
              <div className={"anc-solution-image"}>
                {" "}
                {getMachineImage("solution_prep")}
              </div>
            </div>
          }
          {!solutionPrepData && <APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
        </AncTabCardComponent>
      )}
    </div>
  );
};
export default SolutionPrep;
