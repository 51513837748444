import React from "react";
import "./index.scss";
import Title from "reusable/title";
import StatusLogo from "reusable/status-logo";

interface CompletionSectionProps {
  activeAlarms: string;
  desc: string;
  name: string;
  processStep: string;
  receipeDuration: string;
  recipe: string;
  running: string;
  status: string;
}

const BatchSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const {
    activeAlarms,
    desc,
    name,
    processStep,
    receipeDuration,
    recipe,
    running,
    status,
  } = props;
  const colorCode: { [key: string]: string } = {
    "In Use": "green",
    Down: "red",
    "Planned Down": "grey",
  };
  const colorCodeFn = (status: string) => {
    return colorCode[status];
  };

  return (
    <div className="anc-tooltip-data-container">
      <div className="anc-d-flex anc-pt-0 anc-justify-content-between anc-mt-0 anc-align-items-center">
        <div className="anc-d-flex anc-batch-title">
          <Title title={name} height={"ops-fs-4"} weight={"ops-fw-bold"} />
        </div>
        <div className="anc-d-flex align-items-center anc-running-label">
          <div className="anc-circle-icon anc-pr-1 anc-d-flex">
          <StatusLogo color={colorCodeFn(status)?.toLowerCase()} size={8} className="ops-px-1 " alertClassName="aph-pb-1"/>

          </div>
          {/* <div className="anc-circle"></div> */}
          <div className="anc-status-label anc-text-uppercase anc-d-flex ops-fs-6 ops-fw-bold">
            {status}
          </div>
        </div>
      </div>
      <div className="anc-d-flex anc-mt-1 anc-mt-0 anc-align-items-center">
        {activeAlarms && (status?.toLowerCase()==="down") && (
          <div className="anc-mt-sm-0 anc-active-alarms anc-text-uppercase ops-fw-bold ops-fs-6 anc-mt-md-0">
            <span className="active-alarm-value">{activeAlarms}&nbsp;</span>
            <span>{" Active alarms"}</span>
          </div>
        )}
        {(status?.toLowerCase()==="planned down") &&  (
          <div className="anc-mt-sm-0 anc-non-commerical ops-fs-6 anc-mt-md-0">
            <div>{"Non-commercial Activity"}</div>
          </div>
        )}
         {(status?.toLowerCase()==="in use") &&  (
         <></>
        )}
      </div>
      <div className="anc-batch-section anc-d-flex anc-mt-2 anc-pb-2">
        <div className="">
          <div className="anc-target-predict-heading anc-text-uppercase ops-fs-6 ops-fw-bold ">
            {"MATERIAL DESCRIPTION"}
          </div>
          <div className="anc-material-content ops-fs-5 ">{desc ?? "N/A"}</div>
        </div>
        <div className="anc-mt-2">
          <div className="anc-target-predict-heading anc-text-uppercase ops-fs-6 ops-fw-bold ">
            {"PROCESS STEP"}
          </div>
          <div className="anc-w-100 anc-material-content anc-text-uppercase ops-fs-5">
            {processStep ?? "N/A"}
          </div>
        </div>
      </div>
      <div className="anc-time-section anc-d-flex anc-mt-0 ">
        
        <div className="anc-w-50">
          <div className="anc-target-predict-heading ops-fs-6 ops-fw-bold ">
            {"RECIPE"}
          </div>
          <div className="anc-w-100 anc-endtime-content ops-fs-5">
            {recipe ?? "N/A"}
          </div>
        </div>
        <div className="anc-w-50">
          <div className="anc-running-predict-heading ops-fs-6 ops-fw-bold ">
            {"RECIPE DURATION"}
          </div>
          <div className="anc-starttime-content ops-fs-5 ">
            {receipeDuration ?? "N/A"}
          </div>
        </div>
      </div>
     
    </div>
  );
};
export default BatchSection;
