import React, { useState } from "react";
import AncMetricHeader from "reusable/anc-metric-header";
import AncTabCardComponent from "reusable/anc-tab-component";
import ShowButtonContent from "reusable/anc-show-button-component";
import LotsCardsContent from "reusable/anc-lots-card-content";
import DctFlask from "assets/images/dct-flask";
import ErrorHandler from "utils/error-handler";
import "./index.scss";
import APIErrorMessage from "utils/api-error";

interface ThawInoculationInterface {
  isLoading: boolean;
  isError: boolean;
  data: ThawDataType;
}
const ThawInoculation: React.FC<ThawInoculationInterface> = ({isLoading, isError, data: thawInoculationData}) => {
  const [showData, setShowData] = useState(false);

  const handleExtendedData = (isShow: boolean) => {
    setShowData(!showData);
  };
const remainingLots = (thawInoculationData?.stage_data?.length - 3).toString();
  return (
    <div className="anc-thaw-inoculation-container anc-w-100">
      <AncMetricHeader header={"Thaw/Inoculation"} serialNo={3} ancHeader={thawInoculationData?.stage_data?.length.toString()}/>
      <ErrorHandler isLoading={isLoading} isError={isError} loaderColor="#ffff" />
      {!isLoading&& !isError && <AncTabCardComponent
        className="anc-thaw-inoculation"
        header={"Batch Schedule"}
      >
        {thawInoculationData && 
          <div className={"anc-d-flex anc-thaw-inoculation-card-content"}>
            <div className={"anc-thaw-inoculation-card-table"}>
              {!showData &&
                thawInoculationData?.stage_data?.sort((a:ThawElementDataType, b:ThawElementDataType) => a.order_id - b.order_id)?.filter((_item: ThawElementDataType, index: number) => index < 3)
                  .map((el: ThawElementDataType, index: number) => {
                      return <LotsCardsContent showRunningStatus={true} key={index} cardData={el}tooltipIndex={(parseInt((Math.random() *500).toString())).toString() }   />;
                  })}
              {showData &&
                thawInoculationData?.stage_data?.sort((a:ThawElementDataType, b:ThawElementDataType) => a.order_id - b.order_id)?.map((el: ThawElementDataType, index: number) => {
                  return <LotsCardsContent showRunningStatus={true} key={index} cardData={el} tooltipIndex={(parseInt((Math.random() *500).toString())).toString() }  />;
                })}

              <div>
                <ShowButtonContent
                  handleExtendedData={handleExtendedData}
                  showData={showData}
                  remainingLots={remainingLots}
                  totalLength={thawInoculationData?.stage_data?.length}
                />
              </div>
            </div>
            <div className={"anc-thaw-inoculation-image"}><DctFlask fill={"black"} width={21} height={33}/></div>
          </div>
        }
        {!thawInoculationData && <APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
      </AncTabCardComponent>}
    </div>
  );
};
export default ThawInoculation;
