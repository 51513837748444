import "./index.scss";
import BioReactorTableViewContainer from "components/bioreactor-trains-tab/bioreactor-train-table-view/container.anc";
import AncMachineAlarmComponent from "reusable/anc-machine-alarm-component";
import sampleData from "assets/mock-data/alarms-count-data";
import AncTitleLegendContainer from "reusable/anc-title-legend-container";
import AncTitleLabelHeader from "reusable/anc-title-label-header";
import AncTitleHeaderText from "reusable/anc-title-header-text";
import AncLegendsGrid from "reusable/anc-legends-grid";
import AncSwitchTab from "reusable/anc-switch-tab";
import { useState } from "react";
import labelData from "assets/mock-data/bioreactor-trains-tab-layout-data";
import processTabArray from "assets/mock-data/bioreactor-trains-tab-array";
import BioReactorProcessViewContainer from "./bioreactor-process-view/container.anc";
import { headerAlarmsData } from "constants/thaw-inoc-constants/thaw-inoc-time-alarm";
import machineAlarmsComponent from "constants/bioreactor-trains-constants/bioreactor-trains-machine-alarm";
import BioreactorTooltipContent from "assets/mock-data/bioreactor-legend-tooltip-content";
import { thawInoculationHeaderInterface } from "assets/types/thaw-inoc-header-types";
import APIErrorMessage from "utils/api-error";

interface BioreactorTrainsTabProps {
  bioReactorHeaderIsLoading: boolean;
  bioReactorHeaderIsError: boolean;
  bioReactorHeaderData: thawInoculationHeaderInterface;
}
const BioReactorTrainsTab = ({bioReactorHeaderIsError,bioReactorHeaderIsLoading,bioReactorHeaderData}:BioreactorTrainsTabProps) => {
  const [activeTab, setactiveTab] = useState(0);
  const handleActiveTab = (tabIndex: number) => {
    setactiveTab(tabIndex);
  };
  const [headerdata, setHeaderData] = useState(headerAlarmsData);

  return (
    <>
      <div className=" anc-bioreactor-trains-tab">
      {!bioReactorHeaderIsLoading && !bioReactorHeaderIsError && bioReactorHeaderData ? (

        <div className="anc-bioreactor-trains-time-alarm-container anc-d-flex">
          {headerdata.map((item, index) => {
            return (
              <AncMachineAlarmComponent
              key={index}
              headerLabel={item.header}
              headerKey={item.headerKey}
              headerData={bioReactorHeaderData}
            />
            );
          })} 
        </div>
              ):<APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}

        <div className="anc-thaw-table-process-container">
          <AncTitleLegendContainer>
            <AncTitleLabelHeader>
              <AncTitleHeaderText title={"Bioreactor Train"} />
              <AncLegendsGrid labelData={labelData} labelContent={BioreactorTooltipContent} />
            </AncTitleLabelHeader>
            <AncSwitchTab
              handleActiveTab={handleActiveTab}
              activeTab={activeTab}
              tabData={processTabArray}
            />
          </AncTitleLegendContainer>
          {activeTab === 0 && (
            <div className="anc-d-flex anc-bioreactor-content">
              <BioReactorProcessViewContainer />
            </div>
          )}
          {activeTab === 1 && (
            <div className="anc-d-flex anc-bioreactor-content">
              <BioReactorTableViewContainer />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BioReactorTrainsTab;
