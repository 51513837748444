import { useQuery } from "react-query";
import SolutionPrepUpcomingNext from ".";
import { getSolutionPrepContainerApi } from "api/sol-prep-container-api";
import { useParams } from "react-router-dom";
interface AncSolutionPrepUpcomingNextContainerProps{
  ref: any;
  id:string;
}
const AncSolutionPrepUpcomingNextContainer = ({id,ref}:AncSolutionPrepUpcomingNextContainerProps) => {
  const { site } = useParams();
  const {
    isLoading: upcomingNextIsLoading,
    isError: upcomingNextIsError,
    data: solutionUpcomingNextData,
  } = useQuery(
    ["getSolutionPrepNextContainer-Api", site],
    getSolutionPrepContainerApi.bind(this, {
      type: "upcoming",
      status: "string",
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
    })
  );
  return (
    <SolutionPrepUpcomingNext
      isLoading={upcomingNextIsLoading}
      isError={upcomingNextIsError}
      upcomingNextData={solutionUpcomingNextData?.data?.data}
      id={id}
      ref={ref}
    />
  );
};
export default AncSolutionPrepUpcomingNextContainer;
