export const processViewCardHeaderData = [
    {
      datakey: "name",
      align: "right",
      header: "AMG-135",
      type: "NAME",
    },
    {
      datakey: "lot_value",
      align: "right",
      header: "",
      type: "LOT",
    },
    {
      datakey: "culture_min",
      align: "right",
      header: "culture time",
      type: "MIN",
    },
    {
      datakey: "culture_max",
      align: "right",
      header: "culture time",
      type: "MAX",
    },
  ];

export const processViewLabelData = [
  {
    label: "In Use",
    labelColor: "#008533",
    labelImageType: "circle",
  },
  {
    label: "advisory",
    labelColor: "#D07804",
    labelImageType: "circle",
  },
  {
    label: "CRITICAL",
    labelColor: "#D62728",
    labelImageType: "circle",
  },
  {
    label: "planned downtime",
    labelColor: "#9C9C9C",
    labelImageType: "circle",
  },
  {
    label: "idle",
    labelColor: "#E3E3E3",
    labelImageType: "circle",
  },
  {
    label: "MACHINE down",
    labelColor: "",
    labelImageType: "alert",
  },
];

export const processTabArray = [
  {
    tabLabel: "Process View",
  },
  {
    tabLabel: "Table View",
  },
];

export const ThawProcessViewTitle="Thaw/Inoculation"
export const LotRangeHeaderText="culture time"

export const NameHeaderType="NAME"
export const LotHeaderType="LOT"
export const MinHeaderType="MIN"
export const MaxHeaderType="MAX"
