import HarvestImages from "assets/mock-data/harvest-images";
import "./index.scss";
import AncMetricCard from "reusable/anc-metric-card";
import AncMetricHeader from "reusable/anc-metric-header";
import AncMetricTitle from "reusable/anc-metric-title";
import TileWrapper from "reusable/anc-tile-wrapper";
import ErrorHandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";

interface HarvestProps {
  isLoading: boolean;
  isError: boolean;
  data: HarestDataType[];
}
const Harvest = ({ isError, isLoading, data }: HarvestProps) => {
  return (
    <div className="anc-ml-4 ops-mt-2 anc-harvest-wrapper">
      <AncMetricHeader header={"Harvest"} serialNo={5} />
      <ErrorHandler
        isLoading={isLoading}
        isError={isError}
        loaderColor="#ffff"
      />
       
      <TileWrapper>
        <div className="anc-harvest anc-pl-4 anc-pr-5">
        {!isLoading && !isError && data?.length > 0 ? (
          data?.map((element: HarestDataType, ind: number) => {
            return (
              <div key={parseInt(
                (Math.random() * 500).toString()
              ).toString()}>
                <AncMetricTitle
                  title={element.step_name}
                  className="anc-pl-3 anc-pt-2 anc-pb-1"
                />
                {element?.values
                  ?.sort(function (a: any, b: any) {
                    return a?.order_id - b?.order_id;
                  })
                  .map((el: any, index: number) => {
                    return (
                      <div key={parseInt(
                        (Math.random() * 500).toString()
                      ).toString()}>
                        <AncMetricCard
                          machineStatus={el.status}
                          machineScheduleStatus={el.running_status}
                          machinePhase={el.machine_status}
                          machineTitle={el?.name}
                          image={HarvestImages[ind]}
                          statusColor={el.color_code}
                          runningColor={el.running_status_code}
                          tooltipIndex={parseInt(
                            (Math.random() * 500).toString()
                          ).toString()}
                          activealarms={el.active_alarms}
                          materialdesc={el.material_desc}
                          batchno={el.batch_no}
                          machinestatus={el.machine_status}
                          receipeduration={el.receipe_duration}
                          recipe={el.recipe}
                          running={el.running}
                          unitstatus={el.unit_status}
                        />
                      </div>
                    );
                  })}
              </div>
            );
          })
          ):<APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
        </div>
      </TileWrapper>
 
    </div>
  );
};

export default Harvest;
