import {
  Button,
  ExternalLinkIcon,
  LinkExternalIcon,
  MetricCard,
  Modal,
} from "@gitlab-rtsensing/component-library";
import { useState } from "react";
import DctFlask from "assets/images/dct-flask";
import CleanProgressBar from "reusable/clean-progress-bar";
import MetricCardBlock from "reusable/metric-card-block";
import Accordion from "reusable/accordion";
import AccordionHeader from "reusable/accordion/header";
import AccordionBody from "reusable/accordion/body";
import "./index.scss";
import { TableModalData, ThawInoculationDataType } from "assets/types/thaw-modal-type";
import AncMachineAlarmsCount from "reusable/anc-machine-alarms-count";
import AncMachineAlarmsTooltip from "reusable/anc-alarm-tooltip-content";
import { AlarmType } from "types/bioreactor-trains-type";

interface ThawInoculationModalType {
  opened: boolean;
  close: () => void;
  title: string;
  incubatorName: string;
  alarms: number;
  isTable: boolean;
  alarmsDataArray: AlarmType[];
  materialLots: ThawInoculationDataType[];
  tableLot:TableModalData;
}
const ThawInoculationModal: React.FC<ThawInoculationModalType> = ({
  opened,
  close,
  title,
  alarms,
  isTable,
  incubatorName,
  alarmsDataArray,
  materialLots,
  tableLot
}) => {
  const [thawInoculationData, setThawInoculationData] =
    useState<ThawInoculationDataType[]>(materialLots);

  const getThawInoculationBody = (indData: ThawInoculationDataType | TableModalData) => {
    return (
      <div className="anc-thaw-inoculation-body ops-mt-2">
        <div className="ops-mb-4 anc-thaw-inoculation-body-header">
          <div className="anc-thaw-inoculation-body-header-text-container">
            <div className="anc-thaw-inoculation-body-header-text ops-fs-5 ops-fw-semibold ops-mb-3">
              Current Lot Status
            </div>
            <div className="anc-thaw-inoculation-body-header-machine-description">
              <MetricCardBlock
                headerValue={"Material description"}
                dataValue={indData.desc}
                dataColor={""}
              />
            </div>
          </div>
          <div className="anc-thaw-inoculation-body-header-machine-image">
            <DctFlask fill={"black"} width={36} height={55} />
          </div>
        </div>
        <div className="ops-mb-4 anc-d-flex anc-justify-content-between">
          <div className="anc-w-50">
            <div className="ops-mb-2">
              <MetricCardBlock
                headerValue={"Unit op progress"}
                dataValue={"Thaw/Inoculation"}
                dataColor={""}
              />
            </div>
            <div className="anc-d-flex">
              <CleanProgressBar
                percentageArray={[
                  { percentage: indData.unit_op_progress, flag: "BLUE" },
                ]}
              />
            </div>
          </div>

          <div className="anc-w-45">
            <div className="ops-mb-2">
              <MetricCardBlock
                headerValue={"Step process progress"}
                dataValue={!isTable ? incubatorName : indData.stage}
                dataColor={""}
              />
            </div>
            <div className="anc-d-flex">
              <CleanProgressBar
                percentageArray={[
                  { percentage: indData.step_process_progress, flag: "BLUE" },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="ops-mb-4 anc-d-flex anc-justify-content-between">
          <div className="anc-w-50">
            <div className="">
              <MetricCardBlock
                headerValue={"Minimum culture time"}
                dataValue={indData.culture_time_min}
                dataColor={""}
              />
            </div>
          </div>

          <div className="anc-w-45">
            <div className="">
              <MetricCardBlock
                headerValue={"Maximum culture time"}
                dataValue={indData.culture_time_max}
                dataColor={""}
              />
            </div>
          </div>
        </div>
        <div className="ops-mb-4 anc-d-flex anc-justify-content-between">
          <div className="anc-w-50">
            <div className="">
              <MetricCardBlock
                headerValue={"Next Equipment"}
                dataValue={indData.next_equipment}
                dataColor={""}
              />
            </div>
          </div>

          <div className="anc-w-45">
            <div className="">
              <MetricCardBlock
                headerValue={"schedule adherence"}
                dataValue={indData.running_status}
                dataColor={indData.color_code as string}
                dataFontWeight={"bold"}
              />
            </div>
          </div>
        </div>
        <div className="ops-mb-4 anc-d-flex anc-justify-content-between">
          <div className="anc-w-50">
            <div className="ops-mb-3">
              <MetricCardBlock
                headerValue={"recipes"}
                dataValue={indData.recipes}
                dataColor={""}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const tooltipId = parseInt((Math.random() * 500).toString()).toString();
  
  const modalTitle = !isTable
    ? `${title} (${incubatorName})`
    : `${title} - ${tableLot?.name}`;

  return (
    <Modal
      className={"anc-thaw-inoculation-modal"}
      opened={opened}
      onClose={() => {
        close();
      }}
    >
      <Modal.Header contentClassName="d-flex align-items-center">
        <>
          <Modal.Title title={modalTitle} className="anc-modal-title" />
          {alarmsDataArray.length > 0 && (
            <AncMachineAlarmsCount
              alarmCount={alarmsDataArray.length ?? 0}
              tooltipId={`anc-alarm-tooltip-${tooltipId}`}
              tooltip={
                <AncMachineAlarmsTooltip
                  id={`anc-alarm-tooltip-${tooltipId}`}
                  alarms={alarmsDataArray}
                />
              }
            />
          )}
        </>
      </Modal.Header>
      <MetricCard.Content>
        <div className="anc-thaw-inoculation-modal-body">
          {!isTable &&
            thawInoculationData.map((indData, index) => {
              return (
                <>
                  {!isTable && (
                    <Accordion key={index}>
                      <AccordionHeader
                        opened={indData.isOpen}
                        toggle={(isOpen) => {
                          const updatedData = thawInoculationData.map(
                            (newData, newIndex) => {
                              if (index === newIndex) {
                                newData.isOpen = isOpen;
                              } else {
                                newData.isOpen = false;
                              }
                              return newData;
                            }
                          );
                          setThawInoculationData([...updatedData]);
                        }}
                      >
                        <div className="ops-fs-4">{`${indData.name}`}</div>
                      </AccordionHeader>
                      {indData.isOpen && (
                        <AccordionBody>
                          {getThawInoculationBody(indData)}
                        </AccordionBody>
                      )}
                    </Accordion>
                  )}
                </>
              );
            })}
          {isTable && (
            <AccordionBody>
              {getThawInoculationBody(tableLot)}
            </AccordionBody>
          )}
        </div>
      </MetricCard.Content>
      <div className="anc-d-flex anc-thaw-inoculation-footer">
        <div className="w-50 anc-text-center">
          <Button
            className="anc-pi-vision-button"
            label="GO TO PI VISION"
            type="secondary"
            iconPosition="right"
            icon={<ExternalLinkIcon width={12} height={12} fill="#0063c3" />}
            disabled={false}
          />
        </div>
        <div className="w-50 anc-text-center">
          <Button
            className="anc-simca-online-button"
            label="GO TO SIMCA ONLINE"
            type="secondary"
            iconPosition="right"
            icon={<LinkExternalIcon width={12} height={12} fill="#0063c3" />}
            disabled={false}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ThawInoculationModal;
