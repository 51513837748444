
import BatchSection from "./batch-section";
import CardComponent from "./card";
import "./index.scss";

interface LotsTooltipDataProps {
  activealarms:string;
  materialdesc: string;
  batchno:string;
  machinestatus:string;
  receipeduration:string;
  recipe:string;
  running:string;
  unitstatus:string;

}
const LotsTooltipComponent: React.FC<LotsTooltipDataProps> = (
  {
    activealarms,
  materialdesc,
  batchno,
  machinestatus,
  receipeduration,
  recipe,
  running,
  unitstatus
}
  
) => {

  return (
    <CardComponent header={""}>
      <div className="anc-tag-tooltip-container">
        <div className="anc-batch-container ">
          <BatchSection
             activeAlarms={activealarms}
             desc={materialdesc }
             name={batchno }
             processStep={machinestatus }
             receipeDuration={receipeduration }
             recipe={recipe}
             running={running }
             status={unitstatus }
             
          />
        </div>
      </div>
    </CardComponent>
  );
};

export default LotsTooltipComponent;
