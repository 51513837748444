import { useEffect, useRef } from "react";
import SolutionPrepPage from ".";
import { useParams } from "react-router-dom";

const SolutionPrepContainer = () => {
  const { tab_route: tab } = useParams();
  const inProgress = useRef<React.MutableRefObject<HTMLElement>>();
  const upcoming = useRef<React.MutableRefObject<HTMLElement>>();
  const completed = useRef<React.MutableRefObject<HTMLElement>>();

  useEffect(() => {
    if (location && tab==="solution") {
      const currentInprogress: any = inProgress.current;
      const currentUpcoming: any = upcoming.current;
      const currentCompleted: any = completed.current;

      if (location.hash === "#anc-in-progress-sol") {
        if (currentInprogress) {
          window.scroll({
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            top: currentInprogress?.getBoundingClientRect()?.top +
            window.pageYOffset - 150,

            behavior: "smooth",
          });
        }
      }
      if (location.hash === "#anc-upcoming-48-hours-sol") {
        if (currentUpcoming) {
          window.scroll({
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            top: currentUpcoming?.getBoundingClientRect()?.top +
            window.pageYOffset - 150,
            behavior: "smooth",
          });
        }
      }
      if (location.hash === "#anc-completed-48-hours-sol") {
        if (currentCompleted) {
          window.scroll({
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            top: currentCompleted?.getBoundingClientRect()?.top +
            window.pageYOffset - 150,
            behavior: "smooth",
          });
        }
      }
    }
  }, [tab,location.hash]);
  return (
    <SolutionPrepPage
      inprogressRef={inProgress}
      completedRef={completed}
      upcomingRef={upcoming}
    />
  );
};
export default SolutionPrepContainer;
