
const labelData = [
    {
      label: "In Use",
      labelColor: "#008533",
      labelImageType: "circle",
    },
    {
      label: "Advisory",
      labelColor: "#D07804",
      labelImageType: "circle",
    },
    {
      label: "Critical",
      labelColor: "#D62728",
      labelImageType: "circle",
    },
    {
      label: "Planned Downtime",
      labelColor: "#9C9C9C",
      labelImageType: "circle",
    },
    {
      label: "Idle",
      labelColor: "#E3E3E3",
      labelImageType: "circle",
    },
    {
      label: "Machine Down",
      labelColor: "",
      labelImageType: "alert",
    },
  ];
  
  export default labelData;