import { ThawInoculationTableDataObjProps } from "assets/types/thaw-inoc-table-types";
import moment from "moment";

export const thawInoculationHeaderData = [
  {
    datakey: "name",
    align: "left",
    key: 1,
    sortKey: false,
    sortfn: function (
      a: ThawInoculationTableDataObjProps,
      b: ThawInoculationTableDataObjProps
    ): number {
      if (this.sortKey) {
        return a.material > b.material ? 1 : -1;
      } else {
        return b.material > a.material ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Material #",
    sortActive: true,
  },
  {
    datakey: "lot",
    align: "right",
    key: 2,
    sortKey: false,
    sortfn: function (
      a: ThawInoculationTableDataObjProps,
      b: ThawInoculationTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.lot > b.lot ? 1 : -1;
      } else {
        return b.lot > a.lot ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Lot #",
    sortActive: false
  },
  {
    datakey: "stage",
    align: "left",
    key: 3,
    sortKey: false,
    sortfn: function (
      a: ThawInoculationTableDataObjProps,
      b: ThawInoculationTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.stage > b.stage ? 1 : -1;
      } else {
        return b.stage > a.stage ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Stage",
    sortActive: false
  },
  {
    datakey: "solution_desc",
    align: "left",
    key: 4,
    sortKey: false,
    sortfn: function (
      a: ThawInoculationTableDataObjProps,
      b: ThawInoculationTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.solution_desc > b.solution_desc ? 1 : -1;
      } else {
        return b.solution_desc > a.solution_desc? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Solution description",
    sortActive: false
  },
  {
    datakey: "target_start",
    align: "left",
    key: 5,
    sortKey: false,
    sortfn: function (
      a: ThawInoculationTableDataObjProps,
      b: ThawInoculationTableDataObjProps
    ) {
      const momentA = moment(a.target_start, "hh:mmA"); // Parse time string
      const momentB = moment(b.target_start, "hh:mmA"); // Parse time string

      if (this.sortKey) {
        return momentA.isBefore(momentB) ? 1 : -1;
      } else {
        return momentB.isBefore(momentA) ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Target Start",
    sortActive: false
  },
  {
    datakey: "actual_start",
    align: "left",
    key: 6,
    sortKey: false,
    sortfn: function (
      a: ThawInoculationTableDataObjProps,
      b: ThawInoculationTableDataObjProps
    ) {
      const momentA = moment(a.actual_start, "hh:mmA");
      const momentB = moment(b.actual_start, "hh:mmA");

      if (this.sortKey) {
        return momentA.isBefore(momentB) ? 1 : -1;
      } else {
        return momentB.isBefore(momentA) ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Actual Start",
    sortActive: false
  },
  // {
  //   datakey: "header7",
  //   sortfn: () => {},
  //   align: "left",
  //   key: 7,
  //   sortKey: false,
  //   columnNameVisible: false,
  //   columnComponentType: "text",
  //   header: "",
  //   sortActive: false
  // },
  {
    datakey: "running_status",
    align: "left",
    key: 8,
    sortKey: false,
    sortfn: function (
      a: ThawInoculationTableDataObjProps,
      b: ThawInoculationTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.schedule > b.schedule ? 1 : -1;
      } else {
        return b.schedule > a.schedule ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Schedule",
    sortActive: false
  },
  {
    datakey: "header9",
    sortfn: () => {},
    align: "right",
    key: 9,
    sortKey: false,
    columnNameVisible: false,
    columnComponentType: "button",
    header: "View More",
    sortActive: false
  },
];
