import React from "react";
import "./index.scss";
import AlertIcon from "assets/icons/alert-icon";

interface AncTabTableTextComponentInterface {
  textValue: string | number | undefined;
  textColorValue: string | number;
  textHeader: string;
}

const AncTabTableTextComponent: React.FC<AncTabTableTextComponentInterface> = (
  props: AncTabTableTextComponentInterface
) => {
  const { textValue, textColorValue, textHeader } = props;
  return (
    <div
      className={
        textHeader === "status" && textValue === "Critical"
          ? "d-flex align-items-center"
          : " "
      }
    >
      <div
        className={
          (textHeader === "schedule" || textHeader === "running_status") &&
          textColorValue === "RED"
            ? "ops-fw-bold anc-text-color-red "
            : " " + " ops-fs-5 anc-table-text"
        }
      >
        {textValue}
      </div>
      {textHeader === "status" && textValue === "Critical" ? (
        <>
          <AlertIcon fill="#d62728" classname="anc-pl-2 anc-pt-1" />
          <div className="anc-critical-down-text anc-pl-2">Down</div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default AncTabTableTextComponent;
