import React from "react";
import "./index.scss";

interface AncStartTimeProps {
  startTime: string;
}
const AncStartTime: React.FC<AncStartTimeProps> = ({ startTime }) => {
  return (
    <div className="anc-card-start-time ops-fw-bold ops-fs-6 ops-lh-4">
      {`START${startTime}`}
    </div>
  );
};
export default AncStartTime;
