const TitleLeft = [
  {
    title: "Wave Bioreactor",
  },
  {
    title: "N-3/N-2 SUB Bioreactor",
  },
  {
    title: "N-1 SUB Bioreactor / ATF",
  },
];

export default TitleLeft;
