import { MetricCard, Modal } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import { Tooltip } from "react-tooltip";
import ViewMoreContent from "./view-more-content";
import AncAlarmWithToolTip from "reusable/anc-alarm-with-tooltip";
import { AlarmType } from "types/bioreactor-trains-type";

interface ViewMoreModalInterface {
  opened: boolean;
  close: () => void;
  title: string | number | undefined;
  alarms: AlarmType[];
  status: string;
  equipmentid: string;
  material: string;
  lot: string;
  stepprogress: string;
  processStep: string;
  schedule: string;
  scheduleColorCode: string;
}
const BioReactorModal: React.FC<ViewMoreModalInterface> = ({
  opened,
  close,
  title,
  alarms,
  status,
  equipmentid,
  material,
  lot,
  stepprogress,
  processStep,
  schedule,
  scheduleColorCode,
 
}) => {
  const getTooltipBody = () => {
    return (
      <div>
        <div className="ops-fs-5 ops-fw-semibold ops-mb-1">Current Alarms</div>
        <div>
          <table className="alarm-table">
            <tr>
              <th className="ops-fs-6 ops-fw-semibold">ALARM</th>
              <th className="ops-fs-6 ops-fw-semibold">DURATION</th>
              <th className="ops-fs-6 ops-fw-semibold">TYPE</th>
            </tr>
            {alarms.map((alarm) => {
              return (
                <tr key={alarm.name}>
                  <td className="ops-fs-6">{alarm.name}</td>
                  <td className="ops-fs-6">{alarm.duration}</td>
                  <td className="ops-fs-6">{alarm.type}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    );
  };

  const getTooltip = (id: string) => {
    return (
      <Tooltip
        noArrow={true}
        float
      isOpen={false}
        className={"anc-thaw-inoculation-alarm-tooltip"}
        render={() => {
          return (
            <div className="anc-thaw-inoculation-alarm-tooltip-body">
              {getTooltipBody()}
            </div>
          );
        }}
        style={{
          backgroundColor: "white",
          padding: "0px",
          zIndex: 1000,
          opacity: "1",
        }}
        anchorSelect={`#${id}`}
      ></Tooltip>
    );
  };

  return (
    <Modal
      className={"anc-view-more-modal"}
      opened={opened}
      onClose={() => {
        close();
      }}
    >
      <Modal.Header contentClassName="d-flex align-items-center">
        <>
          <Modal.Title
            title={title?.toString() ?? ""}
            className="anc-modal-title"
          />
          {2 > 0 && (
            <AncAlarmWithToolTip
              alarm={2}
              colorCode="red"
              tooltipId="anc-thaw-inoculation-alarm-container"
              tooltip={getTooltip("anc-thaw-inoculation-alarm-container")}
            />
          )}
        </>
      </Modal.Header>
      <MetricCard.Content>
        <ViewMoreContent alarms={alarms} 
        status={status}
                equipmentid={equipmentid}
                material={material}
                lot={lot}
                stepprogress={stepprogress}
                processstep={processStep}
                schedule={schedule}
                schedulecolorcode={scheduleColorCode}
               />
      </MetricCard.Content>
    </Modal>
  );
};
export default BioReactorModal;
