/* eslint-disable react/react-in-jsx-scope */
import './index.scss'

export interface SegmentHeaderType {
  title: string
  id: string
}
const SegmentHeader = (props: SegmentHeaderType) => {
  return (
    <div
      id={props.id}
      className="anc-d-flex anc-segment-header"
    >
      {props.title}
    </div>
  )
}
export default SegmentHeader
