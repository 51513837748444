import { useQuery } from "react-query";
import SolutionPrepUpcomingLast from ".";
import { getSolutionPrepContainerApi } from "api/sol-prep-container-api";
import { useParams } from "react-router-dom";
interface AncSolutionPrepUpcomingLastContainerProps{
  ref: any;
  id:string;
}
const AncSolutionPrepUpcomingLastContainer = ({id,ref}:AncSolutionPrepUpcomingLastContainerProps) => {
  const { site } = useParams();
  const {
    isLoading: upcomingLastIsLoading,
    isError: upcomingLastIsError,
    data: solutionUpcomingLastData,
  } = useQuery(
    ["getSolutionPrepLastContainer-Api", site],
    getSolutionPrepContainerApi.bind(this, {
      type: "completed",
      status: "string",
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
    })
  );
  return (
    <SolutionPrepUpcomingLast
      isLoading={upcomingLastIsLoading}
      isError={upcomingLastIsError}
      upcomingLastData={solutionUpcomingLastData?.data?.data}
      id={id}
      ref={ref}
    />
  );
};
export default AncSolutionPrepUpcomingLastContainer;
