import { useEffect } from 'react';
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    Security
  } from '@okta/okta-react'
  import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
  import { useNavigate } from 'react-router-dom'
  
  interface IAuthComponentProp {
    children: React.ReactNode
  }
  const AuthComponent: React.FC<IAuthComponentProp> = ({ children }) => {
    const history = useNavigate()
    const oktaAuthConfig = new OktaAuth({
      issuer: process.env.REACT_APP_OKTA_ISSUER_LINK,
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
      redirectUri: window.location.origin + '/implicit/callback',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      pkce: true,
      responseType: 'code',
      tokenManager: { expireEarlySeconds: 120, autoRenew: true, storageKey: 'dct-okta-token' }
    })
    const restoreOriginalUri = async (_oktaAuth: unknown, originalUri: string) => {
      history(toRelativeUrl(originalUri || '/', window.location.origin))
    }

    /********** RENEW TOKEN EVERY 15 MINUTES  *********/
    useEffect(() => {
      const renewTokenInterval = setInterval(() => {
        oktaAuthConfig.tokenManager.renew('accessToken');
        oktaAuthConfig.tokenManager.renew('idToken');
      }, 900000); // Every 15min

      return () => clearInterval(renewTokenInterval);
    }, []);

    /********** AUTOMATICALLY RENEW TOKEN ON EXPIRATION *********/
    useEffect(() => {
      oktaAuthConfig.tokenManager.off('expired');
      // Triggered when a token has expired
      oktaAuthConfig.tokenManager.on(
        'expired',
        function (key: any, expiredToken: any) {
          oktaAuthConfig.tokenManager.renew('accessToken');
          oktaAuthConfig.tokenManager.renew('idToken');
        },
      );

      return () => oktaAuthConfig.tokenManager.off('expired');
    }, []);
  
    return (
          <>
          <Security
            oktaAuth={oktaAuthConfig}
            restoreOriginalUri={restoreOriginalUri}
          >
            {children}
          </Security>
          </>
    )
  }
  export default AuthComponent
