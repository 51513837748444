import React from 'react';
import classNames from 'classnames';
import cls from './tab-content.module.scss';

interface Props {
  children: JSX.Element;
}

export const TabContent = (props: Props) => {
  const { children } = props;
  return (
    <div className={classNames(cls['ops-tab-content'], 'ops-px-0')}>
      {children}
    </div>
  );
};

export default TabContent;
