import ThawIncubatorCard from "../thaw-incubator-card";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import ThawIncubatorHeader from "../thaw-incubator-header";
import {
  IncubatorDetailsInterface,
  IncubatorStagesInterface,
} from "assets/types/thaw-inoculation-process-view.types";
interface ThawIncubatorContainerInterface {
  incubatorData: IncubatorDetailsInterface;
}

const ThawIncubatorContainer: React.FC<ThawIncubatorContainerInterface> = (
  props: ThawIncubatorContainerInterface
) => {
  const { incubatorData } = props;
  const materialList = incubatorData?.incubator_stages?.flatMap(
    (item) => item.alarm_data
  );

  return (
    <div className="anc-d-flex anc-thaw-incubator-card-wrapper">
      <ThawIncubatorHeader
        activeAlarms={incubatorData.active_alarms}
        colorCode={incubatorData.color_code}
        incubatorId={incubatorData.id}
        incubatorName={incubatorData.incubator_name}
        materialListArray={materialList}
      />
      {incubatorData?.incubator_stages?.map(
        (stage: IncubatorStagesInterface) => {
        
          return (
            <div key={stage.name}>
              <ThawIncubatorCard
                incubatorStage={stage}
                incubatorName={incubatorData?.incubator_name ?? "N/A"}
                incubatorActiveAlarms={incubatorData?.active_alarms}
                alarmsData={stage?.alarm_data}
              />
            </div>
          );
        }
      )}
    </div>
  );
};
export default ThawIncubatorContainer;
