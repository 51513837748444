import { useQuery } from "react-query";
import { getNonCommTabApi } from "api/non-comm-tab-api";
import NonCommercialTab from ".";
import { useParams } from "react-router-dom";

const NonCommTabContainer = () => {
  const { site } = useParams();
  const {
    isLoading,
    isError,
    data: nonCommTabData,
  } = useQuery(
    ["getNonCommTabApi-Api",site],
    getNonCommTabApi.bind(this, {
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
      curr_stage: "string",
    })
  );

  return (
    <NonCommercialTab
      isLoading={isLoading}
      isError={isError}
      data={nonCommTabData?.data?.data}
    />
  );
};

export default NonCommTabContainer;
