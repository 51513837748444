import { useQuery } from "react-query"
import DownstreamWrapper from "."
import { getDownStream } from "api/downstream-api"
import ErrorHandler from "utils/error-handler"

import { useParams } from "react-router-dom";
const DownStreamContainer = () => {
const { site } = useParams();

    const { isLoading, isError, data: downStreamData } = useQuery(['DownStream-API',site], getDownStream.bind(this,
        {
            mfg_stage: "string",
            site: site as string,
            building: "string",
            line_no: "string",
            curr_stage: "string"
        }
    ))
    return (
        <DownstreamWrapper 
        isLoading={isLoading} 
        isError={isError}
        downStreamData={downStreamData?.data?.data}
        />
    )
}
export default DownStreamContainer