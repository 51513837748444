import CalenderIcon from "assets/icons/calender-icon"

interface UpcomingLotsContentType {
    label: string
    date: string
    time: string
    lotNumber: string
}

const UpcomingLotsContent: React.FC<UpcomingLotsContentType> = (props) => {
    const {label, date, time, lotNumber} = props;

    return (
        <div className="anc-d-flex anc-align-items-center anc-upcoming-lot-header">
            <div className="anc-label ops-fw-bold ops-fs-6 anc-ml-3 anc-mr-4">
                {label}
            </div>

            <div className="anc-mr-1 anc-mr-sm-1">
                <CalenderIcon fill="#2F2F2F" classname="" />
            </div>
            <div className="anc-lot-date ops-fw-bold ops-fs-5 anc-mr-2">
                {date} {time}
            </div>
            {/*
            <div className="anc-lot-time ops-fw-bold ops-fs-5">
                {time}
            </div>
            */}

            <div className="anc-lot ops-fw-bold ops-fs-5">
                {lotNumber}
            </div>
        </div>
    )
}

export default UpcomingLotsContent;