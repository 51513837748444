import { useQuery } from "react-query"
import WeighExpense from "."
import { getWeighExpenseApi } from "api/weighexpense-api"
import { useParams } from "react-router-dom";

const WeighDispenseContainer = () => {
    const { site } = useParams();
    const {isLoading, isError, data: WeighExpenseData} = useQuery(['getWeighDispense-Api',site],getWeighExpenseApi.bind(this,{
        mfg_stage: "string",
        site: site as string,
        building: "string",
        line_no: "string",
        curr_stage: "string"
    }))
    return (
        <WeighExpense 
            isLoading={isLoading} 
            isError={isError} 
            data={WeighExpenseData?.data?.data} 
        />
    )
}
export default WeighDispenseContainer;
