import { Status } from "@gitlab-rtsensing/component-library";
import CircleIcon from "assets/icons/circle-icon";
import { getStatusColor } from "utils/common-methods";

interface StatusLogoProps {
  color: string;
  size: number;
  alertClassName?: string;
  className?: string;
}
const StatusLogo: React.FC<StatusLogoProps> = ({
  color,
  size,
  className,
  alertClassName,
}: StatusLogoProps) => {
  return (
    <>
      {
        <div className={className}>
          <div
            style={{
              backgroundColor: getStatusColor(color),
              borderRadius: "100px",
              height: size,
              width: size,
            }}
          />
        </div>
      }
    </>
  );
};

export default StatusLogo;
