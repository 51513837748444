import React from "react";
import "./index.scss";
import VectorUpDownIcon from "assets/icons/vector-up-down-icon";
interface AncHeaderTextInterface {
  textValue: string;
  sortKey: boolean;
  columnNameVisible: boolean;
  alignText: string;
  sortFn: () => void;
  sortActive: boolean;
}

const AncHeaderText: React.FC<AncHeaderTextInterface> = (
  props: AncHeaderTextInterface
) => {
  const { textValue, sortKey, alignText, sortFn, sortActive } = props;
  return (
    <>
      {props.columnNameVisible && (
        <div
          className={
            (alignText === "right"
              ? "anc-tab-table-header-data-right "
              : alignText === "center"
              ? "anc-tab-table-header-data-center "
              : "anc-tab-table-header-data-left ") +
            "anc-d-flex anc-align-items-center anc-tab-table-header-data"
          }
        >
          <div className="anc-text-uppercase anc-text-value">{textValue}</div>
          <div className="anc-vector-icon-container" onClick={sortFn}>
            {sortActive
                ? <VectorUpDownIcon
                fillUp={sortKey ? "#0063C3" : "#9C9C9C"}
                fillDown={sortKey ? "#9C9C9C" :  "#0063C3"}
              /> 
              : <VectorUpDownIcon
              fillUp={"#9C9C9C"}
              fillDown={"#9C9C9C"}
            />
            }
            
          </div>
        </div>
      )}
    </>
  );
};
export default AncHeaderText;
