import "./index.scss";

interface TableHeaderComponentProps {
  children: React.ReactNode;
  align?: string;
}

const TableHeaderComponent: React.FC<TableHeaderComponentProps> = ({
  children,
  align,
}) => {
  return (
    <th
      className={
        (align === "right"
          ? "anc-tab-table-header-data-right "
          : align === "center"
          ? "anc-tab-table-header-data-center "
          : "anc-tab-table-header-data-left ") + 
          "anc-tab-table-header-data anc-text-uppercase ops-fs-6"
      }
    >
      {children}
    </th>
  );
};

export default TableHeaderComponent;
