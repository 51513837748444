import { useQuery } from "react-query";
import WeighAndDispenseProgress from ".";
import { getWeighAndDispenseContainerApi } from "api/weigh-dispense-container-api";
import { useParams } from "react-router-dom";

const AncWeighAndDispenseProgressContainer = () => {
  const { site } = useParams()
  const {
    isLoading: inProgressIsLoading,
    isError: inProgressIsError,
    data: inProgressData,
  } = useQuery(
    ["getWeighAndDispenseContainer-Api", site],
    getWeighAndDispenseContainerApi.bind(this, {
      type: "inprogress",
      status: "string",
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
    })
  );
  
  return (
    <WeighAndDispenseProgress
      isLoading={inProgressIsLoading}
      isError={inProgressIsError}
      inProgressData={inProgressData?.data?.data ?? []}
    />
  );
};
export default AncWeighAndDispenseProgressContainer;
