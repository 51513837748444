interface Props {
  className?: string;
}

const DangerIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.99996 1.1665C5.84624 1.1665 4.71842 1.50862 3.75914 2.1496C2.79985 2.79057 2.05218 3.70161 1.61066 4.76752C1.16915 5.83342 1.05363 7.00631 1.27871 8.13786C1.50379 9.26942 2.05937 10.3088 2.87517 11.1246C3.69098 11.9404 4.73038 12.496 5.86194 12.7211C6.99349 12.9462 8.16638 12.8306 9.23228 12.3891C10.2982 11.9476 11.2092 11.2 11.8502 10.2407C12.4912 9.28138 12.8333 8.15356 12.8333 6.99984C12.8315 5.45328 12.2164 3.97058 11.1228 2.877C10.0292 1.78342 8.54651 1.16827 6.99996 1.1665Z"
        fill="#D62728"
      />
      <path
        d="M6.58748 4.25435C6.69688 4.14495 6.84525 4.0835 6.99996 4.0835C7.15467 4.0835 7.30304 4.14495 7.41244 4.25435C7.52183 4.36375 7.58329 4.51212 7.58329 4.66683V7.00016C7.58329 7.15487 7.52183 7.30325 7.41244 7.41264C7.30304 7.52204 7.15467 7.5835 6.99996 7.5835C6.84525 7.5835 6.69688 7.52204 6.58748 7.41264C6.47808 7.30325 6.41663 7.15487 6.41663 7.00016V4.66683C6.41663 4.51212 6.47808 4.36375 6.58748 4.25435Z"
        fill="white"
      />
      <path
        d="M6.67588 8.84847C6.77181 8.78437 6.88459 8.75016 6.99996 8.75016C7.15467 8.75016 7.30304 8.81162 7.41244 8.92102C7.52183 9.03041 7.58329 9.17879 7.58329 9.3335C7.58329 9.44887 7.54908 9.56165 7.48498 9.65758C7.42089 9.75351 7.32978 9.82827 7.22319 9.87243C7.1166 9.91658 6.99931 9.92813 6.88616 9.90562C6.773 9.88311 6.66906 9.82756 6.58748 9.74598C6.5059 9.66439 6.45034 9.56045 6.42783 9.4473C6.40533 9.33414 6.41688 9.21685 6.46103 9.11026C6.50518 9.00367 6.57995 8.91257 6.67588 8.84847Z"
        fill="white"
      />
    </svg>
  );
};

export default DangerIcon;
