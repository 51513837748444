import React, { useState } from "react";
import AncMetricHeader from "reusable/anc-metric-header";
import AncTabCardComponent from "reusable/anc-tab-component";
import LotsCardsContent from "reusable/anc-lots-card-content";
import ShowButtonContent from "reusable/anc-show-button-component";
import DctDsBag from "assets/images/dct-ds-bag";
import ErrorHandler from "utils/error-handler";
import "./index.scss";
import { getMachineImage } from "utils/common-methods";
import APIErrorMessage from "utils/api-error";

interface WeighExpenseInterface {
  isLoading: boolean;
  isError: boolean;
  data: WeighDispenseDataType;
}
const WeighExpense: React.FC<WeighExpenseInterface> = ({
  isLoading,
  isError,
  data: weighExpenseData,
}) => {
  const [showData, setShowData] = useState(false);
  const handleExtendedData = (isShow: boolean) => {
    setShowData(!showData);
  };

  const remainingLots = (weighExpenseData?.stage_data?.length - 3).toString();
  return (
    <div className="anc-weigh-dispense-container anc-w-100">
      <AncMetricHeader
        header={"Weigh and Dispense"}
        serialNo={1}
        ancHeader={weighExpenseData?.stage_data?.length.toString()}
      />
      <ErrorHandler
        isLoading={isLoading}
        isError={isError}
        loaderColor="#ffff"
      />
      
        <AncTabCardComponent
          className="anc-weigh-dispense"
          header={"Batch Schedule"}
        >
          <div className={"anc-d-flex anc-weigh-dispense-card-content"}>
          {!isLoading && !isError && weighExpenseData ?(
            weighExpenseData && 
            <>
              <div className={"anc-weigh-dispense-card-table"}>
                {!showData &&
                  weighExpenseData?.stage_data
                    ?.sort(
                      (
                        a: SolutionPrepElementDataType,
                        b: SolutionPrepElementDataType
                      ) => a.order_id - b.order_id
                    )
                    ?.filter(
                      (_item: WeighDispenseElementDataType, index: number) =>
                        index < 3
                    )
                    .map((el: WeighDispenseElementDataType, index: number) => {
                      return <LotsCardsContent showRunningStatus={false} key={index} cardData={el}tooltipIndex={(parseInt((Math.random() *500).toString())).toString() }   />;
                    })}

                {showData &&
                  weighExpenseData?.stage_data
                    ?.sort(
                      (
                        a: SolutionPrepElementDataType,
                        b: SolutionPrepElementDataType
                      ) => a.order_id - b.order_id
                    )
                    ?.map((el: WeighDispenseElementDataType, index: number) => {
                      return <LotsCardsContent showRunningStatus={false} key={index} cardData={el}tooltipIndex={(parseInt((Math.random() *500).toString())).toString() }   />;
                    })}

                <div>
                  <ShowButtonContent
                    handleExtendedData={handleExtendedData}
                    showData={showData}
                    remainingLots={remainingLots}
                    totalLength={weighExpenseData?.stage_data?.length}
                  />
                </div>
              </div>
              <div className={"anc-weigh-dispense-image"}>
                {getMachineImage("weigh_dispense")}
              </div>
            </>
            ):<APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
          </div>
        </AncTabCardComponent>
      
    </div>
  );
};
export default WeighExpense;
