import React from "react";
import "./index.scss";
import AlarmBellIcon from "assets/icons/alarm-bell-icon";

interface AncMachineAlarmsCountProps {
  alarmCount: number ;
  tooltip?: React.ReactNode;
    tooltipId: string;
}
const AncMachineAlarmsCount: React.FC<AncMachineAlarmsCountProps> = ({
  alarmCount,
  tooltipId,
  tooltip
}) => {
  
  const textColor = alarmCount > 0 ? "#d62728" : "#9c9c9c";
  const color = alarmCount > 0 ? "red" : "grey";
  
  return (
    <div
    id={tooltipId}
      className={`anc-machine-alarm-count anc-alarm-${color} anc-d-flex anc-ml-1 ops-fw-medium ops-lh-3 ops-fs-5`}
    >
      <AlarmBellIcon fill={textColor} classname="ops-mb-1"></AlarmBellIcon>
      {alarmCount}
      {tooltip && tooltip}
    </div>
  );
};
export default AncMachineAlarmsCount;
