import AncMachineAlarmsCount from "reusable/anc-machine-alarms-count";
import "./index.scss";
import AncMachineAlarmsTooltip from "reusable/anc-alarm-tooltip-content";
import { AlarmType } from "types/bioreactor-trains-type";
interface ThawIncubatorHeaderInterface {
  activeAlarms: number;
  colorCode: string;
  incubatorId: string;
  incubatorName: string;
  materialListArray:AlarmType[];
}

const ThawIncubatorHeader = (props: ThawIncubatorHeaderInterface) => {
  const tooltipId = parseInt((Math.random() * 500).toString()).toString();

  return (
    <div
      className={
        (props.colorCode === "green"
          ? "anc-thaw-incubator-border-green "
          : props.colorCode === "red"
          ? "anc-thaw-incubator-border-red "
          : "") + " d-flex anc-thaw-incubator-header"
      }
    >
      <div className="d-flex ops-fs-4 ops-fw-semibold">
        {props.incubatorName ?? "N/A"}
      </div>
      <div className="d-flex anc-thaw-incubator-id-alarm">
        <div className="d-flex anc-thaw-incubator-id">
          <div className="d-flex ops-fs-5 anc-thaw-incubator-id-context">
            ID:&nbsp;
          </div>
          <div className="d-flex ops-fs-5 anc-thaw-incubator-id-context">
            {props.incubatorId ?? "N/A"}
          </div>
        </div>
        <div className="d-flex ops-fs-5 anc-thaw-incubator-alarm">
          <AncMachineAlarmsCount
            alarmCount={props.activeAlarms ?? 0}
            tooltipId={`anc-alarm-tooltip-${tooltipId}`}
            tooltip={
              <AncMachineAlarmsTooltip
                id={`anc-alarm-tooltip-${tooltipId}`}
                alarms={props.materialListArray}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
export default ThawIncubatorHeader;
