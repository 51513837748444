/* eslint-disable no-debugger */
import React, { useRef, useEffect, useContext } from 'react'
import './index.scss'

// import {AppContext} from '../../../context/app-context'

import {AppContext} from '../../context/app-context'
interface PageLayoutProps {
  children: JSX.Element
}
const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const navRef = useRef<any>(null)
  const {setAppState, appState} = useContext(AppContext)
  useEffect(() => {
    
    const navRefCurrent = navRef.current
    if (navRefCurrent) {
      setAppState({...appState,navRoot: navRefCurrent})
    }
  },[navRef?.current])
  return (
      <>
        <div>
          <div id="dct-desktop-side-nav"ref={navRef} ></div>
          <div id={'dct-main-content'} className='anc-layout-wrapper anc-mt-5'>
              {children}
          </div>
        </div>
        </>
  )
}

export default PageLayout
