export const headerAlarmsData = [
  {
    header: "Machines - critical hold",
    headerKey: "machine_critical_hold",
    value: "0",
  },
  {
    header: "Machines in use",
    headerKey: "machine_in_use",
    value: "2",
  },
  {
    header: "Total Lots",
    headerKey: "total_lots",
    value: "8",
  },
  {
    header: "Total Alarms",
    headerKey: "total_alarms",
    value: "0",
  },
  {
    header: "Critical alarms",
    headerKey: "critical_alarms",
    value: "0",
  },
  {
    header: "avg Lot lead time",
    headerKey: "avg_lot_lead_time",
    value: "0",
  },
];
