import React, { useEffect, useState } from 'react';
import TabHeader from './tab-header';
import TabContent from './tab-content';
import classNames from 'classnames';
import cls from './tabs.module.scss';

export interface Tab {
  /**
   * Object containing the attributes for rendering a tab
   */
  header: {
    /**
     * Text label to be displayed in the tab
     */
    label: string | JSX.Element;
    /**
     * Click handler which fires onClick and includes the tab index as an argument
     */
    onClick?: (index: number) => void;
  };
  /**
   * JSX element / Component to be rendered in the tab's content area
   */
  content: JSX.Element;
}

export interface Props {
  /**
   * Override or extend the styles applied to the component
   */
  className?: string;
  /**
   * Defines the tab-level controls visual styles such as width of tab underline
   */
  tabLevel?: 'section' | 'tile';
  /**
   * Sets which tab index will be selected by default
   */
  defaultTab?: number;
  /**
   * Collection of Tabs to be rendered
   */
  tabs: Tab[];
}

export const Tabs = (props: Props) => {
  const { defaultTab = 0, tabLevel = 'section', tabs, className } = props;
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const rcn = cls['ops-tabs-container'];
  const containerHeaderClass = cls['ops-tabs-container-header'];
  const containerLevelClass = cls[`ops-tabs-container-header--${tabLevel}`];
  const handleClick = (index: number) => {
    setCurrentTab(index);

    const tabOnClick = tabs[index].header.onClick;
    if (tabOnClick) {
      tabOnClick(index);
    }
  };

  const containerClassName = classNames([rcn, className]);
  useEffect(() => {
    setCurrentTab(defaultTab)
  }, [defaultTab])
  return (
    <div className={containerClassName}>
      <ul
        className={`${containerHeaderClass} ${containerLevelClass} ops-p-0 ops-m-0 d-flex flex-nowrap`}
      >
        {tabs
          .map(tab => tab.header)
          .map((header, index) => (
            <TabHeader
              key={index.toString()}
              {...{
                ...header,
                index,
                onClick: handleClick,
                isActive: currentTab === index,
              }}
            />
          ))}
      </ul>
      <TabContent>{tabs[currentTab].content}</TabContent>
    </div>
  );
};

Tabs.TabContent = TabContent;
Tabs.Tabheader = TabHeader;

export default Tabs;
