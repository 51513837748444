import AncSolutionPrepProgressContainer from "./sol-prep-progress/container.anc";
import AncSolutionPrepUpcomingNextContainer from "./sol-prep-upcoming/container.anc";
import AncSolutionPrepUpcomingLastContainer from "./sol-prep-last-24-hours/container.anc";
import AncTitleSegmentHeader from "reusable/anc-title-segment-header";
import "./index.scss";

interface SolutionPrepProps{
  inprogressRef:any
  completedRef:any
  upcomingRef:any
}
const SolutionPrepPage = ({inprogressRef,completedRef,upcomingRef}:SolutionPrepProps) => {
  return (
    <>
      <div className="ops-fs-2 anc-solution-prep-container">
        <div className="d-flex align-items-center anc-top-progress-container ops-font-arial">
          <AncTitleSegmentHeader title={"In Progress"}  />
        </div>
        <AncSolutionPrepProgressContainer ref={inprogressRef} id="anc-in-progress-sol" />
        <div className="d-flex align-items-center anc-next-progress-container ops-font-arial">
          <AncTitleSegmentHeader title={"Upcoming Next 48 Hours"}  />
        </div>
        <AncSolutionPrepUpcomingNextContainer ref={upcomingRef} id="anc-upcoming-48-hours-sol"/>
        <div className="d-flex align-items-center anc-last-progress-container ops-font-arial">
          <AncTitleSegmentHeader title={"Completed in Last 48 Hours"}  />
        </div>
        <AncSolutionPrepUpcomingLastContainer ref={completedRef} id = "anc-completed-48-hours-sol"/>
      </div>
    </>
  );
};
export default SolutionPrepPage;
