import { MetricCard, Modal, Tabs } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import ProductionTab from "./production-tab";

import NonCommTabContainer from "./non-commercial-activity-tab/container.anc";
import ProductionTabContainer from "./production-tab/container.anc";

interface UpcomingScheduleModalInterface {
  opened: boolean;
  close: () => void;
  title: string;
}
const UpcomingScheduleModal: React.FC<UpcomingScheduleModalInterface> = ({
  opened,
  close,
  title,
}) => {
  return (
    <Modal
      className={"anc-upcoming-schedule-modal"}
      opened={opened}
      onClose={() => {
        close();
      }}
    >
      <Modal.Header contentClassName="d-flex anc-upcoming-schedule-modal-header align-items-center">
        <Modal.Title title={title} className="anc-modal-title" />
      </Modal.Header>
      <MetricCard.Content>
        <div className="d-flex aoh-flex-column anc-upcoming-schedule-modal-content ">
          <Tabs
            className='anc-upcoming-schedule-modal-tabs'
            tabs={[
              {
                header: {
                  label: "Production",
                },
                content: <ProductionTabContainer />,
              },
              {
                header: {
                  label: "Non-Commercial Activity",
                },
                content: <NonCommTabContainer />,
              },
            ]}
          />
        </div>
      </MetricCard.Content>
    </Modal>
  );
};
export default UpcomingScheduleModal;
