interface Props {
  fill: string;
  classname?: string;
  height?: number;
  stroke?:string ;
  width ? : number;
  id?: string 
}

const CircleIcon = ({ fill, classname, height, stroke ,width,id }: Props) => {
  return (
    <svg
      id={id}
      width={width ?? 8}
      height={height ?? 8}
      viewBox="0 0 8 8"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="3.5" stroke={stroke} strokeWidth={"1"} fill={fill}/>
    </svg>
  );
};

export default CircleIcon;
