/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  MetricCard,
} from "@gitlab-rtsensing/component-library";
import { type Alert } from "@gitlab-rtsensing/component-library/lib/components/types";
import React from "react";
import "./index.scss";
interface LotsCardProps {
  children: React.ReactNode;
  header?: string;
  headerStatusColor?: Alert;
  isToolTip?: boolean;
  isStatus?: boolean;
  tooltipContent?: string;
  className?: string;
}

const AncTabCardComponent: React.FC<LotsCardProps> = (props) => {
  return (
    <MetricCard className={`anc-metric-card--min-height anc-pd-0 ${props.className}`}>
      <>
        <MetricCard.Content className=" d-flex anc-metric-card-content ">
          <>{props.children}</>
        </MetricCard.Content>
      </>
    </MetricCard>
  );
};
export default AncTabCardComponent;
