import { useQuery } from "react-query";
import SolutionPrepProgress from ".";
import { getSolutionPrepContainerApi } from "api/sol-prep-container-api";
import { useParams } from "react-router-dom";
interface AncSolutionPrepProgressContaineProps{
  ref: any;
  id:string;
}
const AncSolutionPrepProgressContainer = ({id,ref}:AncSolutionPrepProgressContaineProps) => {
  const { site } = useParams();
  const {
    isLoading: inProgressIsLoading,
    isError: inProgressIsError,
    data: inProgressData,
  } = useQuery(
    ["getSolutionPrepContainer-Api",site],
    getSolutionPrepContainerApi.bind(this, {
      type: "inprogress",
      status: "string",
      mfg_stage: "string",
      site: site as string,
      building: "string",
      line_no: "string",
    })
  );
  
  return (
    <SolutionPrepProgress
      isLoading={inProgressIsLoading}
      isError={inProgressIsError}
      inProgressData={inProgressData?.data?.data}
      id={id}
      ref={ref}
    />
  );
};
export default AncSolutionPrepProgressContainer;
