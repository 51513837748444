import ThawIncubatorContainer from "components/thaw-inoculation-tab/thaw-incubator-container";
import "./index.scss";
import ErrorHandler from "utils/error-handler";

import {
  IncubatorDetailsInterface,
  ProcessViewDataInterface,
} from "assets/types/thaw-inoculation-process-view.types";
import TileWrapper from "reusable/anc-tile-wrapper";
import APIErrorMessage from "utils/api-error";

interface ThawInoculationProcessViewInterface {
  isLoading: boolean;
  isError: boolean;
  processViewData: ProcessViewDataInterface;
}

const ThawInoculationProcessView: React.FC<
  ThawInoculationProcessViewInterface
> = ({ isLoading, isError, processViewData }) => {
    
  return (
    <>
    
        <ErrorHandler
          isLoading={isLoading}
          isError={isError}
          loaderColor="#ffff"
        />
      
      {!isLoading && !isError && processViewData?.incubator_details?.length > 0 ? (
        <div className="anc-d-flex anc-thaw-incubator-wrapper">
          {processViewData?.incubator_details?.map(
            (incubatorItem: IncubatorDetailsInterface) => {
              return (
                <TileWrapper key={incubatorItem.id}>
                  <ThawIncubatorContainer incubatorData={incubatorItem} />
                </TileWrapper>
              );
            }
          )}
        </div>
      ):<APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>}
    </>
  );
};
export default ThawInoculationProcessView;
