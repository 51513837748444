import AncHeaderText from "reusable/anc-table/anc-header-text";
import TableBody from "reusable/anc-table/table-body";
import TableDescriptionComponent from "reusable/anc-table/table-description-component";
import TableHead from "reusable/anc-table/table-head";
import TableHeaderComponent from "reusable/anc-table/table-header-Component";
import TableHeaderRowComponent from "reusable/anc-table/table-header-row-component";
import AncTabTableComponent from "reusable/anc-table/table-wrapper";
import TableRowComponent from "reusable/anc-table/table-row-component";
import ErrorHandler from "utils/error-handler";
import { useState } from "react";
import { thawInoculationHeaderData } from "constants/thaw-inoc-constants/thaw-inoc-header";
import { ThawInoculationTableDataObjProps } from "assets/types/thaw-inoc-table-types";
import TableDescriptionRender from "reusable/anc-table/table-description-render/table-description";
import ThawInoculationModal from "components/thaw-inoculation-tab/thaw-inoculation-modal";
import {
  TableModalData,
} from "assets/types/thaw-modal-type";
import { AlarmType } from "types/bioreactor-trains-type";
import EmptyTableDataComponent from "reusable/anc-empty-table-data-component";
interface ThawInoculationIncubatorType {
  isLoading: boolean;
  isError: boolean;
  incubatorName:string;
  alarmData:AlarmType[];
  incubatorData: ThawInoculationTableDataObjProps[] | any;
}

const ThawInoculationIncubator: React.FC<ThawInoculationIncubatorType> = ({
  isLoading,
  isError,
  incubatorData,
  incubatorName,
  alarmData
}) => {
  const [incubatorHeaderData, setIncubatorHeaderData] = useState(
    thawInoculationHeaderData
  );

  const [isViewMoreOpen, setIsViewMoreOpen] = useState<boolean>(false);
 
  const [modalRow, setModalRow] = useState<TableModalData>({
    color_code: "",
    culture_time_max: "",
    culture_time_min: "",
    desc: "",
    name: "",
    stage: "",
    next_equipment: "",
    recipes: "",
    running_status: "",
    step_process_progress: "",
    unit_op_progress: "",
  });
  let sortedData = incubatorData;

  return (
    <>
      <div className="anc-table-error-handler anc-align-items-center">
        <ErrorHandler
          isLoading={isLoading}
          isError={isError}
          loaderColor="#ffff"
        />
      </div>

        <>
          <AncTabTableComponent>
            <TableHead>
              <TableHeaderRowComponent>
                {incubatorHeaderData?.map((item, index) => {
                  return (
                    <TableHeaderComponent key={index} align={item.align}>
                      <AncHeaderText
                        sortActive={item.sortActive}
                        textValue={item.header}
                        sortKey={item.sortKey}
                        alignText={item.align}
                        columnNameVisible={item.columnNameVisible}
                        sortFn={() => {
                          const updatedItems = [...incubatorHeaderData];
                          const indexFind = updatedItems.findIndex(
                            (item) => item.sortActive
                          );
                          updatedItems[index].sortKey =
                            !updatedItems[index].sortKey;
                          updatedItems[indexFind].sortActive = false;
                          updatedItems[index].sortActive = true;
                          setIncubatorHeaderData(updatedItems);
                          sortedData = incubatorData.sort(
                            item.sortfn.bind(item) as (
                              a: ThawInoculationTableDataObjProps,
                              b: ThawInoculationTableDataObjProps
                            ) => number
                          );
                        }}
                      />
                    </TableHeaderComponent>
                  );
                })}
              </TableHeaderRowComponent>
            </TableHead>

            <TableBody>
              {sortedData.map(
                (row: ThawInoculationTableDataObjProps, i: number) => (
                  <TableRowComponent key={i}>
                    {thawInoculationHeaderData.map((item, index) => {
                      return (
                        <TableDescriptionComponent
                          key={index}
                          align={item.align}
                        >
                          <TableDescriptionRender
                            columnComponentType={item.columnComponentType}
                            headerDataKey={item.datakey}
                            row={row}
                            buttonCallback={(state) => {
                              setIsViewMoreOpen(true);
                              setModalRow({
                                color_code: row.color_code,
                                culture_time_max: row.culture_time_max,
                                culture_time_min: row.culture_time_min,
                                desc: row.desc,
                                name: row.name,
                                stage: row.stage,
                                next_equipment: row.next_equipment,
                                recipes: row.recipes,
                                running_status: row.running_status,
                                step_process_progress:
                                row.step_process_progress,
                                unit_op_progress: row.unit_op_progress,
                              });
                            }}
                          />
                        </TableDescriptionComponent>
                      );
                    })}
                  </TableRowComponent>
                )
              )}
            </TableBody>
          </AncTabTableComponent>

          <ThawInoculationModal
            alarms={alarmData?.length ?? 0}
            title={incubatorName}
            opened={isViewMoreOpen}
            close={() => {
              setIsViewMoreOpen(false);
            }}
            incubatorName={incubatorData[0].name ?? "N/A"}
            alarmsDataArray={alarmData}
            isTable={true}
            materialLots={[]}
            tableLot={modalRow}
          />
        </>
    
    </>
  );
};
export default ThawInoculationIncubator;
