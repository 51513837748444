import { getRefreshData } from "api/refresh-api";
import ErrorHandler from "utils/error-handler";
import { useQuery } from "react-query";
import RefreshTag from "reusable/anc-refresh-tag";
import { useParams } from "react-router-dom";
import APIErrorMessage from "utils/api-error";

const RefreshE2E = () => {
const { site } = useParams();
    const {
        data: refreshData,
        isLoading: isRefreshLoading,
        isError: isRefreshError,
    } = useQuery({
        queryKey: ["getRefreshData", site],
        queryFn: getRefreshData.bind(this, {
            mfg_stage: "string",
            site: site as string,
            building: "string",
            line_no: "string",
            curr_stage: "string"
        }),
    });

    return (
        <div className="anc-data-refresh-tag">
            <ErrorHandler
                isLoading={isRefreshLoading}
                isError={isRefreshError}
                loaderColor="#ffff"
            />
            {!isRefreshLoading && !isRefreshError && refreshData?.data?.data?.e2e_process_view_refresh_date ? (
  
            <RefreshTag data={refreshData?.data?.data?.e2e_process_view_refresh_date} />
            ):!isRefreshLoading &&
            !isRefreshError &&
            (!refreshData?.data?.data?.e2e_process_view_refresh_date || refreshData?.data?.data?.e2e_process_view_refresh_date?.length === 0) ?<APIErrorMessage apierror={"No Data Available"}></APIErrorMessage>:<></>}
        </div>
    );
}

export default RefreshE2E;