import VectorUpIcon from "assets/icons/vector-up-icon";
import VectorDownIcon from "assets/icons/vector-down-icon";
import "./index.scss";

interface AccordionHeaderType {
  children: React.ReactNode;
  opened: boolean;
  toggle: (isOpen: boolean) => void;
}
const AccordionHeader: React.FC<AccordionHeaderType> = ({
  children,
  opened,
  toggle,
}) => {
  return (
    <div
      className="anc-accordion-header"
      onClick={() => {
        toggle(!opened);
      }}
    >
      <div className="anc-accordion-header-text">{children}</div>
      <div className="anc-accordion-header-icon">
        {!opened && <VectorUpIcon fill="#585858" />}
        {opened && <VectorDownIcon fill="#585858" />}
      </div>
    </div>
  );
};
export default AccordionHeader;
