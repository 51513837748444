import { MetricCard } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import AncMetricTag from "reusable/anc-metric-tag";
import { getMachineImage } from "utils/common-methods";
import { Tooltip } from "react-tooltip";
import LotsTooltipComponent from "reusable/anc-lots-tooltip";

interface AncMetricCardProps {
  machineStatus?: string;
  machineScheduleStatus?: string;
  machinePhase?: string;
  machineTitle?: string;
  className?: string;
  image?: string;
  statusColor?: string;
  runningColor?: string;
  activealarms:string;
  materialdesc: string;
  batchno:string;
  machinestatus:string;
  receipeduration:string;
  recipe:string;
  running:string;
  unitstatus:string;
  tooltipIndex:string,
}
const AncMetricCard: React.FC<AncMetricCardProps> = ({
  machineStatus,
  machineScheduleStatus,
  machinePhase,
  machineTitle,
  className,
  image,
  statusColor,
  runningColor,
  activealarms,
  materialdesc,
  batchno,
  machinestatus,
  receipeduration,
  recipe,
  running,
  unitstatus,
  tooltipIndex,
}) => {
  const isIdle = machineStatus === "Idle";
  return (
    <div className={`${className} anc-metric-card`}>
      <MetricCard
        className={`anc-card anc-${statusColor?.toLowerCase()} ${
          isIdle ? "anc-idle-card" : ""
        } anc-px-2 anc-pb-2 anc-pt-0 `}
      >
        <div>
          <div
            className={`anc-d-flex 
               anc-justify-content-between anc-align-items-center
            `}
          >
            <div className="anc-pt-2 anc-pb-1">
              <div className="anc-d-flex">
                {machineStatus && (
                  <div className="anc-machine-status ops-fw-bold ops-fs-5 ops-lh-3">
                    {machineStatus}
                  </div>
                )}

                {machineScheduleStatus && (
                  <div
                    className={`anc-machine-schedule-status anc-running-status-${runningColor?.toLowerCase()} ops-fw-bold ops-fs-6 ops-lh-4 anc-text-uppercase anc-pl-2`}
                  >
                    {machineScheduleStatus.toUpperCase() === "ON SCHEDULE"
                      ? "ON SCHED."
                      : machineScheduleStatus}
                  </div>
                )}
              </div>
              {machinePhase && (
                <div className="anc-card-machine-phase ops-fw-medium ops-fs-5 ops-lh-3">
                  {machinePhase}
                </div>
              )}
            </div>

            {getMachineImage(image ?? "")}
            
          </div>
          {machineTitle && (
            <>
              <div id={`anc-float-data-anchor${tooltipIndex}`}>
                <AncMetricTag machineTitle={machineTitle} />
              </div>

              <Tooltip
                noArrow={true}
                float
                isOpen={false}
                className={"anc-tag-tooltip"}
                render={() => {
                  return (
                    <LotsTooltipComponent activealarms={activealarms} materialdesc={materialdesc} batchno={batchno} machinestatus={machinestatus} receipeduration={machinestatus} recipe={recipe} running={running} unitstatus={unitstatus}  />
                  );
                }}
                style={{
                  backgroundColor: "white",
                  padding: "0px",
                  zIndex: 1000,
                }}
                anchorSelect={`#anc-float-data-anchor${tooltipIndex}`}
              ></Tooltip>
            </>
          )}
        </div>
      </MetricCard>
    </div>
  );
};
export default AncMetricCard;
