const processTabArray = [
  {
    tabLabel: "Process View",
  },
  {
    tabLabel: "Table View",
  },
];

export default processTabArray;
