import { thawInoculationHeaderInterface } from "assets/types/thaw-inoc-header-types";
import "./index.scss";
import DangerIcon from "assets/icons/danger-icon";

interface AncMachineAlarmComponentInterface {
  headerKey: string;
  headerLabel: string;
  headerIcon?: boolean;
  headerData: thawInoculationHeaderInterface;
}

const AncMachineAlarmComponent = (props: AncMachineAlarmComponentInterface) => {
  const { headerKey, headerLabel, headerData, headerIcon } = props;
  return (
    <div className="anc-machine-alarm-container">
      <div className="anc-machine-alarm-header ops-fs-6 anc-text-uppercase ops-fw-bold">
        {headerLabel ?? "N/A"}
      </div>
      <div className="d-flex anc-machine-alarm-value-container">
        {headerData && (
          <div className="anc-machine-alarm-value ops-fs-4 ops-fw-bold">
            {headerData[headerKey] ?? "N/A"}
          </div>
        )}
        {headerIcon && (
          <div className="anc-machine-alarm-icon">{<DangerIcon />}</div>
        )}
      </div>
    </div>
  );
};
export default AncMachineAlarmComponent;
