import "./index.scss";

interface TableBodyProps {
  children: React.ReactNode;
}
const TableBody: React.FC<TableBodyProps> = ({
  children,
}) => {
  return <tbody className="anc-tab-table-body">{children}</tbody>;
};
export default TableBody;
