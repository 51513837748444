import { MetricCard, Modal } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import ViewMoreContent from "./view-more-content";
import { SolutionPrepTableDataObjProps } from "assets/types/anc-sol-prep-table-types";

interface ViewMoreModalInterface {
  opened: boolean;
  close: () => void;
  title: string | number | undefined;
  modalData: SolutionPrepTableDataObjProps;
}
const SolutionPrepModal: React.FC<ViewMoreModalInterface > = ({
  opened,
  close,
  title,
  modalData,
}) => {
  return (
    <Modal
      className={"anc-view-more-modal"}
      opened={opened}
      onClose={() => {
        close();
      }}
    >
      <Modal.Header contentClassName="d-flex anc-view-more-modal-header align-items-center">
        <Modal.Title
          title={title as string}
          className="anc-modal-title"
        />
      </Modal.Header>
      <MetricCard.Content>
        <ViewMoreContent
        processLot={modalData.process_lot}
          actualStart={modalData.actual_start}
          lot={modalData.lot}
          material={modalData.material}
          materialDescription={modalData.material_description}
          progressValue={modalData.progress_value}
          remainingTime={modalData.remaining_time}
          schedule={modalData.schedule}
          scheduleColorCode={modalData.schedule_color_code}
          solutionDescription={modalData.solution_description }
          solutionLot={modalData.solution_lot}
          targetStart={modalData.target_start}
        />
      </MetricCard.Content>
    </Modal>
  );
};
export default SolutionPrepModal;
