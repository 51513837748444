/* eslint-disable n/no-callback-literal */
import { useState } from "react"
import UpcomingScheduleModal from "../upcoming-schedule-modal"
import { prependOnceListener } from "process"
import './index.scss';
import { ExternalLinkIcon } from "@gitlab-rtsensing/component-library";

interface UpcomingLotsButtonType {
    title: string
    icon: string
    cb: (state: boolean) => void
}

const UpcomingLotsButton: React.FC<UpcomingLotsButtonType> = (props) => {
    const {title, icon, cb} = props;

    return (
        <>
            <div
                onClick={() => cb(true)}
                className="anc-button anc-d-flex ops-fw-bold ops-fs-6 anc-mb-1 anc-mt-1"
            >
                {title}
                {icon==='link-external' 
                ? <ExternalLinkIcon/>
                : <></>}
            </div>
            
        </>
    )
}

export default UpcomingLotsButton;