import HorizontalLabels from "reusable/horizontal-labels";
import { Tooltip } from "react-tooltip";
import "./index.scss";
import { getLegendTooltip } from "utils/common-methods";

interface labelProps {
  label: string;
  labelColor: string;
  labelImageType: string;
}

interface labelContentProps {
  in_use: string;
  advisory: string;
  critical: string;
  planned_downtime: string;
  idle: string;
  machine_down?: string;
}

interface AncLegendsGridProps {
  labelData: labelProps[];
  labelContent: labelContentProps;
}

const AncLegendsGrid: React.FC<AncLegendsGridProps> = (
  props: AncLegendsGridProps
) => {
  return (
    <div className="anc-d-flex anc-label-horizontal-container">
      {props.labelData?.map((item, index) => {
        return (
        <>
          <Tooltip
          float
        isOpen={false}
            render={() => {
              return (
                <div key={index}>
                  {getLegendTooltip(item, props.labelContent)}
                </div>
              );
            }}
            noArrow={true}
            
            className={"anc-bioreactor-legend-tooltip"}
            style={{ backgroundColor: "white", padding: "0px" }}
            anchorSelect={`#anc-tooltip-legend-anchor${index}`}
          >
          </Tooltip>
          <div id={`anc-tooltip-legend-anchor${index}`} key={index}>
            <HorizontalLabels item={item}/>
          </div>
        </>
        )
      })}
    </div>
  );
};
export default AncLegendsGrid;
