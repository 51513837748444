import React from "react";
import "./index.scss";
import { Tooltip } from "react-tooltip";
import { AlarmType } from "types/bioreactor-trains-type";

interface AncMachineAlarmsTooltipProps {
  id: string;
  alarms: AlarmType[];
}
const AncMachineAlarmsTooltip: React.FC<AncMachineAlarmsTooltipProps> = ({
  id,
  alarms,
}) => {
  return (
    <>
      {alarms.length > 0 && (
        <Tooltip
          noArrow={true}
          float
          opacity={1}
          className={"anc-machine-alarm-tooltip"}
          render={() => {
            return (
              <div className="anc-machine-alarm-tooltip-body">
                <div>
                  <div className="ops-fs-5 ops-fw-semibold ops-mb-1">
                    Current Alarms
                  </div>
                  <div>
                    <table className="alarm-table">
                      <tr>
                        <th className="ops-fs-6 ops-fw-semibold">ALARM</th>
                        <th className="ops-fs-6 ops-fw-semibold">DURATION</th>
                        <th className="ops-fs-6 ops-fw-semibold">TYPE</th>
                      </tr>
                      {alarms.map((alarm, i) => {
                        return (
                          <tr key={`alarm-${i}`}>
                            <td className="ops-fs-6">{alarm.name ?? "N/A"}</td>
                            <td className="ops-fs-6">{alarm.duration ?? "N/A"}</td>
                            <td className="ops-fs-6">{alarm.type ?? "N/A"}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            );
          }}
          style={{
            backgroundColor: "white",
            padding: "0px",
            zIndex: 1000,
          }}
          anchorSelect={`#${id}`}
        ></Tooltip>
      )}
    </>
  );
};
export default AncMachineAlarmsTooltip;
