// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-content_ops-tab-content__8oAw7{width:100%;content:""}`, "",{"version":3,"sources":["webpack://./src/reusable/dct-tab/tab-content/tab-content.module.scss"],"names":[],"mappings":"AACA,oCACI,UAAA,CACA,UAAA","sourcesContent":["\n.ops-tab-content {\n    width: 100%;\n    content: ''; // left empty as reference\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ops-tab-content": `tab-content_ops-tab-content__8oAw7`
};
export default ___CSS_LOADER_EXPORT___;
