import { Button } from "@gitlab-rtsensing/component-library";
import { useState } from "react";
import AncTabTableTextComponent from "reusable/anc-table/anc-table-text";
import "./table-description.scss";
import { SolutionPrepTableDataObjProps } from "assets/types/anc-sol-prep-table-types";
import { ThawInoculationTableDataObjProps } from "assets/types/thaw-inoc-table-types";
import { BioreactorTrainsTableDataObjProps } from "assets/types/bioreactor-trains-table-types";
import CleanProgressBar from "reusable/clean-progress-bar";
import { WeighAndDispenseTableDataObjProps } from "assets/types/anc-weigh-dispense-table-types";

interface TableDescriptionRenderInterface {
  columnComponentType: string;
  headerDataKey: string;
  row: SolutionPrepTableDataObjProps | ThawInoculationTableDataObjProps | BioreactorTrainsTableDataObjProps | WeighAndDispenseTableDataObjProps;
  buttonCallback: (state: boolean) => void
}

const TableDescriptionRender: React.FC<TableDescriptionRenderInterface> = (
  props: TableDescriptionRenderInterface
) => {
  const { columnComponentType, headerDataKey, row, buttonCallback } = props;
  const isIdle = row.status==='Idle';
  
  switch (columnComponentType) {
    case "text":
      return (
        <AncTabTableTextComponent
          textValue={row[headerDataKey]}
          textHeader={headerDataKey}
          textColorValue={row.schedule_adherences_color_code ?? "N/A"}
        />
      );
    case "button":
      return (
        <>
          <div
            onClick={() => {
              buttonCallback(true)
            }}
            className=" 
            anc-view-more-button 
            ops-fs-5"
          >
            <Button label="View More" type="secondary" className="anc-view-more-idle-button"/>
          </div>

        </>
      );
    case "progressbar":
      return <>
      {
        !isIdle
          ? <div className="anc-d-flex align-items-center">
              <CleanProgressBar percentageArray={[{ percentage: row[headerDataKey], flag: "BLUE" }]} />
            </div>
          : <></>
      }
      </>
    default:
      return <></>;
  }
};
export default TableDescriptionRender;
