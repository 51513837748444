import "./index.scss";

interface TimerHeaderValueContentProps {
  headerValue: string;
  dataValue: string;
 
}
const TimerHeaderValueContent = (props: TimerHeaderValueContentProps) => {
  const { headerValue, dataValue } = props;
  return (
    <>
      <div className="ops-fs-6 anc-timer-header anc-text-uppercase ops-fw-bold">
        {headerValue}
      </div>
      <div className="ops-fs-5 anc-timer-data">{dataValue}</div>
    </>
  );
};

export default TimerHeaderValueContent;
