/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import {
  useOktaAuth
} from '@okta/okta-react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Outlet } from 'react-router-dom'

const RequiredAuthComponent: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth()
  useEffect(() => {
    if (!authState) {
      return
    }
    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      )
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }
  },
  [oktaAuth, !!authState, authState?.isAuthenticated])
  if (!authState || !authState?.isAuthenticated) (<div className='anc-okta-loader'>Loader ...</div>)
  return <Outlet />
}

export default RequiredAuthComponent
