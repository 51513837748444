import React, { useState } from "react";
import "./index.scss";
import CustomDropdown from "reusable/dct-custom-dropdown";
import ErrorHandler from "utils/error-handler";
import { NonCommercialTableDataType } from "types/schedule-modal.types";
import APIErrorMessage from "utils/api-error";
interface NonCommercialTabInterface {
  isLoading: boolean;
  isError: boolean;
  data: NonCommercialTableDataType[];
}

const NonCommercialTab: React.FC<NonCommercialTabInterface> = ({
  isLoading,
  isError,
  data: NonCommTabData,
}) => {
  const [statusState, setStatusState] = useState({
    showStatus: "",
    oeeDataValue: "All",
  });
  return (
    <>
      <ErrorHandler
        isLoading={isLoading}
        isError={isError}
        loaderColor="#ffff"
      />
      {!isLoading && !isError && (
        <div className="anc-non-comm-tab">
          <div className="anc-non-comm-table-modal anc-pb-4">
            <table className="anc-w-100">
              <thead className="anc-non-comm-table-header-container">
                <tr>
                  <th className="anc-non-comm-table-header-1 anc-text-uppercase ops-fs-6 ops-fw-semibold">
                    Target Start
                  </th>
                  <th className="anc-non-comm-table-header-3 anc-text-uppercase ops-fs-6 ops-fw-semibold ">
                    Task Name
                  </th>
                  <th className="anc-non-comm-table-header-5 anc-text-uppercase ops-fs-6 ops-fw-semibold anc-pr-3">
                    Unit Op
                  </th>
                </tr>
              </thead>
              <tbody className="anc-non-comm-table-body-container">
                {NonCommTabData.length > 0 ?(NonCommTabData?.map(
                  (row: NonCommercialTableDataType, index: number) => (
                    <tr key={index}>
                      <td className="anc-non-comm-table-data-1  ops-fs-5 ">
                        {row.target_date ?? "N/A"}
                      </td>
                      <td className="anc-non-comm-table-data-3  ops-fs-5 ">
                        {row?.task_name ?? "N/A"}
                      </td>
                      <td className="anc-non-comm-table-data-5 ops-fs-5 anc-pr-3">
                        {row?.unit_op ?? "N/A"}
                      </td>
                    </tr>
                  )
                )):(<td colSpan={3}><APIErrorMessage apierror={"No Data Available"}></APIErrorMessage></td>)}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default NonCommercialTab;
