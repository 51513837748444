import { ReactElement } from "react";
import "./index.scss";

interface AccordionType {
    children: React.ReactNode;
}
const Accordion: React.FC<AccordionType> = ({
  children
}) => {
    return (
        <div className="anc-accordion-container ops-mb-2 ops-pb-2">
            {children}
        </div>
    );
};
export default Accordion;
