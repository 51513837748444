interface Props {
  className?: string;
  iconActive?: boolean;
}

const PathwayIcon = ({ className, iconActive }: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fillOpacity={1}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!iconActive ? (
        <path
          d="M9.25 7.625C8.91745 7.62531 8.5944 7.73592 8.33144 7.9395C8.06848 8.14308 7.88048 8.42813 7.79688 8.75H3.25C2.85218 8.75 2.47064 8.59196 2.18934 8.31066C1.90804 8.02936 1.75 7.64782 1.75 7.25C1.75 6.85218 1.90804 6.47064 2.18934 6.18934C2.47064 5.90804 2.85218 5.75 3.25 5.75H7.75C8.24728 5.75 8.72419 5.55246 9.07582 5.20083C9.42746 4.84919 9.625 4.37228 9.625 3.875C9.625 3.37772 9.42746 2.90081 9.07582 2.54917C8.72419 2.19754 8.24728 2 7.75 2H3.25C3.15054 2 3.05516 2.03951 2.98484 2.10984C2.91451 2.18016 2.875 2.27554 2.875 2.375C2.875 2.47446 2.91451 2.56984 2.98484 2.64016C3.05516 2.71049 3.15054 2.75 3.25 2.75H7.75C8.04837 2.75 8.33452 2.86853 8.5455 3.0795C8.75647 3.29048 8.875 3.57663 8.875 3.875C8.875 4.17337 8.75647 4.45952 8.5455 4.6705C8.33452 4.88147 8.04837 5 7.75 5H3.25C2.65326 5 2.08097 5.23705 1.65901 5.65901C1.23705 6.08097 1 6.65326 1 7.25C1 7.84674 1.23705 8.41903 1.65901 8.84099C2.08097 9.26295 2.65326 9.5 3.25 9.5H7.79688C7.867 9.77161 8.01189 10.0181 8.21508 10.2115C8.41828 10.4049 8.67163 10.5375 8.94637 10.5941C9.22111 10.6507 9.50623 10.6292 9.76934 10.5319C10.0325 10.4347 10.263 10.2656 10.4349 10.0439C10.6067 9.82213 10.7129 9.55666 10.7415 9.2776C10.77 8.99854 10.7197 8.71706 10.5963 8.46515C10.4729 8.21323 10.2813 8.00096 10.0434 7.85245C9.80538 7.70394 9.53052 7.62514 9.25 7.625ZM9.25 9.875C9.10166 9.875 8.95666 9.83101 8.83332 9.7486C8.70999 9.66619 8.61386 9.54906 8.55709 9.41201C8.50032 9.27497 8.48547 9.12417 8.51441 8.97868C8.54335 8.8332 8.61478 8.69956 8.71967 8.59467C8.82456 8.48978 8.9582 8.41835 9.10368 8.38941C9.24917 8.36047 9.39997 8.37532 9.53701 8.43209C9.67406 8.48886 9.79119 8.58499 9.8736 8.70832C9.95601 8.83166 10 8.97666 10 9.125C10 9.32391 9.92098 9.51468 9.78033 9.65533C9.63968 9.79598 9.44891 9.875 9.25 9.875Z"
          fill="#0063C3"
        />
      ) : (
        <path
          d="M9.25 7.625C8.91745 7.62531 8.5944 7.73592 8.33144 7.9395C8.06848 8.14308 7.88048 8.42813 7.79688 8.75H3.25C2.85218 8.75 2.47064 8.59196 2.18934 8.31066C1.90804 8.02936 1.75 7.64782 1.75 7.25C1.75 6.85218 1.90804 6.47064 2.18934 6.18934C2.47064 5.90804 2.85218 5.75 3.25 5.75H7.75C8.24728 5.75 8.72419 5.55246 9.07582 5.20083C9.42746 4.84919 9.625 4.37228 9.625 3.875C9.625 3.37772 9.42746 2.90081 9.07582 2.54917C8.72419 2.19754 8.24728 2 7.75 2H3.25C3.15054 2 3.05516 2.03951 2.98484 2.10984C2.91451 2.18016 2.875 2.27554 2.875 2.375C2.875 2.47446 2.91451 2.56984 2.98484 2.64016C3.05516 2.71049 3.15054 2.75 3.25 2.75H7.75C8.04837 2.75 8.33452 2.86853 8.5455 3.0795C8.75647 3.29048 8.875 3.57663 8.875 3.875C8.875 4.17337 8.75647 4.45952 8.5455 4.6705C8.33452 4.88147 8.04837 5 7.75 5H3.25C2.65326 5 2.08097 5.23705 1.65901 5.65901C1.23705 6.08097 1 6.65326 1 7.25C1 7.84674 1.23705 8.41903 1.65901 8.84099C2.08097 9.26295 2.65326 9.5 3.25 9.5H7.79688C7.867 9.77161 8.01189 10.0181 8.21508 10.2115C8.41828 10.4049 8.67163 10.5375 8.94637 10.5941C9.22111 10.6507 9.50623 10.6292 9.76934 10.5319C10.0325 10.4347 10.263 10.2656 10.4349 10.0439C10.6067 9.82213 10.7129 9.55666 10.7415 9.2776C10.77 8.99854 10.7197 8.71706 10.5963 8.46515C10.4729 8.21323 10.2813 8.00096 10.0434 7.85245C9.80538 7.70394 9.53052 7.62514 9.25 7.625ZM9.25 9.875C9.10166 9.875 8.95666 9.83101 8.83332 9.7486C8.70999 9.66619 8.61386 9.54906 8.55709 9.41201C8.50032 9.27497 8.48547 9.12417 8.51441 8.97868C8.54335 8.8332 8.61478 8.69956 8.71967 8.59467C8.82456 8.48978 8.9582 8.41835 9.10368 8.38941C9.24917 8.36047 9.39997 8.37532 9.53701 8.43209C9.67406 8.48886 9.79119 8.58499 9.8736 8.70832C9.95601 8.83166 10 8.97666 10 9.125C10 9.32391 9.92098 9.51468 9.78033 9.65533C9.63968 9.79598 9.44891 9.875 9.25 9.875Z"
          fill="white"
        />
      )}
    </svg>
  );
};

export default PathwayIcon;
