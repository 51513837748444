import HorizontalLabels from "reusable/horizontal-labels";
import "../../assets/bootstrap.scss";
import "./index.scss";

interface TitleProps {
  title: string;
  height?: string;
  weight?: string;
}

const AncTitleSegmentHeader = (props: TitleProps) => {
  const { title, height, weight } = props;

  return (
    <div
      className={
        (height ? `${height}` : `ops-fs-3 `) +
        "anc-table-title " +
        "ops-font-arial"+ " anc-d-flex"
      }
    >
      <div> {title ?? ""}</div>
    </div>
  );
};

export default AncTitleSegmentHeader;
