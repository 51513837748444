import { BioreactorTrainsTableDataObjProps } from "assets/types/bioreactor-trains-table-types";
import moment from "moment";

export const bioreactorTrainsHeaderData = [
  {
    datakey: "status",
    align: "left",
    key: 1,
    sortKey: false,
    sortfn: function (
      a: BioreactorTrainsTableDataObjProps,
      b: BioreactorTrainsTableDataObjProps
    ): number {
      if (this.sortKey) {
        return a.status > b.status ? 1 : -1;
      } else {
        return b.status > a.status ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Status",
    sortActive: true
  },
  {
    datakey: "equipment_id",
    align: "left",
    key: 2,
    sortKey: false,
    sortfn: function (
      a: BioreactorTrainsTableDataObjProps,
      b: BioreactorTrainsTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.equipment_id > b.equipment_id ? 1 : -1;
      } else {
        return b.equipment_id > a.equipment_id ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Equipment ID",
    sortActive: false
  },
  {
    datakey: "material",
    align: "left",
    key: 3,
    sortKey: false,
    sortfn: function (
      a: BioreactorTrainsTableDataObjProps,
      b: BioreactorTrainsTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.material > b.material ? 1 : -1;
      } else {
        return b.material > a.material ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Material #",
    sortActive: false
  },
  {
    datakey: "lot_id",
    align: "right",
    key: 4,
    sortKey: false,
    sortfn: function (
      a: BioreactorTrainsTableDataObjProps,
      b: BioreactorTrainsTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.lot_id > b.lot_id ? 1 : -1;
      } else {
        return b.lot_id > a.lot_id ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Lot #",
    sortActive: false
  },
  {
    datakey: "step_progress",
    align: "left",
    key: 5,
    sortKey: false,
    sortfn: function (
      a: BioreactorTrainsTableDataObjProps,
      b: BioreactorTrainsTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.step_process_progress > b.step_process_progress ? 1 : -1;
      } else {
        return b.step_process_progress > a.step_process_progress ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "progressbar",
    header: "Step Progress",
    sortActive: false
  },
  {
    datakey: "process_step",
    align: "left",
    key: 6,
    sortKey: false,
    sortfn: function (
      a: BioreactorTrainsTableDataObjProps,
      b: BioreactorTrainsTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.process_step > b.process_step ? 1 : -1;
      } else {
        return b.process_step > a.process_step ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Process Step",
    sortActive: false
  },
  {
    datakey: "schedule",
    align: "left",
    key: 7,
    sortKey: false,
    sortfn: function (
      a: BioreactorTrainsTableDataObjProps,
      b: BioreactorTrainsTableDataObjProps
    ) {
      if (this.sortKey) {
        return a.schedule_adherences > b.schedule_adherences ? 1 : -1;
      } else {
        return b.schedule_adherences > a.schedule_adherences ? 1 : -1;
      }
    },
    columnNameVisible: true,
    columnComponentType: "text",
    header: "Schedule",
    sortActive: false
  },
  {
    datakey: "header8",
    sortfn: () => {},
    align: "right",
    key: 8,
    sortKey: false,
    columnNameVisible: false,
    columnComponentType: "button",
    header: "View More",
    sortActive: false
  },
];
