import { useQuery } from "react-query"
import ThawInoculation from "."
import { getthawInoculationApi } from "api/thawInoculation-api"
import { useParams } from "react-router-dom";

const ThawInoculationContainer = () => {
    const { site } = useParams();
    const {isLoading, isError, data: WeighExpenseData} = useQuery(['getThawInoculation-Api', site],getthawInoculationApi.bind(this,{
        mfg_stage: "string",
        site: site as string,
        building: "string",
        line_no: "string",
        curr_stage: "string"
    }))
    return (
        <ThawInoculation 
            isLoading={isLoading} 
            isError={isError} 
            data={WeighExpenseData?.data?.data} 
        />
    )
}
export default ThawInoculationContainer;
