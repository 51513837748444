import React from "react";
import { BatchDataResponse } from "../../../assets/types/get-batch-information";
import './index.scss'

const StatusAndHeader: React.FC<BatchDataResponse> = (
  props: BatchDataResponse
) => {
  const { title } = props;
  return (
    <div className="aoh-d-flex anc-line-tab-data">

      <div className="d-flex align-items-center aoh-mr-2 ops-fw-bold aoh-pl-1 aoh-pr-2 ops-fs-5">
        {title}
      </div>
    </div>
  );
};
export default StatusAndHeader;