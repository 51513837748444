import AexColumnImage from "assets/machine-images/anc-aex-column-3";
import BDSFill from "assets/machine-images/anc-bds-fill";
import BioreactorImage from "assets/machine-images/anc-bioreactor-image";
import BioreactorATFImage from "assets/machine-images/anc-bioreactor-atf-image";
import BioreactorATF2000Image from "assets/machine-images/anc-bioreactor-atf-2000-image";
import CentrifugeImage from "assets/machine-images/anc-centrifuge";
import CexColumnImage from "assets/machine-images/anc-cex-column";
import FiltrationImage from "assets/machine-images/anc-filtration-skid";
import HoldTankImage from "assets/machine-images/anc-hold-tank";
import LowPhViralImage from "assets/machine-images/anc-low-ph-viral";
import NeutralizationImage from "assets/machine-images/anc-neutralization-tank";
import PolySorbateImage from "assets/machine-images/anc-polysorbate-addition";
import ProAColumnImage from "assets/machine-images/anc-pro-a-column";
import SolutionPrepImage from "assets/machine-images/anc-solution-prep-image";
import UfDfImage from "assets/machine-images/anc-uf-df";
import ViralFiltrationImage from "assets/machine-images/anc-viral-filtration";
import WeighDispenseImage from "assets/machine-images/anc-weigh-and-dispese-image";
import UpcomingLotsButton from "components/upcoming-lots-container/upcoming-lots-button";
import UpcomingLotsContent from "components/upcoming-lots-container/upcoming-lots-content";
import LegendTooltip from "reusable/anc-legends-tooltip";
import { useParams } from "react-router-dom";

interface labelProps {
  label: string;
  labelColor: string;
  labelImageType: string;
}

interface labelContentProps {
  in_use: string;
  advisory: string;
  critical: string;
  planned_downtime: string;
  idle: string;
  machine_down?: string;
}

export const getMachineImage: (status: string) => JSX.Element = (
  status: string
) => {
  switch (status) {
    case "wave_bioreactor":
      return (
        <BioreactorImage
          className={`anc-ml-1 anc-mt-1`}
          height={36}
          width={31}
        />
      );
    case "wave_bioreactor_alt":
      return (
        <BioreactorImage
          className={`anc-ml-1 anc-mt-1`}
          height={104}
          width={104}
        />
      );
    case "solution_prep":
      return (
        <SolutionPrepImage
          className={`anc-ml-1 anc-mt-1`}
          height={31}
          width={30}
        />
      );
    case "weigh_dispense":
      return (
        <WeighDispenseImage
          className={`anc-ml-1 anc-mt-1`}
          height={31}
          width={30}
        />
      );
    case "view_more_modal_image":
      return (
        <WeighDispenseImage
          className={`anc-ml-1 anc-mt-1`}
          height={77}
          width={75}
        />
      );
    case "n-3/n-2_sub_bioreactor":
      return (
        <BioreactorATFImage
          className={`anc-ml-1 anc-mt-1`}
          height={27}
          width={32}
        />
      );
    case "production_bioreactor":
      return <BioreactorATF2000Image className={` anc-mt-1`} height={38} width={38} />;
    case "n-1_sub_bioreactor_ATF":
      return (
        <BioreactorATFImage
          className={`anc-ml-1 anc-mt-1`}
          height={27}
          width={32}
        />
      );
    case "filtration":
      return <FiltrationImage className="anc-ml-2 anc-mt-1" />;
    case "centrifuge":
      return <CentrifugeImage className="anc-ml-2 anc-mt-1" />;
    case "aex_column":
      return <AexColumnImage className="anc-ml-2 anc-mt-1" />;
    case "cex_column":
      return <CexColumnImage className="anc-ml-2 anc-mt-1" />;
    case "viral_filtration":
      return <ViralFiltrationImage className="anc-ml-2 anc-mt-1" />;
    case "pro_a_column":
      return <ProAColumnImage className="anc-ml-2 anc-mt-1" />;
    case "neutralization":
      return <NeutralizationImage className="anc-ml-2 anc-mt-1" />;
    case "hold_tank":
      return <HoldTankImage className="anc-ml-2 anc-mt-1" />;
    case "low_ph_viral":
      return <LowPhViralImage className="anc-ml-2 anc-mt-1" />;
    case "polysorbate_addition":
      return <PolySorbateImage className="anc-ml-2 anc-mt-1" />;
    case "bds_fill":
      return <BDSFill className="anc-ml-2 anc-mt-1" />;
    case "uf_df":
      return <UfDfImage className="anc-ml-2 anc-mt-1" />;
    default:
      return <></>;
  }
};

export const getStatusColor: (status: string) => string = (status: string) => {
  switch (status) {
    case "green":
      return "#008533";
    case "grey":
      return "#9C9C9C";
    case "red":
      return "#D62728";
    case "blue":
      return "#0063C3";
    default:
      return "#0063C3";
  }
};

export const getNavList: (tabRoute: string, site: string) => any = (tabRoute: string, site: string) => {

  switch (tabRoute) {
    case "e2e":
      return {
        label: `${site?.toUpperCase()} Shop Floor`,
        authorized: true,
        ...{
          jumpToSections: [],
        },
        externalSections: [
          {
            sectionLabel: "Related Links",
            links: [
              { label: "PI Vision", url: "" },
              { label: "SIMCA Online", url: "" },
              { label: "Schedule Adherence Dashboard", url: "" },
            ],
          },
        ],
      };

      case "inoculation":
      return {
        label: `${site?.toUpperCase()} Shop Floor`,
        authorized: true,
        ...{
          jumpToSections: [],
        },
        externalSections: [
          {
            sectionLabel: "Related Links",
            links: [
              { label: "PI Vision", url: "" },
              { label: "SIMCA Online", url: "" },
              { label: "Schedule Adherence Dashboard", url: "" },
            ],
          },
        ],
      };
      
    case "solution":
      return {
        label: `${site?.toUpperCase()} Shop Floor`,
        authorized: true,
        ...{
          jumpToSections: [
            {
              sectionLabel: "",
              links: [
                {
                  label: "In Progress",
                  url: "#anc-in-progress-sol",
                  authorized: true,
                  disabled: false,
                },
                {
                  label: "Upcoming Next in 48 Hours",
                  url: "#anc-upcoming-48-hours-sol",
                  authorized: true,
                  disabled: false,
                },
                {
                  label: "Completed in Last 48 Hours",
                  url: "#anc-completed-48-hours-sol",
                  authorized: true,
                  disabled: false,
                },
              ],
            },
          ],
        },
        externalSections: [
          {
            sectionLabel: "Related Links",
            links: [
              { label: "PI Vision", url: "" },
              { label: "SIMCA Online", url: "" },
              { label: "Schedule Adherence Dashboard", url: "" },
            ],
          },
        ],
      };
    default:
      return {
        label: `${site?.toUpperCase()} Shop Floor`,
        authorized: true,
        ...{
          jumpToSections: [],
        },
        externalSections: [],
      };
  }
};

export const makeBold = (word: String | undefined) => {
  return <b> {word}</b>;
};

export const captializeWord = (word: string) => {
  const wordArray = word
    .split("_")
    .map((w: string) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(" ");
  return wordArray;
};

export const capsFirstLetter = (word: string) => {
  const wordArray = word.toLowerCase();
  return wordArray.charAt(0).toUpperCase() + word.slice(1);
};
export const getOktaDetails = () => {
  const oktaToken: string | null = localStorage.getItem("dct-okta-token");
  const oktaTokenObj = JSON.parse(oktaToken ?? "");
  if (oktaTokenObj?.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return "";
};

export const checkingTab = (data: any, onModalClick: (state: boolean) => void) => {

  return (
    <>
      <UpcomingLotsContent
        label='UPCOMING LOT'
        date={data?.date}
        time={data?.time }
        lotNumber={data?.lot} />
      <div className="anc-d-flex anc-flex-row anc-flex-wrap">
        <UpcomingLotsButton
          title='SEE UPCOMING SCHEDULE'
          icon=''
          cb={(state: boolean) => { onModalClick(state) }}
        />
        <UpcomingLotsButton
          title='GO TO SYMPHONY'
          icon='link-external'
          cb={(state: boolean) => { }}
        />
      </div>

    </>
  )
}

export const getLegendTooltip = (labelData: labelProps, labelContent: labelContentProps) => {
  switch (labelData.label) {
    case 'In Use':
      return <LegendTooltip
        title={labelData.label}
        labelColor={labelData.labelColor}
        text={labelContent.in_use} />
    case 'Advisory':
      return <LegendTooltip
        title={labelData.label}
        labelColor={labelData.labelColor}
        text={labelContent.advisory} />
    case 'Critical':
      return <LegendTooltip
        title={labelData.label}
        labelColor={labelData.labelColor}
        text={labelContent.critical} />
    case 'Planned Downtime':
      return <LegendTooltip
        title={labelData.label}
        labelColor={labelData.labelColor}
        text={labelContent.planned_downtime} />
    case 'Idle':
      return <LegendTooltip
        title={labelData.label}
        labelColor={labelData.labelColor}
        text={labelContent.idle} />
    case 'Machine Down':
      return <LegendTooltip
        title={labelData.label}
        labelColor={labelData.label}
        labelImageType={labelData.labelImageType}
        text={labelContent.machine_down ?? ""} />
    default:
      break;
  }
}