import { payloadSolType } from "types/api.types";
import { fetchApi } from "./fetch-api";

export const getWeighAndDispenseContainerApi = async (data:payloadSolType) => {

    const response = await fetchApi({
      header: {},
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/weigh_dispense_details`,
      data
    })
   
    return response;

}