import { payloadType } from "types/api.types";
import { fetchApi } from "./fetch-api";

export const getThawInocTabHeaderApi = async (data: payloadType) => {
  const response = await fetchApi({
    header: {},
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/unit_op_header_view`,
    data,
  });

  return response;
};
