import React from "react";
import "./index.scss";
import "../../assets/bootstrap.scss";
import DctFlask from "assets/images/dct-flask";

interface AncTitleImageCardHeaderInterface {
  materialTitle:string
}

const AncTitleImageCardHeader: React.FC<AncTitleImageCardHeaderInterface> = (
  props: AncTitleImageCardHeaderInterface
) => {
  return (
    <div className="anc-title-image-card-header anc-d-flex">
      <div className="ops-fw-bold anc-title-image-card-label ops-fs-4">{props.materialTitle}</div>
      <div>
        <DctFlask
          fill={"black"}
          width={"14"}
          height={"23"}
        />
      </div>
    </div>
  );
};
export default AncTitleImageCardHeader;
