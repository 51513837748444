interface Props {
  classname?: string;
  fillUp:string;
  fillDown:string;
}

const VectorUpDownIcon = ({ fillUp,fillDown, classname }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="-1 -1 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91812 4.82264L7.41562 2.34931C7.36139 2.29463 7.29688 2.25124 7.22579 2.22162C7.15471 2.19201 7.07846 2.17676 7.00146 2.17676C6.92445 2.17676 6.8482 2.19201 6.77712 2.22162C6.70603 2.25124 6.64152 2.29463 6.58729 2.34931L4.11396 4.82264C4.05928 4.87687 4.01588 4.94139 3.98627 5.01247C3.95665 5.08355 3.94141 5.1598 3.94141 5.23681C3.94141 5.31381 3.95665 5.39006 3.98627 5.46114C4.01588 5.53223 4.05928 5.59675 4.11396 5.65097C4.22325 5.75962 4.3711 5.8206 4.52521 5.8206C4.67931 5.8206 4.82716 5.75962 4.93646 5.65097L7.00146 3.58597L9.06646 5.65097C9.17511 5.75874 9.32176 5.8195 9.47479 5.82014C9.55156 5.82058 9.62766 5.80587 9.69873 5.77684C9.76981 5.74781 9.83445 5.70504 9.88896 5.65097C9.94558 5.5987 9.99129 5.53572 10.0234 5.46567C10.0556 5.39563 10.0735 5.31991 10.0762 5.24289C10.079 5.16587 10.0664 5.08907 10.0392 5.01694C10.0121 4.94481 9.97093 4.87877 9.91812 4.82264Z"
        fill={fillUp}
      />
      <path
        d="M9.91812 9.17492L7.41562 11.6483C7.36139 11.7029 7.29688 11.7463 7.22579 11.7759C7.15471 11.8056 7.07846 11.8208 7.00146 11.8208C6.92445 11.8208 6.8482 11.8056 6.77712 11.7759C6.70603 11.7463 6.64152 11.7029 6.58729 11.6483L4.11396 9.17492C4.05928 9.12069 4.01588 9.05617 3.98627 8.98509C3.95665 8.914 3.94141 8.83776 3.94141 8.76075C3.94141 8.68374 3.95665 8.6075 3.98627 8.53642C4.01588 8.46533 4.05928 8.40081 4.11396 8.34659C4.22325 8.23794 4.3711 8.17696 4.52521 8.17696C4.67931 8.17696 4.82716 8.23794 4.93646 8.34659L7.00146 10.4116L9.06646 8.34659C9.17511 8.23882 9.32176 8.17806 9.47479 8.17742C9.55156 8.17697 9.62766 8.19169 9.69873 8.22072C9.76981 8.24975 9.83445 8.29252 9.88896 8.34659C9.94558 8.39886 9.99129 8.46184 10.0234 8.53189C10.0556 8.60193 10.0735 8.67765 10.0762 8.75467C10.079 8.83169 10.0664 8.90849 10.0392 8.98062C10.0121 9.05275 9.97093 9.11879 9.91812 9.17492Z"
        fill={fillDown}
      />
    </svg>
  );
};

export default VectorUpDownIcon;
